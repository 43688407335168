import {
	Box,
	Button,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ColorSnackbar from "./ColorSnackbar";
import styled from "styled-components";
import { setDefectInspectionSettings } from "../../store/defectsInspectionSlice";

const Wrapper = styled.div`
	width: 100%;
`;

export const ColorDefectParams = () => {
	const { defaultColorSettings } = useSelector(
		(state) => state.defectInspection
	);
	const [settings, setSettings] = useState({
		min_defect_size: 0,
		min_pit_depth_rough_mm: 0,
		min_pit_depth_smooth_mm: 0,
		allowable_defect_number: 0,
	});
	const [openSnack, setOpenSnack] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (defaultColorSettings) {
			const mono = defaultColorSettings.find(
				(color) => color.color_name === "default_mono"
			);
			setSettings((prev) => ({
				...prev,
				min_defect_size: mono?.min_defect_size ?? "",
				min_pit_depth_rough_mm: mono?.min_pit_depth_rough_mm ?? "",
				min_pit_depth_smooth_mm: mono?.min_pit_depth_smooth_mm ?? "",
				allowable_defect_number: mono?.allowable_defect_number ?? "",
			}));
		}
	}, [defaultColorSettings]);

	const inputHandlerCreator = (property) => (event) => {
		const numberRegex = /^([0-9]*[.])?[0-9]*?$/;
		if (numberRegex.test(event.target.value)) {
			setSettings((prev) => ({
				...prev,
				[property]:
					event.target.value.length > 1
						? event.target.value.replace(/^0+(?=\d)/, "")
						: event.target.value.length
						? event.target.value
						: 0,
			}));
		}
	};

	const allowedAmountHandler = (event) => {
		const numberRegex = /^[0-9]*$/;
		if (numberRegex.test(event.target.value)) {
			setSettings((prev) => ({
				...prev,
				allowable_defect_number: Number(event.target.value),
			}));
		}
	};

	const handleClick = () => {
		dispatch(
			setDefectInspectionSettings({
				color_id: defaultColorSettings.find(
					(color) => color.color_name === "default_mono"
				).color_id,
				settings,
			})
		);
		setOpenSnack(true);
	};

	return (
		<Wrapper>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					width: "100%",
					padding: "1rem 1rem",
					backgroundColor: "white",
					borderRadius: "12px",
					boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "1rem",
						justifyContent: "space-between",
					}}
				>
					<Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
						{t("settings.setting.min_defect")}
					</Typography>
					<TextField
						value={settings.min_defect_size}
						onChange={inputHandlerCreator("min_defect_size")}
						inputProps={{ min: 0 }}
						variant="standard"
						sx={{ maxWidth: "8rem" }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "1rem",
						justifyContent: "space-between",
					}}
				>
					<Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
						{t("settings.setting.min_defect_rough")}
					</Typography>
					<TextField
						value={settings.min_pit_depth_rough_mm}
						onChange={inputHandlerCreator("min_pit_depth_rough_mm")}
						inputProps={{ min: 0 }}
						variant="standard"
						sx={{ maxWidth: "8rem" }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: "1rem",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
						{t("settings.setting.min_defect_smooth")}
					</Typography>
					<TextField
						value={settings.min_pit_depth_smooth_mm}
						onChange={inputHandlerCreator(
							"min_pit_depth_smooth_mm"
						)}
						variant="standard"
						inputProps={{ min: 0 }}
						sx={{ maxWidth: "8rem" }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<p>{t("measureUnits.mm")}</p>
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: "1rem",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
						{t("settings.setting.ok_defect")}
					</Typography>
					<TextField
						value={settings.allowable_defect_number}
						onChange={allowedAmountHandler}
						inputProps={{ min: 0 }}
						variant="standard"
						sx={{ maxWidth: "8rem" }}
					/>
				</Box>
				<Button onClick={handleClick}>{t("profile.apply")}</Button>
			</Box>
			<ColorSnackbar open={openSnack} setOpen={setOpenSnack} />
		</Wrapper>
	);
};
