import styled from "styled-components";
import { PalletDeprecationStat } from "../components/PalletDeprecationStat";

const Content = styled.div`
	width: 100%;
	min-height: 100%;
	display: flex;
	padding: 2rem;
	flex-direction: column;
`;

export const DeprecationStats = () => {
	return (
		<Content>
			<PalletDeprecationStat />
		</Content>
	);
};
