import { Button } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 100%;
`;

const TabSwitches = styled.div`
	display: flex;
	padding: 0 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Tab = styled.div`
	padding: 1rem 0;
`;

export const TabsComponent = ({ tabs }) => {
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<Wrapper>
			<TabSwitches>
				{tabs.map((tab, index) => (
					<Button
						sx={{
							marginBottom:
								currentTabIndex === index ? "-1px" : "0",
							borderRadius: "4px 4px 0 0",
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderBottom:
								currentTabIndex === index
									? "1px solid white"
									: "none",
							...(currentTabIndex === index
								? {}
								: { backgroundColor: "#fcfcfc" }),
						}}
						key={tab.label}
						onClick={() => setCurrentTabIndex(index)}
					>
						{tab.label}
					</Button>
				))}
			</TabSwitches>
			<Tab>{tabs[currentTabIndex].content}</Tab>
		</Wrapper>
	);
};
