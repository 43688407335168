import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const palletApi = createApi({
	reducerPath: "palletApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `http://localhost:8000/`,
		prepareHeaders: (headers) => {
			headers.set(
				"Authorization",
				`Bearer ${localStorage.getItem("apiToken")}`
			);
			return headers;
		},
	}),
	endpoints: (build) => ({
		getPallets: build.query({
			query: () => `pallets?limit=1&with_polygons=true&desc=true`,
		}),
		getPalletById: build.query({
			query: async (id) => `pallets/${id}`,
		}),
	}),
});

export const { useGetPalletsQuery, useGetPalletByIdQuery } = palletApi;
