import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPalletSectors } from "./palletSlice";

export const changeDecisionInData = createAsyncThunk(
	"report/changeDecisionInData",
	async function (
		{ pallet_id, sector_id, decision, defect_classes },
		{ rejectWithValue, extra, dispatch }
	) {
		const { api } = extra;
		try {
			const response = await api(
				`/api/pallets/${pallet_id}/${sector_id}/decision`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						decision: decision,
						defect_classes: defect_classes,
					}),
				}
			);
			dispatch(getPalletSectors(pallet_id));
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getDecisionBySector = createAsyncThunk(
	"report/getDecisionBySector",
	async function ({ pallet_id, sector_id }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets/${pallet_id}/${sector_id}/decision`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getNonSeriesPallets = createAsyncThunk(
	"report/getNonSeriesPallets",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets?limit=48&with_polygons=false&desc=false`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const initialState = {
	images: [],
	reportScheme: null,
	reportChart: null,
	reportCrop: null,
	palletId: null,
	cloudStatus: "fulfilled",
	imagesCrop: null,
	decisionBySector: null,
	decisionByPallet: null,
	palletDecisionStatus: null,
	sectorId: null,
	reportStatus: null,
	reportClickedChartId: null,
	nonSeriesPallets: null,
	isGarbage: false,
};

const reportSlice = createSlice({
	name: "report",
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getDecisionBySector.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getDecisionBySector.fulfilled, (state, action) => {
				state.status = "resolved";
				state.decisionBySector = action.payload;
			})
			.addCase(getDecisionBySector.rejected, setError)
			.addCase(getNonSeriesPallets.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getNonSeriesPallets.fulfilled, (state, action) => {
				state.status = "resolved";
				state.nonSeriesPallets = action.payload;
			})
			.addCase(getNonSeriesPallets.rejected, setError);
	},
});

export const {
	getSectorId,
	getReportClickedChartId,
	clearAllReportData,
	isGarbage,
	nullPalletStatus,
	setPalletDecisionStatus,
} = reportSlice.actions;

export default reportSlice.reducer;
