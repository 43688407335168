import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const DefectTextbox = ({ defect, rectData, possibleDefects }) => {
	const [targetDeltaX, setTargetDeltaX] = useState(0);
	const { t } = useTranslation();

	const textElem = useRef(null);
	const upperString = useRef(null);

	useEffect(() => {
		const textElemWidth =
			textElem.current?.getBoundingClientRect()?.width ?? 0;
		const textElemHeight =
			textElem.current?.getBoundingClientRect()?.height ?? 0;
		const leftSide = Number(defect.points?.split(",")[0]?.split(" ")[0]);
		const rightSide = Number(defect.points?.split(",")[1]?.split(" ")[0]);
		const topSide = Number(defect.points?.split(",")[0]?.split(" ")[1]);
		const bottomSide = Number(defect.points?.split(",")[2]?.split(" ")[1]);

		const newX =
			rectData.width < rightSide + textElemWidth
				? leftSide - textElemWidth > 0
					? leftSide - textElemWidth
					: rectData.width - (textElemWidth + 10)
				: rightSide;

		const newY =
			rectData.width < rightSide + textElemWidth &&
			leftSide - textElemWidth < 0
				? bottomSide + textElemHeight > rectData.height
					? rectData.height - textElemHeight
					: bottomSide + textElemHeight / 2
				: topSide + (textElemHeight / 2 - 5);
		textElem.current.setAttribute("x", newX);
		textElem.current.setAttribute("y", newY);
		setTargetDeltaX(
			upperString.current?.getBoundingClientRect()?.width ?? 0
		);
	}, [defect, rectData.width, rectData.height]);

	return (
		<text
			filter="url(#rounded-corners)"
			ref={textElem}
			fontSize="14"
			fill={
				defect?.class === 0
					? "#008cff"
					: defect?.class === 1
					? "#ff7300"
					: defect?.class === 2
					? "#ff4800"
					: "#ff0000"
			}
		>
			<tspan ref={upperString}>
				{t(possibleDefects[defect?.class])}
				{": "}
				{defect?.score?.toFixed(2)}
			</tspan>
			<tspan dx={`-${targetDeltaX}px`} dy="14px">
				{"D: "}
				{`${defect?.d_mm?.toFixed(0)} ${t("measureUnits.mm")}`}
			</tspan>
		</text>
	);
};
