import { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as PalletDirection } from "../../icons/palletDirectionSmall.svg";

const Content = styled.div`
	border-radius: 12px;
	padding: 4px 0 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2px;
	align-items: center;
`;

const DirectionIcon = styled(PalletDirection)`
	fill: #A9B8DB;
`;

const ZonesWrapper = styled.div`
	display: grid;
	width: 100%;
	grid-template-rows: repeat(3, 26px);
	grid-template-columns: repeat(3, 1fr);
	gap: 4px;
	flex: 1;
`;

const Zone = styled.div`
	border-radius: 4px;
	color: white;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	justify-content: center;
	background: ${(props) => (props.hasARepeat ? "#fb564d" : "#ebeff5")};
`;

export const RepeatsVisualizer = ({ repetitions_grid }) => {
	const zones = useMemo(() => [1, 2, 3, 4, 5, 6, 7, 8, 9], []);

	return (
		<Content>
			<DirectionIcon />
			<ZonesWrapper>
				{zones.map((zone) => (
					<Zone
						hasARepeat={repetitions_grid?.find(
							(cell) => cell?.grid_n === zone
						)}
						key={zone}
					>
						{repetitions_grid?.find((cell) => cell.grid_n === zone)
							?.rep_count ?? ""}
					</Zone>
				))}
			</ZonesWrapper>
		</Content>
	);
};
