import styled from "@emotion/styled";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";

const TimeLineElement = styled.div`
	position: relative;
	width: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	height: 100%;
	margin-left: ${(props) => `${props.margin}%`};
`;

const TactCircle = styled.div`
	position: absolute;
	background-color: ${(props) => props.displayColor};
	border-radius: 30px;
	width: ${(props) => (props.isSelected ? "22px" : "16px")};
	height: ${(props) => (props.isSelected ? "22px" : "16px")};
	opacity: ${(props) =>
		props.isSelected ? "1" : props.isHidden ? "0.10" : "0.6"};
	cursor: pointer;
	&:hover {
		opacity: ${(props) => (props.isSelected ? "1" : "0.85")};
	};
	&:active {
		opacity: 1;
	};
`;

const PopupWrapper = styled.div`
	position: absolute;
	background: white;
	padding: 4px 8px;
	bottom: ${(props) => (props.location === "top" ? "110%" : "initial")};
	top: ${(props) => (props.location === "bottom" ? "40%" : "initial")};;
	width: 450px;
	display: ${(props) => (props.isVisible ? "flex" : "none")};
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
`;

const TimeLineBody = styled.div`
	display: flex;
	height: 25px;
	padding-top: 5px;
	width: 100%;
`;

const ZoomWrapper = styled.div`
	min-width: ${(props) => `${Number((100 + props.scale).toFixed(1))}%`};
	flex-direction: column;
	display: flex;
`;

const TimeLineWrapper = styled.div`
	display: flex;
	overflow-x: auto;
	overflow-y: visible;
	padding-bottom: 28px;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
`;

const TimeSegment = styled.div`
	position: relative;
	height: 18px;
	width: 3px;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	align-items: ${(props) => props.position};
`;

const TimeStamp = styled.div`
	position: absolute;
	top: calc(100% + 4px);
`;

const TimeScales = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const TactTimeLine = ({
	data,
	handleClick,
	isLoaded,
	selectedUuid,
	onZoom,
	Popup,
	popupLocation,
}) => {
	const [scale, setScale] = useState(0);
	const content = useRef(null);
	const [popupInfo, setPopupInfo] = useState(null);
	const popup = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const [params, setParams] = useState({
		start: null,
		end: null,
		duration: null,
	});

	const handleScroll = useCallback(
		(event) => {
			event.preventDefault();
			setScale((prev) => {
				const result = Number(
					(
						prev +
						(event.wheelDeltaY > 0
							? prev >= data.length - 100
								? 0
								: (data.length - 100) / 10
							: prev <= 0
							? 0
							: -(data.length - 100) / 10)
					).toFixed(1)
				);
				if (onZoom) onZoom(result);
				return result;
			});
		},
		[data]
	);

	useEffect(() => {
		if (data.length) {
			setParams({
				start: data[0].begin,
				end: data[data.length - 1].end,
				duration: data[data.length - 1].end - data[0].begin,
			});
		}
	}, [data]);

	useEffect(() => {
		content.current.addEventListener("wheel", handleScroll);
		const currentContent = content.current;
		return () => {
			currentContent.removeEventListener("wheel", handleScroll);
		};
	}, [handleScroll]);

	const handleElementHover = useCallback((event, entry) => {
		const position =
			event.target.getBoundingClientRect().left -
			38 -
			225 +
			event.target.clientWidth / 2;
		popup.current.style.left = `${
			position < 0
				? 0
				: position > content.current.clientWidth - 450
				? content.current.clientWidth - 450
				: position
		}px`;
		setIsVisible(true);
		setPopupInfo(entry);
	}, []);

	const timeLineElements = useMemo(() => {
		if (params.duration === null) return null;
		return data.map((entry) => {
			return (
				<TimeLineElement
					key={entry.uuid}
					margin={(entry.marginMS / params.duration) * 100}
				>
					<TactCircle
						isHidden={entry.isHidden}
						onClick={handleClick ? () => handleClick(entry) : null}
						isSelected={entry.uuid === selectedUuid}
						displayColor={entry.displayColor}
						onMouseEnter={(event) =>
							handleElementHover(event, entry)
						}
						onMouseOut={() => setIsVisible(false)}
					/>
				</TimeLineElement>
			);
		});
	}, [params, selectedUuid]);

	const timeLineScales = useMemo(() => {
		if (params.duration === null) return null;
		const deltaTime = Number(
			(params.duration / (10 * (scale / 100 + 1) - 1).toFixed(0)).toFixed(
				0
			)
		);
		const scales = new Array(Number((10 * (scale / 100 + 1)).toFixed(0)))
			.fill(0)
			.map((_, index) => params.start + deltaTime * index);

		return scales.map((segment, index) => (
			<TimeSegment
				position={
					index === 0
						? "start"
						: index === scales.length - 1
						? "end"
						: "center"
				}
				key={segment}
			>
				<TimeStamp>
					{setTimeZoneOffset(
						new Date(segment),
						data && data[0]?.dt?.slice(-6, -3)
					)
						.toTimeString()
						.slice(0, 8)}
				</TimeStamp>
			</TimeSegment>
		));
	}, [params, scale]);

	return (
		<Content ref={content}>
			<TimeLineWrapper>
				<ZoomWrapper scale={scale}>
					{isLoaded ? (
						<>
							<TimeLineBody>{timeLineElements}</TimeLineBody>
							<TimeScales>{timeLineScales}</TimeScales>
						</>
					) : null}
				</ZoomWrapper>
			</TimeLineWrapper>
			<PopupWrapper
				location={popupLocation}
				ref={popup}
				isVisible={isVisible}
			>
				<Popup {...popupInfo} />
			</PopupWrapper>
		</Content>
	);
};
