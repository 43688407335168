import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
	getSettings,
	getSystem,
	setIsChartDisplayed,
	setIsTableDisplayed,
} from "../../store/settingsSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SwitchLangToggle from "../UI/SwitchLangToggle";
import DryContacts from "./DryContacts";
import MeasureUnits from "./MeasureUnits";
import { DataListSettings } from "../DataListSettings";

const SettingsTitle = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 22px;
	margin-top: 2rem;
`;

const Content = styled.div`
	min-height: 84vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SettingsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

const SettingUnit = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const VersionWrapper = styled.div`
	padding-top: 3rem;
	padding-bottom: 1rem;
`;

const Title = styled.p`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 22px;
`;

const ConfigList = () => {
	const { users } = useSelector((state) => state.user);
	const { isChartDisplayedSetting, isTableDisplayedSetting } = useSelector(
		(state) => state.settings
	);
	const { alarm } = useSelector((state) => state.settings);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [version, setVersion] = useState(null);

	useEffect(() => {
		fetch("/version").then((response) => {
			response.json().then((result) => setVersion(result.version));
		});
		dispatch(getSettings());
		dispatch(getSystem());
	}, [dispatch]);

	return (
		<Content>
			<SettingsWrapper>
				<SettingUnit>
					<SettingsTitle>{t("settings.setting.title")}</SettingsTitle>
					{users?.role !== "operator" && <SwitchLangToggle />}
				</SettingUnit>
				{alarm && users?.role !== "operator" && <DataListSettings />}
				<DryContacts />
				<SettingUnit>
					<Title>{t("settings.setting.drySideUi")}</Title>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									onChange={() =>
										dispatch(
											setIsTableDisplayed(
												!isTableDisplayedSetting
											)
										)
									}
									checked={isTableDisplayedSetting}
								/>
							}
							label={t("settings.setting.noTable")}
						/>
						<FormControlLabel
							control={
								<Switch
									onChange={() =>
										dispatch(
											setIsChartDisplayed(
												!isChartDisplayedSetting
											)
										)
									}
									checked={isChartDisplayedSetting}
								/>
							}
							label={t("settings.setting.noChart")}
						/>
					</FormGroup>
				</SettingUnit>
				<MeasureUnits />
			</SettingsWrapper>
			<VersionWrapper>Version: {version}</VersionWrapper>
		</Content>
	);
};

export default ConfigList;
