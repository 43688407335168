import styled from "styled-components";
import { ReactComponent as LogoIcon } from "../../icons/logo_head_blue.svg";

const IconWrapper = styled.div`
	width: 30px;
	height: 26px;
	color: #4357a0;
`;

export const Logo = () => {
	return (
		<IconWrapper>
			<LogoIcon />
		</IconWrapper>
	);
};
