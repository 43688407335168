import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import NotionOperScr from "./NotionOperScr";
import { useTranslation } from "react-i18next";

const NotionPage = () => {
	const [value, setValue] = React.useState("1");
	const { t, i18n } = useTranslation();

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%", typography: "body1" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						aria-label="lab API tabs example"
					>
						<Tab label={t("notion.screen")} value="1" />
						<Tab label={t("notion.daily")} value="2" />
						<Tab label={t("notion.color")} value="3" />
						<Tab label={t("notion.form")} value="4" />
						<Tab label={t("notion.drySide")} value="5" />
						<Tab label={t("notion.setting")} value="6" />
						<Tab label={t("notion.questions")} value="7" />
						<Tab label={t("notion.learning")} value="8" />
					</TabList>
				</Box>
				<TabPanel value="1">
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "8bbc2738546f444c958c8c60dc1f9321"
								: i18n.language === "en"
								? "ecc6d7c96169468aaccba2986ba75129"
								: "015ec04d5cc645eeb31151573189a094"
						}
					/>
				</TabPanel>
				<TabPanel value="2">
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "09ca364eb4dd427caa3b329f9bc812fb"
								: i18n.language === "en"
								? "3e5c313af5e942a886d39f38e55ef936"
								: "bf141998d9fe4e00a144f81fc687b92c"
						}
					/>
				</TabPanel>
				<TabPanel value="3">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "4d2b72979ad04f24b82e0f8c2bd1a4ea"
								: i18n.language === "en"
								? "80ca4eedb7774290af435f792611cb31"
								: "077ab993612b446f980200fc7c7643ad"
						}
					/>
				</TabPanel>
				<TabPanel value="4">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "1c8989d716924bc2ba9bdca290d86a8b"
								: i18n.language === "en"
								? "22f7e271e5394ff2a665bf88df95f6f8"
								: "dbedc0a05a1248d9843a623cd551d0dd"
						}
					/>
				</TabPanel>
				<TabPanel value="5">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "92f3693ae7f54f5ca0259a6bf2418bf4"
								: i18n.language === "en"
								? "ee12d0b47a9c458daacfcd3e62d12ba5"
								: "5512d3394a614bfabd57dbe7bd47be38"
						}
					/>
				</TabPanel>
				<TabPanel value="6">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "1dc4366e52fd4e368346f1b213ea2094"
								: i18n.language === "en"
								? "ce2aeaa3c6ee45b885b32f00407dd53e"
								: "581ceb56f9074b119461e6d4bc269c24"
						}
					/>
				</TabPanel>
				<TabPanel value="7">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "680b811892c94dfa854ebd911fd35025"
								: i18n.language === "en"
								? "b0254da04a5f419db62758a3a8959776"
								: "322ff5a90a8f432b82ec98849dda0317"
						}
					/>
				</TabPanel>
				<TabPanel value="8">
					{" "}
					<NotionOperScr
						pageID={
							i18n.language === "ru"
								? "b7460f1334cd4673b77a0430c6148f21"
								: i18n.language === "en"
								? "c987dadd799142eba30352b1bbeb0c06"
								: "323b54c703f24c94be359ba7c1a82497"
						}
					/>
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default NotionPage;
