import { IconButton } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import styled from "styled-components";

const Content = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const UserDataManagement = ({ openDialog, ...user }) => {
	return (
		<Content>
			<IconButton onClick={() => openDialog(user)}>
				<ManageAccountsIcon />
			</IconButton>
		</Content>
	);
};
