import { MenuItem, Select } from "@mui/material";

export const FilterSelect = ({ filter, filters, handleChange }) => {
	return (
		<Select
			sx={{
				width: "10rem",
				marginLeft: "0.5rem",
			}}
			variant="standard"
			value={filters[filter].value}
			onChange={(event) => handleChange(filter, event.target.value)}
		>
			<MenuItem key={"-"} value={""}>
				{"-"}
			</MenuItem>
			{filters[filter].options.map((option) => (
				<MenuItem key={option.uuid} value={option.uuid}>
					{option.label}
				</MenuItem>
			))}
		</Select>
	);
};
