import styled from "styled-components";

const Content = styled.div`
	border-radius: 6px;
	background: #fff;
	display: flex;
	gap: ${(props) => (props.divider ? "4px" : "6px")};
	flex-direction: column;
	align-items: center;
	padding: 6px;
	letter-spacing: 0.04em;
	flex: ${(props) => `${props.blocksCount} ${props.blocksCount} 0`};
	${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : "")}
`;

const Label = styled.p`
	font-size: 13px;
	letter-spacing: 0.04em;
	color: #5A76A3;
	line-height: 20px;
	white-space: nowrap;
`;

const Divider = styled.div`
	width: 100%;
	height: 2px;
	min-height: 2px;
	border-radius: 2px;
	background: #DBE2F1;
`;

export const DataItemWrapper = ({
	label,
	divider,
	children,
	maxWidth,
	blocksCount = 1,
}) => {
	return (
		<Content
			maxWidth={maxWidth}
			blocksCount={blocksCount}
			divider={divider}
		>
			<Label>{label}</Label>
			{divider && <Divider />}
			{children}
		</Content>
	);
};
