import * as React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import CircleLoader from "./CircleLoader";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Switch,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import TypeOfData from "./TypeOfData";
import { clearNeiroScheme } from "../../store/palletSlice";
import { DefectTextbox } from "../DefectTextbox";

const SectorSVGWrapper = styled.svg.attrs({
	xmlns: "http://www.w3.org/2000/svg",
})``;
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({
	opened,
	onClose,
	sectorId,
	isSectorLoaded,
	positiveDecisionHandler,
	negativeDecisionHandler,
	decision,
	sectorImgCrop,
}) {
	const [rectData, setRectData] = React.useState({ width: 0, height: 0 });
	const [checked, setChecked] = React.useState(true);
	const [bboxes, setBboxes] = React.useState([]);
	const [defected, setDefected] = React.useState([]);
	const [checkBoxes, setCheckBoxes] = React.useState({
		crack: false,
		pit: false,
		stain: false,
		destruction: false,
	});
	const dispatch = useDispatch();

	const { t } = useTranslation();

	React.useEffect(() => {
		setBboxes(decision?.bboxes ?? []);
	}, [decision?.bboxes]);

	const handleBboxMouseIn = (points) => {
		setBboxes((prev) => {
			const temp = structuredClone(prev);
			const indexToPop = temp.findIndex((a) => a.points === points);
			temp.push(temp[indexToPop]);
			temp.splice(indexToPop, 1);
			return temp;
		});
	};

	const possibleDefects = [
		"report.table.filter_crack",
		"report.table.filter_pit",
		"report.table.filter_stain",
		"report.table.filter_destruction",
	];

	const handleBboxMouseOut = () => {
		setBboxes(decision?.bboxes ?? []);
	};

	React.useEffect(() => {
		setDefected([]);
		setCheckBoxes({ crack: false, pit: false, stain: false });
	}, [sectorId]);

	React.useEffect(() => {
		if (decision?.classes) {
			setCheckBoxes({
				crack: decision.classes.includes(0),
				pit: decision.classes.includes(1),
				stain: decision.classes.includes(2),
				destruction: decision.classes.includes(3),
			});
		} else {
			setCheckBoxes({ crack: false, pit: false, stain: false });
		}
	}, [sectorId, decision]);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const handleCrackChange = (event) => {
		setCheckBoxes({ ...checkBoxes, crack: event.target.checked });
		if (event.target.checked === true) {
			setDefected([...defected, 0]);
		}
		if (event.target.checked === false && defected.includes(0)) {
			const filtered = defected.filter((def) => def !== 0);
			setDefected(filtered);
		}
	};

	const handlePitChange = (event) => {
		setCheckBoxes({ ...checkBoxes, pit: event.target.checked });
		if (event.target.checked === true) {
			setDefected([...defected, 1]);
		}
		if (event.target.checked === false && defected.includes(1)) {
			const filtered = defected.filter((def) => def !== 1);
			setDefected(filtered);
		}
	};
	const handleStainChange = (event) => {
		setCheckBoxes({ ...checkBoxes, stain: event.target.checked });
		if (event.target.checked === true) {
			setDefected([...defected, 2]);
		}
		if (event.target.checked === false && defected.includes(2)) {
			const filtered = defected.filter((def) => def !== 2);
			setDefected(filtered);
		}
	};
	const handleDestructionChange = (event) => {
		setCheckBoxes({ ...checkBoxes, destruction: event.target.checked });
		if (event.target.checked === true) {
			setDefected([...defected, 3]);
		}
		if (event.target.checked === false && defected.includes(3)) {
			const filtered = defected.filter((def) => def !== 3);
			setDefected(filtered);
		}
	};

	const handleKeyPress = (e) => {
		const key = e.key;
		if (key === "1") {
			setCheckBoxes({ ...checkBoxes, crack: !checkBoxes.crack });
			if (!checkBoxes.crack === true) {
				setDefected([...defected, 0]);
			}
			if (!checkBoxes.crack === false && defected.includes(0)) {
				const filtered = defected.filter((def) => def !== 0);
				setDefected(filtered);
			}
		}
		if (key === "2") {
			setCheckBoxes({ ...checkBoxes, pit: !checkBoxes.pit });
			if (!checkBoxes.pit === true) {
				setDefected([...defected, 1]);
			}
			if (!checkBoxes.pit === false && defected.includes(1)) {
				const filtered = defected.filter((def) => def !== 1);
				setDefected(filtered);
			}
		}
		if (key === "3") {
			setCheckBoxes({ ...checkBoxes, stain: !checkBoxes.stain });
			if (!checkBoxes.stain === true) {
				setDefected([...defected, 2]);
			}
			if (!checkBoxes.stain === false && defected.includes(2)) {
				const filtered = defected.filter((def) => def !== 2);
				setDefected(filtered);
			}
		}
		if (key === "4") {
			setCheckBoxes({
				...checkBoxes,
				destruction: !checkBoxes.destruction,
			});
			if (!checkBoxes.destruction === true) {
				setDefected([...defected, 3]);
			}
			if (!checkBoxes.destruction === false && defected.includes(3)) {
				const filtered = defected.filter((def) => def !== 3);
				setDefected(filtered);
			}
		}
		if (key === "Enter") {
			positiveDecisionHandler(defected);
		}
	};

	const closed = () => {
		onClose();
		dispatch(clearNeiroScheme());
	};

	const onLoadRect = () => {
		const el = document.getElementById("myImage");

		const rectWidth = el?.getBBox().width.toFixed(0);
		const rectHeight = el?.getBBox().height.toFixed(0);

		setRectData({ width: Number(rectWidth), height: Number(rectHeight) });
	};

	return (
		<div>
			<Dialog
				maxWidth={false}
				sx={{
					maxWidth: "98%",
					maxHeight: "98%",
				}}
				open={opened}
				TransitionComponent={Transition}
				keepMounted
				onClose={closed}
				aria-describedby="alert-dialog-slide-description"
				onKeyDown={handleKeyPress}
			>
				<DialogTitle
					display={"flex"}
					flexDirection="row"
					gap="2rem"
					alignItems={"center"}
				>
					{t("panel.crop.cropDefects")}

					{decision?.decision === undefined ? (
						<Typography sx={{ color: "gray" }}>
							{" "}
							{t("panel.crop.defectsNull")}
						</Typography>
					) : decision?.decision === true ? (
						<Typography sx={{ display: "flex", gap: "3px" }}>
							{t("panel.crop.defectsCheck")} -
							<Typography sx={{ color: "red" }}>
								{t("panel.crop.defectsYes")}
							</Typography>
						</Typography>
					) : decision?.decision === false ? (
						<Typography sx={{ display: "flex", gap: "3px" }}>
							{t("panel.crop.defectsCheck")} -
							<Typography sx={{ color: "green" }}>
								{t("panel.crop.defectsNo")}
							</Typography>
						</Typography>
					) : (
						<Typography sx={{ color: "gray" }}>
							{t("panel.crop.defectsNull")}
						</Typography>
					)}
				</DialogTitle>
				<DialogContent>
					{isSectorLoaded ? (
						<SectorSVGWrapper
							width={rectData.width}
							height={rectData.height}
							viewBox={`0 0 ${rectData.width} ${rectData.height}`}
						>
							<image
								id={"myImage"}
								href={sectorImgCrop}
								onLoad={onLoadRect}
							/>
							{bboxes.map((defect, index) => (
								<g
									onMouseEnter={() =>
										handleBboxMouseIn(defect.points)
									}
									onMouseLeave={handleBboxMouseOut}
									key={index}
								>
									<filter
										id="rounded-corners"
										x="-5%"
										width="110%"
										y="0"
										height="100%"
									>
										<feFlood floodColor="#353535" />
										<feGaussianBlur stdDeviation="2" />
										<feComponentTransfer>
											<feFuncA
												type="table"
												tableValues="0 0 0 1"
											/>
										</feComponentTransfer>

										<feComponentTransfer>
											<feFuncA
												type="table"
												tableValues="0 1 1 1 1 1 1 1"
											/>
										</feComponentTransfer>
										<feComposite
											operator="over"
											in="SourceGraphic"
										/>
									</filter>
									{checked && (
										<polygon
											id={"MyPath"}
											fill="#00000000"
											stroke={
												defect.class === 0
													? "#008cff"
													: defect.class === 1
													? "#ff7300"
													: defect.class === 2
													? "#ff4800"
													: "#ff0000"
											}
											strokeWidth={"5px"}
											points={defect.points}
										/>
									)}
									{checked && (
										<DefectTextbox
											defect={defect}
											rectData={rectData}
											possibleDefects={possibleDefects}
										/>
									)}
								</g>
							))}
						</SectorSVGWrapper>
					) : (
						<CircleLoader />
					)}
				</DialogContent>
				<DialogActions
					sx={{
						justifyContent: "space-between",
						margin: "0 1rem 0 1rem",
					}}
				>
					<Box
						display="flex"
						flexDirection="row"
						gap="0.5rem"
						alignItems="center"
					>
						{decision?.bboxes?.length > 0 && (
							<FormControlLabel
								control={
									<Switch
										checked={checked}
										onChange={handleChange}
										inputProps={{
											"aria-label": "controlled",
										}}
									/>
								}
								label={t("report.table.filter_label")}
							/>
						)}
					</Box>
					<Box ml="2rem">
						<FormControlLabel
							control={
								<Checkbox
									checked={checkBoxes.crack ?? false}
									onChange={handleCrackChange}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label={t("report.table.filter_crack")}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkBoxes.pit ?? false}
									onChange={handlePitChange}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label={t("report.table.filter_pit")}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkBoxes.stain ?? false}
									onChange={handleStainChange}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label={t("report.table.filter_stain")}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkBoxes.destruction ?? false}
									onChange={handleDestructionChange}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label={t("report.table.filter_destruction")}
						/>
					</Box>
					<Box>
						<Button
							sx={{ color: "red" }}
							onClick={() => {
								onClose();
								positiveDecisionHandler(defected);
							}}
						>
							{t("panel.crop.defectsYesBtn")}
						</Button>
						<Button
							sx={{ color: "green" }}
							onClick={() => {
								onClose();
								negativeDecisionHandler();
							}}
						>
							{t("panel.crop.defectsNoBtn")}
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
}
