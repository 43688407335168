import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchPallet = createAsyncThunk(
	"pallet/fetchPallet",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets?limit=100&with_polygons=true&desc=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getLastPallet = createAsyncThunk(
	"pallet/getLastPallet",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets?limit=1&with_polygons=true&desc=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPenultPallet = createAsyncThunk(
	"pallet/getPenultPallet",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets?limit=2&with_polygons=true&desc=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPalletById = createAsyncThunk(
	"pallet/getPalletById",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/pallets/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPalletImageById = createAsyncThunk(
	"pallet/getPalletImageById",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/image`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getZippedPalletImage = createAsyncThunk(
	"pallet/getZippedPalletImage",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/image?zipped=true`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getBigPalletImage = createAsyncThunk(
	"pallet/getBigPalletImage",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/image`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getBigImageMask = createAsyncThunk(
	"rfid/getBigImageMask",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return (
					await api(`/api/pallets/${id}/scheme`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					})
				).scheme;
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPalletsForImageZipped = createAsyncThunk(
	"pallet/getPalletsForImageZipped",
	async function (
		{
			bdt,
			edt,
			has_crack,
			has_pit,
			has_stain,
			has_destruction,
			has_no_defect,
			has_height_overlimit,
			user_decision,
			content,
			include_garbage,
			include_empty,
		},
		{ rejectWithValue, extra }
	) {
		const { api } = extra;
		try {
			if (bdt && edt) {
				const values = {
					has_crack: has_crack ?? null,
					has_pit: has_pit ?? null,
					has_stain: has_stain ?? null,
					has_destruction: has_destruction ?? null,
					has_no_defect: has_no_defect ?? null,
					has_height_overlimit: has_height_overlimit ?? null,
					user_decision: user_decision ?? null,
					content: content ?? null,
					include_garbage: include_garbage ?? null,
					include_empty: include_empty ?? null,
				};
				let params = new URLSearchParams(values);

				let keysForDel = [];
				params.forEach((value, key) => {
					if (value === String(null)) {
						keysForDel.push(key);
					}
				});
				keysForDel.forEach((key) => {
					params.delete(key);
				});
				return await api(
					`/api/pallets?is_inspected=true&dt_begin=${encodeURIComponent(
						bdt
					)}&dt_end=${encodeURIComponent(
						edt
					)}&limit=10000&with_polygons=false&desc=false&` + params,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getSeriesById = createAsyncThunk(
	"pallet/getSeriesById",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(
					`/api/batches/interval?limit=1&dt_end=${encodeURIComponent(
						id
					)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getSchemeById = createAsyncThunk(
	"pallet/getSchemeById",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPalletSectors = createAsyncThunk(
	"pallet/getPalletSectors",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/sectors`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getClickedChartById = createAsyncThunk(
	"pallet/getClickedChartById",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return (
					await api(`/api/pallets/${id}/scheme`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					})
				).chart.history;
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getPalletImageCropById = createAsyncThunk(
	"pallet/getPalletImageCropById",
	async function ({ id, crop }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/pallets/${id}/image?crop=%5B${crop[0]}%2C%20${crop[1]}%2C%20${crop[2]}%2C%20${crop[3]}%5D`,
				{
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getSeriesByDay = createAsyncThunk(
	"pallet/getSeriesByDay",
	async function ({ begin, end }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/batches/date?dt_begin=${encodeURIComponent(
					begin
				)}&dt_end=${encodeURIComponent(end)}&limit=100`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const palletSlice = createSlice({
	name: "pallet",
	initialState: {
		pallets: [],
		lastPallet: null,
		penultPallet: null,
		palletId: null,
		status: null,
		imageStatus: null,
		zippedImagesStatus: null,
		bigImagesStatus: null,
		error: null,
		images: null,
		zippedImage: [],
		bigImage: [],
		bigImageMask: [],
		imagesCrop: null,
		crop: null,
		series: null,
		actualSeries: null,
		scheme: null,
		schemeStatus: null,
		clickedChart: null,
		clickedChartPalletId: null,
		seriesByDay: null,
		palletsForImageZipped: null,
		dayStatus: null,
		sectorId: null,
		palletSectors: [],
		clickBySerie: null,
		neiroScheme: null,
		beginDT: null,
		endDT: null,
		filterParams: null,
		lastTactState: true,
	},
	reducers: {
		getClickedChartPalletId(state, action) {
			state.clickedChartPalletId = action.payload;
		},
		getSectorId(state, action) {
			state.sectorId = action.payload;
		},
		getBeginDT(state, action) {
			state.beginDT = action.payload;
		},
		getEndDT(state, action) {
			state.endDT = action.payload;
		},
		saveFilterParams(state, action) {
			state.filterParams = action.payload;
		},
		getNeiroScheme(state, action) {
			state.neiroScheme = action.payload;
		},
		clearNeiroScheme(state, action) {
			state.neiroScheme = null;
		},
		isClickBySerie(state, action) {
			state.clickBySerie = action.payload;
		},
		setLastTact(state, action) {
			state.lastTactState = action.payload;
		},
		clearAllData(state, action) {
			state.images = null;
			state.scheme = null;
			state.series = null;
			state.clickedChart = null;
			state.clickedChartPalletId = null;
			state.palletsForImageZipped = null;
		},
	},
	extraReducers: {
		[fetchPallet.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[fetchPallet.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.pallets = action.payload;
		},
		[fetchPallet.rejected]: setError,

		[getLastPallet.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getLastPallet.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.lastPallet = action.payload;
		},
		[getLastPallet.rejected]: setError,

		[getPenultPallet.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getPenultPallet.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.penultPallet = action.payload;
		},
		[getPenultPallet.rejected]: setError,

		[getPalletsForImageZipped.pending]: (state) => {
			state.zippedImagesStatus = "loading";
			state.palletsForImageZipped = null;
			state.error = null;
		},
		[getPalletsForImageZipped.fulfilled]: (state, action) => {
			state.zippedImagesStatus = "resolved";
			state.palletsForImageZipped = action.payload;
		},
		[getPalletsForImageZipped.rejected]: (state, action) => {
			state.zippedImagesStatus = "rejected";
		},

		[getPalletImageById.pending]: (state) => {
			state.imageStatus = "loading";
			state.error = null;
			state.images = null;
		},
		[getPalletImageById.fulfilled]: (state, action) => {
			state.imageStatus = "resolved";

			if (action.payload) {
				state.images = action.payload;
			}
		},
		[getPalletImageById.rejected]: (state, action) => {
			state.imageStatus = "rejected";
			state.error = action.payload;
		},

		[getZippedPalletImage.pending]: (state, action) => {
			state.zippedImagesStatus = "loading";
			state.error = null;
			state.images = null;
		},
		[getZippedPalletImage.fulfilled]: (state, action) => {
			state.zippedImagesStatus = "resolved";
			if (action.payload) {
				state.zippedImage.push({
					id: action.meta.arg,
					blob: action.payload,
				});
			}
			state.zippedImage = [...new Set(state.zippedImage)];
		},
		[getZippedPalletImage.rejected]: (state, action) => {
			state.zippedImagesStatus = "rejected";
			state.error = action.payload;
		},

		[getBigPalletImage.pending]: (state, action) => {
			state.bigImagesStatus = "loading";
			state.error = null;
		},
		[getBigPalletImage.fulfilled]: (state, action) => {
			state.bigImagesStatus = "resolved";
			if (action.payload) {
				state.bigImage.push({
					id: action.meta.arg,
					blob: action.payload,
				});
			}
			state.bigImage = [...new Set(state.bigImage)];
		},
		[getBigPalletImage.rejected]: (state, action) => {
			state.bigImagesStatus = "rejected";
			state.error = action.payload;
		},

		[getBigImageMask.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getBigImageMask.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.bigImageMask.push({
					id: action.meta.arg,
					mask: action.payload,
				});
			}
			state.bigImageMask = [...new Set(state.bigImageMask)];
		},
		[getBigImageMask.rejected]: setError,

		[getPalletImageCropById.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getPalletImageCropById.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.crop = action.meta.arg.crop;
			state.imagesCrop = action.payload;
		},
		[getPalletImageCropById.rejected]: setError,

		[getSeriesById.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getSeriesById.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.series = action.payload;
		},
		[getSeriesById.rejected]: setError,

		[getSchemeById.pending]: (state) => {
			state.schemeStatus = "loading";
			state.error = null;
			state.scheme = null;
		},
		[getSchemeById.fulfilled]: (state, action) => {
			state.schemeStatus = "resolved";
			state.scheme = action.payload;
		},
		[getSchemeById.rejected]: setError,

		[getPalletSectors.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getPalletSectors.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.palletSectors.push({
					id: action.meta.arg,
					mask: action.payload,
				});
			}
			state.palletSectors = [...new Set(state.palletSectors)];
		},
		[getPalletSectors.rejected]: setError,

		[getClickedChartById.pending]: (state) => {
			state.status = "loading";
			state.error = null;
			state.clickedChart = null;
		},
		[getClickedChartById.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.clickedChart = action.payload;
		},
		[getClickedChartById.rejected]: setError,

		[getPalletById.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getPalletById.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.palletId = action.payload;
		},
		[getPalletById.rejected]: setError,

		[getSeriesByDay.pending]: (state) => {
			state.dayStatus = "loading";
			state.error = null;
		},
		[getSeriesByDay.fulfilled]: (state, action) => {
			state.dayStatus = "resolved";
			state.seriesByDay = action.payload;
		},
		[getSeriesByDay.rejected]: setError,
	},
});

export const {
	getClickedChartPalletId,
	getSectorId,
	isClickBySerie,
	getNeiroScheme,
	clearNeiroScheme,
	clearAllData,
	getBeginDT,
	getEndDT,
	saveFilterParams,
	setLastTact,
} = palletSlice.actions;

export default palletSlice.reducer;
