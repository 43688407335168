import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getSettings = createAsyncThunk(
	"settings/getSettings",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api("/api/settings/alarm", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const changeSettings = createAsyncThunk(
	"settings/changeSettings",
	async function ({ settingsState }, { rejectWithValue, extra, dispatch }) {
		const { api } = extra;
		try {
			await api("/api/settings/alarm", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(settingsState),
			});
			dispatch(getSettings());
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const changeSystem = createAsyncThunk(
	"settings/changeSystem",
	async function ({ settingsState }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			await api("/api/settings/system", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(settingsState),
			});

			return {
				data: settingsState,
			};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getEquipment = createAsyncThunk(
	"settings/getEquipment",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api("/api/settings/equipment", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getSystem = createAsyncThunk(
	"settings/getSystem",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api("/api/settings/system", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const settingsSlice = createSlice({
	name: "settings",
	initialState: {
		status: null,
		alarm: null,
		equipment: null,
		isChartDisplayedSetting:
			localStorage.getItem("isChartDisplayedSetting") !== "false",
		isTableDisplayedSetting:
			localStorage.getItem("isTableDisplayedSetting") !== "false",
		system: null,
		logs: [],
	},
	reducers: {
		addLogs: (state, action) => {
			state.logs.push(action.payload);
			const logs = JSON.parse(localStorage.getItem("logs"));
			logs.push(action.payload);
			localStorage.setItem("logs", JSON.stringify(logs));
		},
		setIsChartDisplayed: (state, action) => {
			state.isChartDisplayedSetting = action.payload;
			localStorage.setItem("isChartDisplayedSetting", action.payload);
		},
		setIsTableDisplayed: (state, action) => {
			state.isTableDisplayedSetting = action.payload;
			localStorage.setItem("isTableDisplayedSetting", action.payload);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSettings.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getSettings.fulfilled, (state, action) => {
				state.status = "resolved";
				state.alarm = action.payload;
			})
			.addCase(getSettings.rejected, setError)
			.addCase(getEquipment.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getEquipment.fulfilled, (state, action) => {
				state.status = "resolved";
				state.equipment = action.payload;
			})
			.addCase(getEquipment.rejected, setError)
			.addCase(getSystem.pending, (state) => {
				state.status = "loading";
				state.error = null;
				state.system = null;
			})
			.addCase(getSystem.fulfilled, (state, action) => {
				state.status = "resolved";
				state.system = action.payload;
			})
			.addCase(getSystem.rejected, setError)
			.addCase(changeSystem.fulfilled, (state, action) => {
				state.system = action.payload.data;
			});
	},
});

export const { addLogs, setIsChartDisplayed, setIsTableDisplayed } =
	settingsSlice.actions;

export default settingsSlice.reducer;
