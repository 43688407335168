import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ColorSnackbar({ open, setOpen, error }) {
	const { t } = useTranslation();
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	if (error)
		return (
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={open}
					autoHideDuration={2000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity={"error"}
						sx={{ width: "100%" }}
					>
						Возникла ошибка, попробуйте еще!
					</Alert>
				</Snackbar>
			</Stack>
		);

	if (!error)
		return (
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={open}
					autoHideDuration={2000}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						severity={"success"}
						sx={{ width: "100%" }}
					>
						Параметры изменены.
					</Alert>
				</Snackbar>
			</Stack>
		);
}
