import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { deDE, ruRU, enUS } from "@mui/x-date-pickers";
import { useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/de";
import "dayjs/locale/en";

export const LocalizedDatePicker = ({ value, sx, setValue, variant }) => {
	const { i18n } = useTranslation();
	const locale = useRef(
		(() => {
			switch (i18n.resolvedLanguage) {
				case "ru":
					return {
						baseLanguage: ruRU,
						format: "DD/MM/YYYY",
					};
				case "en":
					return {
						baseLanguage: enUS,
						format: "MM/DD/YYYY",
					};
				case "de":
					return {
						baseLanguage: deDE,
						format: "DD/MM/YYYY",
					};
				default:
					return {
						baseLanguage: ruRU,
						format: "DD/MM/YYYY",
					};
			}
		})()
	);

	return (
		<LocalizationProvider
			adapterLocale={i18n.resolvedLanguage}
			dateAdapter={AdapterDayjs}
			localeText={
				locale.current.baseLanguage.components.MuiLocalizationProvider
					.defaultProps.localeText
			}
		>
			<DatePicker
				slotProps={{ textField: { variant } }}
				sx={sx}
				value={value}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				format={locale.current.format}
				disableFuture
			/>
		</LocalizationProvider>
	);
};
