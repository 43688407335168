import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(NavLink).attrs({
	activeClassName: "active",
})`
	padding: 1px 10px;
	height: 22px;
	border: 1px solid #DBE2F1;
	color: #4357A0;
	border-radius: 4px;
	background: #fff;
	display: flex;
	width: 100%;
	text-decoration: none;
	cursor: pointer;
	align-items: center;
	font-size: 14px;
	justify-content: ${(props) => (props.isFull ? "flex-start" : "center")};
	:hover {
		background: #ebeff5;
	}

	&.active {
		color: #4357A0;
		background-color: #dbe2f1;
	}

	:active {
		color: #4357A0;
		background-color: #dbe2f1;
	}
`;

export const UserMenu = ({ userName, isFull, closeOnClick }) => {
	const handleClick = () => {
		closeOnClick();
	};

	return (
		<Wrapper to="/profile" onClick={handleClick} isFull={isFull}>
			{isFull
				? userName
				: userName
						.split(" ")
						.map((word) => word[0])
						.join("")}
		</Wrapper>
	);
};
