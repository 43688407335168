import styled from "styled-components";
import { ReactComponent as Chevrons } from "../../icons/chevrons.svg";

const Wrapper = styled.div`
	width: 18px;
	height: 24px;
	border-radius: 4px;
	:hover {
		background: #EBEFF5;
	}
`;

const StyledIcon = styled(Chevrons)`
	transform: rotate(${(props) => (props.isFull ? 0 : 180)}deg);
`;

export const SideBarButton = ({ onClick, isFull }) => {
	return (
		<Wrapper onClick={onClick}>
			<StyledIcon isFull={isFull} />
		</Wrapper>
	);
};
