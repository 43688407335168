import styled from "styled-components";
import { ReactComponent as SupportIcon } from "../../icons/support.svg";
import { ReactComponent as NewMessageIcon } from "../../icons/supportNewMessage.svg";
import { ReactComponent as SupportOpenIcon } from "../../icons/supportOpen.svg";
import { ReactComponent as NewMessageOpenIcon } from "../../icons/supportNewMessageOpen.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dropAlert, getAvailableChat } from "../../store/supportSlice";
import { useTranslation } from "react-i18next";

const Link = styled.div`
	padding: 0 5px;
	color: #5A76A3;
	fill: #5A76A3;
	cursor: pointer;
	text-decoration: none;
	border-radius: 4px;
	display: flex;
	gap: 10px;
	align-items: center;

	:hover {
		background-color: #ebeff5;
	}

	:active {
		color: #4357A0;
		fill: #4357A0;
		background-color: #dbe2f1;
	}

	&.active {
		${(props) =>
			props.isOpen
				? `
				color: #4357A0;
				fill: #4357A0;
				background-color: #dbe2f1;
				`
				: ""}
	}
`;

const LinkText = styled.p`
	color: inherit;
	white-space: nowrap;
	padding-right: 10px;
`;

export const SupportButton = ({ isFull, openSupport, isOpen }) => {
	const { available } = useSelector((state) => state.support);
	const { t } = useTranslation();

	const dispatch = useDispatch();

	useEffect(() => {
		let interval;
		if (!available) {
			interval = setInterval(() => {
				dispatch(getAvailableChat());
			}, 60000);
		}
		return () => clearInterval(interval);
	}, [available, dispatch]);

	return (
		<Link
			isOpen={isOpen}
			className="active"
			onClick={() => {
				dispatch(dropAlert());
				openSupport();
			}}
		>
			{available ? (
				isOpen ? (
					<NewMessageOpenIcon />
				) : (
					<NewMessageIcon />
				)
			) : isOpen ? (
				<SupportOpenIcon height={"40px"} />
			) : (
				<SupportIcon height={"40px"} />
			)}
			{isFull && <LinkText>{t("support")}</LinkText>}
		</Link>
	);
};
