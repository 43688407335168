export const fetchSystem = async () =>
	await (
		await fetch("/api/settings/system", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("apiToken"),
			},
		})
	).json();
