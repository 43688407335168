import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getColorPreview } from "../../store/colorSlice";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CircleLoader from "../UI/CircleLoader";

const Image = styled.img`
	width: 280px;
	aspect-ratio: 14/11;
`;

const PlaceHolder = styled.div`
	width: 280px;
	aspect-ratio: 14/11;
	border: 1px solid #ccc;
	display: flex;
	justify-content: center;
	font-size: 24px;
	align-items: center;
`;

const ImagePreview = ({ color_id }) => {
	const { colorPreview } = useSelector((state) => state.color);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const filterById = colorPreview.filter((item) => item.id === color_id);
	const [isLoading, setIsLoading] = useState(Boolean(!filterById[0]?.blob));

	useEffect(() => {
		if (!filterById[0]?.blob)
			dispatch(getColorPreview(color_id)).then(() => setIsLoading(false));
	}, []);

	return { ...filterById }[0]?.blob ? (
		<Image src={{ ...filterById }[0]?.blob} loading="lazy" />
	) : (
		<PlaceHolder>
			{isLoading ? <CircleLoader /> : t("noExamples")}
		</PlaceHolder>
	);
};

export default ImagePreview;
