import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as TooltipPointer } from "../icons/tooltipPointer.svg";

const Wrapper = styled.div`
	position: relative;
	height: fit-content;
`;

const Tooltip = styled.div`
	padding: 4px 10px;
	background: #a9b8db;
	display: ${(props) => (props.isVisible ? "block" : "none")};
	border-radius: 4px;
	color: #fff;
	font-size: 13px;
	position: absolute;
	white-space: nowrap;
	left: calc(100% + 14px);
	top: calc(50% - 12px);
	filter: drop-shadow(0 2px 6px #393D4C40);
`;

const StyledTooltipPointer = styled(TooltipPointer)`
	position: absolute;
	height: 14px;
	top: calc(50% - 7px);
	left: -10.5px;
	display: ${(props) => (props.isVisible ? "block" : "none")};
`;

export const TooltipWrapper = ({ text, children, isOff }) => {
	const [isVisible, setIsVisible] = useState(false);
	const timeOut = useRef(null);

	const handleMouseOver = () => {
		if (!timeOut.current)
			timeOut.current = setTimeout(() => setIsVisible(true), 500);
	};

	const handleMouseLeave = () => {
		clearTimeout(timeOut.current);
		timeOut.current = null;
		setIsVisible(false);
	};

	return (
		<Wrapper
			onMouseOver={isOff ? null : handleMouseOver}
			onMouseLeave={isOff ? null : handleMouseLeave}
		>
			{children}

			{!isOff && (
				<Tooltip isVisible={isVisible}>
					<StyledTooltipPointer isVisible={isVisible} />
					{text}
				</Tooltip>
			)}
		</Wrapper>
	);
};
