import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CurrentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: 17px;
	font-family: "Roboto", sans-serif;
	width: fit-content;
`;
const InfoWrapper = styled.div`
	display: flex;
	align-items: baseline;
	font-family: "Roboto", sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	white-space: nowrap;
	line-height: 22px;
	width: fit-content;
`;

const CurrentDescription = ({ title, info = "-", width, loaded }) => {
	if (!info) return <></>;
	return (
		<CurrentWrapper>
			<TitleWrapper width={width ? 1 : 0}>{title}</TitleWrapper>

			{loaded ? (
				<InfoWrapper>{info}</InfoWrapper>
			) : (
				<Skeleton width={"60px"} height="24px" />
			)}
		</CurrentWrapper>
	);
};

export default CurrentDescription;
