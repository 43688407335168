import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExampleZipImage from "./ExampleZipImage";
import { deleteImageRef, reset } from "../../store/colorSlice";
import { useTranslation } from "react-i18next";

const DeleteExample = ({ setAdd, color_id }) => {
	const { imagesRefList, imageRefsArr } = useSelector((state) => state.color);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const deleteCilckHandler = () => {
		imageRefsArr.map((item) => {
			dispatch(deleteImageRef(item));
		});
		dispatch(reset());
		setTimeout(() => {
			setAdd("example");
		}, 1500);
	};

	return (
		<Box pt="24px">
			<Box>
				<Box
					display={"flex"}
					flexDirection="row"
					justifyContent={"space-between"}
					alignItems="center"
				>
					<Typography fontSize={"18px"}>
						{t("color.modal_delete_desc")}
					</Typography>
					<Box>
						<Button
							sx={{
								border: "2px solid #FF3F3F",
								fontSize: "18px",
								color: "#FF3F3F",
								borderRadius: "10px",
								padding: "5px 33px",
								marginRight: "25px",
							}}
							onClick={deleteCilckHandler}
						>
							{t("color.modal_delete_btn")}
						</Button>
						<Button
							sx={{
								border: "2px solid black",
								fontSize: "18px",
								color: "black",
								borderRadius: "10px",
								padding: "5px 33px",
								marginRight: "25px",
								textTransform: "none",
							}}
							onClick={() => setAdd("example")}
						>
							{t("color.btn_cancel")}
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				display={"flex"}
				flexDirection="row"
				gap={"16px"}
				flexWrap="wrap"
				mt="60px"
			>
				{imagesRefList?.map((item) => (
					<ExampleZipImage
						key={item.ref}
						imageRef={item.ref}
						del={true}
					/>
				))}
			</Box>
		</Box>
	);
};

export default DeleteExample;
