import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ComboBox({
	options,
	value,
	nameLayout,
	setValue,
	size,
	variant,
}) {
	const [inputValue, setInputValue] = React.useState("");

	const handleChange = (_, newValue) => {
		setValue(newValue);
	};

	return (
		<Autocomplete
			value={value}
			onChange={handleChange}
			inputValue={inputValue}
			size={size}
			onInputChange={(_, newInputValue) => {
				setInputValue(newInputValue);
			}}
			disablePortal
			id="combo-box-demo"
			options={options}
			getOptionLabel={(option) => option?.name}
			sx={{ minWidth: 180, flex: 1 }}
			renderInput={(params) => (
				<TextField variant={variant} {...params} label={nameLayout} />
			)}
		/>
	);
}
