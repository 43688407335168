import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeNewBatchInfo } from "../store/opPanelSlice";
import styled from "@emotion/styled";

const Content = styled.div`
	display: flex;
	width: 552px;
	gap: 2rem;
	flex-direction: column;
`;

const Header = styled.p`
`;

const Wrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

export const BatchCorrectionForm = ({ setIsOpen }) => {
	const { possibleMolds, possibleColors } = useSelector(
		(state) => state.opPanel
	);
	const [selectedMold, setSelectedMold] = useState(possibleMolds[0]?.id);
	const [selectedColor, setSelectedColor] = useState(possibleColors[0]?.id);
	const dispatch = useDispatch();

	return (
		<Content>
			<Header>Внести изменения</Header>
			<Wrapper>
				Форма:
				<FormControl>
					<Select
						value={selectedMold}
						onChange={(event) =>
							setSelectedMold(event.target.value)
						}
					>
						{possibleMolds.map((mold) => (
							<MenuItem value={mold.id}>{mold.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				Цвет:
				<FormControl>
					<Select
						value={selectedColor}
						onChange={(event) =>
							setSelectedColor(event.target.value)
						}
					>
						{possibleColors.map((color) => (
							<MenuItem value={color.id}>{color.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Wrapper>
			<Wrapper>
				<Button
					onClick={() => {
						dispatch(
							changeNewBatchInfo({
								decision: true,
								product_id: selectedMold,
								color_id: selectedColor,
							})
						);
						setIsOpen(false);
					}}
				>
					Применить
				</Button>
				<Button onClick={() => setIsOpen(false)}>Отмена</Button>
			</Wrapper>
		</Content>
	);
};
