import { Button } from "@mui/material";
import ComboBox from "./ComboBox";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BigCircleLoader from "./UI/BigCircleLoader";
import { submitSplit } from "../store/newReportSlice";
import { useTranslation } from "react-i18next";
import { getColorList, getFormList } from "../store/colorSlice";
import { getNoTokenUsers } from "../store/userSlice";
import { LocalizedDateTimePicker } from "./DateTimePicker";
import dayjs from "dayjs";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	width: 700px;
	overflow: visible;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	flex: 1;
	overflow: visible;
`;

const ColumnsWrapper = styled.div`
	display: flex;
	width: 100%;
	gap: 1rem;
`;

const Header = styled.p`
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-end;
	flex: 1;
`;

const Label = styled.p`
	width: 30%;
`;

export const BatchSplitForm = ({ closeForm, batch, filters }) => {
	const { colorList, formList } = useSelector((state) => state.color);
	const { noTokenUsers } = useSelector((state) => state.user);
	const { requestedDates } = useSelector((state) => state.newReport);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();

	const [firstHalfForm, setFirstHalfForm] = useState({
		uuid: batch.product_id,
		name: batch.product_name,
	});
	const [firstHalfColor, setFirstHalfColor] = useState({
		uuid: batch.color_id,
		name: batch.color_name,
	});
	const [firstHalfUser, setFirstHalfUser] = useState({
		uuid: batch.user?.uuid ?? null,
		name: batch.user?.name ?? "",
	});

	const [secondHalfForm, setSecondHalfForm] = useState({
		uuid: batch.product_id,
		name: batch.product_name,
	});
	const [secondHalfColor, setSecondHalfColor] = useState({
		uuid: batch.color_id,
		name: batch.color_name,
	});
	const [secondHalfUser, setSecondHalfUser] = useState({
		uuid: batch.user?.uuid ?? null,
		name: batch.user?.name ?? "",
	});

	const [splitTimestamp, setSplitTimestamp] = useState(dayjs(batch?.bdt));

	useEffect(() => {
		console.log(splitTimestamp.format());
	}, [splitTimestamp]);

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		Promise.allSettled([
			dispatch(getFormList()),
			dispatch(getColorList()),
			dispatch(getNoTokenUsers()),
		]).then(() => setIsLoading(false));
	}, []);

	return (
		<Content>
			<Header>{t("newReport.splitBatch")}</Header>
			{!isLoading ? (
				<>
					<Wrapper>
						<Label>{t("newReport.splitTimestamp")}:</Label>
						<LocalizedDateTimePicker
							maxDateTime={dayjs(batch?.edt)}
							minDateTime={dayjs(batch?.bdt)}
							value={splitTimestamp}
							setValue={setSplitTimestamp}
						/>
					</Wrapper>
					<ColumnsWrapper>
						<Column>
							<Header>{t("newReport.leftSide")}</Header>
							<Wrapper>
								<Label>{t("newReport.form")}:</Label>
								<ComboBox
									options={formList}
									setValue={setFirstHalfForm}
									value={firstHalfForm}
								/>
							</Wrapper>
							<Wrapper>
								<Label>{t("newReport.color")}:</Label>
								<ComboBox
									options={colorList}
									setValue={setFirstHalfColor}
									value={firstHalfColor}
								/>
							</Wrapper>
							<Wrapper>
								<Label>{t("newReport.operator")}:</Label>
								<ComboBox
									options={noTokenUsers
										.filter(
											(user) => user.role === "operator"
										)
										.map((user) => ({
											name: user.name,
											uuid: user.id,
										}))}
									setValue={setFirstHalfUser}
									value={firstHalfUser}
								/>
							</Wrapper>
						</Column>
						<Column>
							<Header>{t("newReport.rightSide")}</Header>
							<Wrapper>
								<Label>{t("newReport.form")}:</Label>
								<ComboBox
									options={formList}
									setValue={setSecondHalfForm}
									value={secondHalfForm}
								/>
							</Wrapper>
							<Wrapper>
								<Label>{t("newReport.color")}:</Label>
								<ComboBox
									options={colorList}
									setValue={setSecondHalfColor}
									value={secondHalfColor}
								/>
							</Wrapper>
							<Wrapper>
								<Label>{t("newReport.operator")}:</Label>
								<ComboBox
									options={noTokenUsers
										.filter(
											(user) => user.role === "operator"
										)
										.map((user) => ({
											name: user.name,
											uuid: user.id,
										}))}
									setValue={setSecondHalfUser}
									value={secondHalfUser}
								/>
							</Wrapper>
						</Column>
					</ColumnsWrapper>
					<ButtonsWrapper>
						<Wrapper>
							<Button sx={{ color: "red" }} onClick={closeForm}>
								{t("newReport.cancel")}
							</Button>
							<Button
								disabled={
									+new Date(batch?.edt) <
										+new Date(splitTimestamp.format()) ||
									+new Date(batch?.bdt) >
										+new Date(splitTimestamp.format()) ||
									splitTimestamp.format() === "Invalid Date"
								}
								onClick={() => {
									closeForm();
									dispatch(
										submitSplit({
											batchId: batch.batch_id,
											product_id_l: firstHalfForm.uuid,
											color_id_l: firstHalfColor.uuid,
											user_id_l: firstHalfUser.uuid,
											product_id_r: secondHalfForm.uuid,
											color_id_r: secondHalfColor.uuid,
											user_id_r: secondHalfUser.uuid,
											split_dt: new Date(
												splitTimestamp.format()
											).toISOString(),
											filters,
											requestedDates: requestedDates,
										})
									);
								}}
							>
								{t("newReport.apply")}
							</Button>
						</Wrapper>
					</ButtonsWrapper>
				</>
			) : (
				<BigCircleLoader />
			)}
		</Content>
	);
};
