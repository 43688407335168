import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeSystem, getSystem } from "../../store/settingsSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { fetchSystem } from "../../helper/fetchSystem";

const DryContacts = () => {
	let { system } = useSelector((state) => state.settings);

	const [dryState, setDryState] = useState(false);
	const [wetState, setWetState] = useState(false);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		if (system?.emit_dryside) {
			setDryState(true);
		} else if (system?.emit_dryside === false) {
			setDryState(false);
		}
	}, [system?.emit_dryside]);

	useEffect(() => {
		if (system?.emit_wetside) {
			setWetState(true);
		} else if (system?.emit_wetside === false) {
			setWetState(false);
		}
	}, [system?.emit_wetside]);

	const drySideHandler = async () => {
		const currentSystem = await fetchSystem();
		const settingsState = {
			...currentSystem,
			emit_wetside: wetState,
			emit_dryside: !dryState,
		};
		dispatch(changeSystem({ settingsState: settingsState }));
		setDryState(!dryState);
	};

	const wetSideHandler = async () => {
		const currentSystem = await fetchSystem();
		const settingsState = {
			...currentSystem,
			emit_wetside: !wetState,
			emit_dryside: dryState,
		};
		dispatch(changeSystem({ settingsState: settingsState }));
		setWetState(!wetState);
	};

	return (
		<Box
			sx={{
				display: "flex",
				maxWidth: "fit-content",
				flexDirection: "column",
				gap: "1rem",
			}}
		>
			<Typography fontFamily="Roboto" fontSize={"24px"} fontWeight="bold">
				{t("dry.contacts")}
			</Typography>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch onChange={drySideHandler} checked={dryState} />
					}
					label={t("dry.dry_state")}
				/>
				<FormControlLabel
					control={
						<Switch onChange={wetSideHandler} checked={wetState} />
					}
					label={t("dry.wet_state")}
				/>
			</FormGroup>
		</Box>
	);
};

export default DryContacts;
