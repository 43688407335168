import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../store/authSlice";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);

	const signInAndContinue = (username, password) => {
		dispatch(authLogin({ username, password }));
	};

	const value = {
		token: authState.apiToken,
		signInAndContinue: signInAndContinue,
	};

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};
