import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

export default function ColorDropList({ brightness, setting, setSetting }) {
	const [value, setValue] = React.useState(brightness);
	const { t } = useTranslation();

	const handleChange = (event) => {
		setValue(event.target.value);
		setSetting({ ...setting, brightness: event.target.value });
	};

	return (
		<Box sx={{ minWidth: 120 }}>
			<FormControl variant="standard" fullWidth>
				<InputLabel id="demo-simple-select-label">
					{t("color.color_light")}
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={value}
					label={t("color.color_light")}
					onChange={handleChange}
				>
					<MenuItem value={"bright"}>{t("color.bright")}</MenuItem>
					<MenuItem value={"normal"}>{t("color.normal")}</MenuItem>
					<MenuItem value={"dark"}>{t("color.dark")}</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
}
