import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getBatchesByDate = createAsyncThunk(
	"/inspection/batches",
	async ({ date }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const batchesStats = await api(
				`/api/batches/date?dt_begin=${encodeURIComponent(
					date
				)}&dt_end=${encodeURIComponent(date)}&limit=100`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			const batches = [...batchesStats]
				.reverse()
				.reduce((result, stat) => {
					const temp = [...result];
					if (temp.at(-1)?.batch_id === stat.batch_id) {
						temp.at(-1).edt = stat.edt;
					} else {
						temp.push(stat);
					}
					return temp;
				}, []);

			return batches;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const startNewCheck = createAsyncThunk(
	"/inspection/newCheck",
	async ({ batchId, palletsLimit }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			const sessionId = (
				await api(`/api/batch_accuracy_checks`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						batch_id: batchId,
						n_pallets: palletsLimit,
					}),
				})
			).id;
			const limit = (
				await api(
					`/api/batch_accuracy_checks/${encodeURIComponent(
						sessionId
					)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				)
			).n_pallets_all;
			dispatch(getNextPallet({ sessionId: sessionId }));
			return { sessionId, limit };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getAccuracyChecks = createAsyncThunk(
	"/inspection/checks",
	async (_, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const checks = await api(`/api/batch_accuracy_checks?desc=true`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			const result = checks.map((check) => ({
				...check,
				batchDate: check.batch.begin_dt,
				batchName: `${check.batch.product.name} - ${check.batch.color.name}`,
				userName: check.user.name,
				userUuid: check.user.uuid,
			}));
			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getNextPallet = createAsyncThunk(
	"/inspection/nextPallet",
	async ({ sessionId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return await api(
				`/api/batch_accuracy_checks/${encodeURIComponent(
					sessionId
				)}/pallet`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const submitPalletCheck = createAsyncThunk(
	"/inspection/submitPallet",
	async (
		{
			sessionId,
			palletId,
			falsePositive,
			falseNegative,
			troubleClass,
			isLast,
		},
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			await api(
				`/api/batch_accuracy_checks/${encodeURIComponent(
					sessionId
				)}/submit`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						pallet_id: palletId,
						fn_sectors: falseNegative,
						fp_sectors: falsePositive,
						is_dropped: troubleClass === "3",
						is_empty: troubleClass === "2",
						is_bad_segmented: troubleClass === "12",
					}),
				}
			);
			if (!isLast) {
				dispatch(getNextPallet({ sessionId: sessionId }));
			} else {
				dispatch(getAccuracyChecks());
			}
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getPalletInfo = createAsyncThunk(
	"/inspection/palletInfo",
	async ({ id }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			const [scheme, image] = (
				await Promise.allSettled([
					api(`/api/pallets/${id}/scheme`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}),
					api(`/api/pallets/${id}/image`, {
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}),
					dispatch(getPalletDecision({ id })),
				])
			).map((response) => response.value);
			return { scheme, image };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSectorInfo = createAsyncThunk(
	"/inspection/sectorInfo",
	async ({ palletId, crop, sectorId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const [imageCrop, decision] = (
				await Promise.allSettled([
					api(
						`/api/pallets/${palletId}/image?crop=%5B${crop[0]}%2C%20${crop[1]}%2C%20${crop[2]}%2C%20${crop[3]}%5D`,
						{
							method: "GET",
							headers: {
								"Content-Type": "image/webp",
							},
						}
					),
					api(`/api/pallets/${palletId}/${sectorId}/decision`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}),
				])
			).map((response) => response.value);

			return { imageCrop, decision };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getPalletDecision = createAsyncThunk(
	"/inspection/palletDecision",
	async ({ id }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return (
				await api(`/api/pallets/${id}/decision`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				})
			).annotations;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const submitPalletDecision = createAsyncThunk(
	"/inspection/submitPalletDecision",
	async ({ id, annotations }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return await api(`/api/pallets/${id}/decision`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					annotations,
				}),
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const dotCloudDownload = createAsyncThunk(
	"/inspection/dotCloud",
	async ({ palletId }, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const cloud = await api(`/api/pallets/${palletId}/cloud/download`, {
				method: "GET",
				headers: {
					"Content-Type": "application/octet-stream",
				},
			});
			const download = document.createElement("a");
			download.style.display = "none";
			download.href = cloud.url;
			download.download = cloud.fileName;
			document.body.appendChild(download);
			download.click();
			download.remove();
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDefaultColorSettings = createAsyncThunk(
	"/inspectionSettings/mono",
	async (_, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			const result = (
				await Promise.allSettled([
					api(`/api/defectoscopy_settings/mono`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}),
					api(`/api/defectoscopy_settings/colormix`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}),
				])
			)
				.map((response) => response.value)
				.filter((response) => response);
			if (!result.length) throw Error("Failed to load settings");
			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSpecialSettings = createAsyncThunk(
	"/inspectionSettings/special",
	async (_, { extra, rejectWithValue }) => {
		const { api } = extra;
		try {
			return await api(`/api/defectoscopy_settings/special`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const setDefectInspectionSettings = createAsyncThunk(
	"/inspectionSettings/setSettings",
	async (
		{ product_id, color_id, settings },
		{ extra, rejectWithValue, dispatch }
	) => {
		const { api } = extra;
		try {
			const result = await api(
				`/api/defectoscopy_settings?${
					product_id ? `product_id=${product_id}` : ""
				}${
					color_id
						? `${product_id ? "&" : ""}color_id=${color_id}`
						: ""
				}`,
				{
					method: "PATCH",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(settings),
				}
			);
			Promise.allSettled([
				dispatch(getSpecialSettings()),
				dispatch(getDefaultColorSettings()),
			]);
			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const deleteSpecialSettings = createAsyncThunk(
	"/inspectionSettings/deleteSpecial",
	async ({ product_id, color_id }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			const result = await api(
				`/api/defectoscopy_settings?${
					product_id ? `product_id=${product_id}` : ""
				}${
					color_id
						? `${product_id ? "&" : ""}color_id=${color_id}`
						: ""
				}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			dispatch(getSpecialSettings());
			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addSpecialSettings = createAsyncThunk(
	"/inspectionSettings/addSpecial",
	async ({ settings }, { extra, rejectWithValue, dispatch }) => {
		const { api } = extra;
		try {
			const result = await api(`/api/defectoscopy_settings`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(settings),
			});
			dispatch(getSpecialSettings());
			return result;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const initialState = {
	previousInspections: [],
	possibleBatches: [],
	pallets: [],
	currentCheckSession: null,
	sectorStatus: "pending",
	pallet: null,
	scheme: null,
	image: null,
	limit: null,
	date: null,
	currentNumber: 0,
	checkStatus: "pending",
	submitStatus: "pending",
	imageCrop: null,
	decision: null,
	palletInfoStatus: "pending",
	cloudStatus: "fulfilled",
	defaultColorSettings: [],
	specialSettings: [],
	currentPalletDecision: null,
};

const defectsInspectionSlice = createSlice({
	name: "defectInspection",
	initialState,
	reducers: {
		setTestLimit: (state, action) => {
			state.limit = action.payload;
		},
		setTestDate: (state, action) => {
			state.date = action.payload;
		},
		setCounter: (state, action) => {
			state.currentNumber = action.payload;
		},
		clearState: (state) => {
			Object.keys(initialState).forEach((key) => {
				state[key] = initialState[key];
			});
		},
		setSessionId: (state, action) => {
			state.currentCheckSession = action.payload;
		},
		displayDefect: (state, action) => {
			const sectorIndex = state.scheme.scheme.sectors.findIndex(
				(sector) => sector.id === action.payload.id
			);
			state.scheme.scheme.sectors[sectorIndex].user_decision =
				action.payload.decision;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBatchesByDate.pending)
			.addCase(getBatchesByDate.fulfilled, (state, action) => {
				state.possibleBatches = action.payload;
			})
			.addCase(getBatchesByDate.rejected)
			.addCase(getPalletInfo.pending, (state) => {
				state.palletInfoStatus = "pending";
			})
			.addCase(getPalletInfo.fulfilled, (state, action) => {
				state.palletInfoStatus = "fulfilled";
				state.scheme = action.payload.scheme;
				state.image = action.payload.image;
			})
			.addCase(getPalletInfo.rejected, (state) => {
				state.palletInfoStatus = "rejected";
			})
			.addCase(getSectorInfo.pending, (state) => {
				state.sectorStatus = "pending";
			})
			.addCase(getSectorInfo.fulfilled, (state, action) => {
				state.sectorStatus = "fulfilled";
				state.decision = action.payload.decision;
				state.imageCrop = action.payload.imageCrop;
			})
			.addCase(getSectorInfo.rejected, (state) => {
				state.sectorStatus = "rejected";
			})
			.addCase(dotCloudDownload.pending, (state) => {
				state.cloudStatus = "pending";
			})
			.addCase(dotCloudDownload.fulfilled, (state) => {
				state.cloudStatus = "fulfilled";
			})
			.addCase(dotCloudDownload.rejected, (state) => {
				state.cloudStatus = "rejected";
			})
			.addCase(getAccuracyChecks.pending, (state) => {
				state.checkStatus = "pending";
			})
			.addCase(getAccuracyChecks.fulfilled, (state, action) => {
				state.checkStatus = "fulfilled";
				state.previousInspections = action.payload;
			})
			.addCase(getAccuracyChecks.rejected, (state) => {
				state.checkStatus = "rejected";
			})
			.addCase(startNewCheck.pending)
			.addCase(startNewCheck.fulfilled, (state, action) => {
				state.currentCheckSession = action.payload.sessionId;
				state.limit = action.payload.limit;
			})
			.addCase(startNewCheck.rejected)
			.addCase(getNextPallet.pending)
			.addCase(getNextPallet.fulfilled, (state, action) => {
				state.pallet = action.payload;
			})
			.addCase(getNextPallet.rejected)
			.addCase(submitPalletCheck.pending, (state) => {
				state.submitStatus = "pending";
			})
			.addCase(submitPalletCheck.fulfilled, (state) => {
				state.submitStatus = "fulfilled";
			})
			.addCase(submitPalletCheck.rejected, (state) => {
				state.submitStatus = "rejected";
			})
			.addCase(getDefaultColorSettings.fulfilled, (state, action) => {
				state.defaultColorSettings = action.payload;
			})
			.addCase(getSpecialSettings.fulfilled, (state, action) => {
				state.specialSettings = action.payload.map((rule) => ({
					...rule,
					uuid: rule.color_id ?? "" + rule.product_id ?? "",
				}));
			})
			.addCase(getPalletDecision.fulfilled, (state, action) => {
				state.currentPalletDecision = action.payload;
			});
	},
});

export const {
	setTestLimit,
	setTestDate,
	clearState,
	displayDefect,
	setCounter,
	setSessionId,
} = defectsInspectionSlice.actions;

export default defectsInspectionSlice.reducer;
