import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as HelpLogo } from "../../icons/help.svg";
import Tooltip from "../UI/Tooltip";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 350px;
`;
const Title = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 27px;
	padding-bottom: 28px;
	display: flex;
	flex-direction: row;
`;

const Text = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 27px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
`;

const TextWrap = styled.div`
	display: flex;
	flex-direction: row;
`;

const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const ChangeWrap = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
`;

const LogoWrap = styled.div`
	margin-left: 8px;
	cursor: pointer;
`;

//	&uarr;

const StatHeights = () => {
	return (
		<Wrapper>
			<Title>
				Средние высоты
				<LogoWrap>
					<Tooltip content={"Разница значений от прошлого месяца."}>
						<HelpLogo />
					</Tooltip>
				</LogoWrap>
			</Title>
			<TextWrap>
				<ColumnWrapper style={{ paddingRight: "45px" }}>
					<Title>Номинал</Title>
					<Text>40.0 мм</Text>
					<Text>60.0 мм</Text>
					<Text>80.0 мм</Text>
					<Text>100.0 мм</Text>
					<Text>200.0 мм</Text>
					<Text>300.0 мм</Text>
				</ColumnWrapper>
				<ColumnWrapper>
					<Title>Факт</Title>
					<Text>
						41.3 мм{" "}
						<ChangeWrap style={{ color: "red" }}>
							&uarr; 3.2%
						</ChangeWrap>{" "}
					</Text>
					<Text>
						62.1 мм{" "}
						<ChangeWrap style={{ color: "red" }}>
							&uarr; 1.1%
						</ChangeWrap>{" "}
					</Text>
					<Text>
						82.1 мм{" "}
						<ChangeWrap style={{ color: "red" }}>
							&uarr; 2.6%
						</ChangeWrap>{" "}
					</Text>
					<Text>
						101.6 мм{" "}
						<ChangeWrap style={{ color: "green" }}>
							{" "}
							&darr; 0.8%
						</ChangeWrap>{" "}
					</Text>
					<Text>
						201.8 мм{" "}
						<ChangeWrap style={{ color: "red" }}>
							&uarr; 3.1%
						</ChangeWrap>{" "}
					</Text>
					<Text>
						301.5 мм{" "}
						<ChangeWrap style={{ color: "green" }}>
							{" "}
							&darr; 1.2%
						</ChangeWrap>{" "}
					</Text>
				</ColumnWrapper>
			</TextWrap>
		</Wrapper>
	);
};

export default StatHeights;
