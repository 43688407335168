import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { createPluginWithSetLimits } from "../helper/overlimitPointerPlugin";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const DensityChart = ({ processedChart }) => {
	const { system } = useSelector((state) => state.settings);
	const isDm = system?.density_measure_unit === "kg_dm3";
	const schemeChart = processedChart?.history;

	const markArr = [...schemeChart]?.reverse()?.map((item) => {
		if (item.density === null || !isDm) {
			return item.density;
		} else {
			return item.density / 1000;
		}
	});

	let minY = processedChart?.density_min;
	let maxY = processedChart?.density_max;

	if (isDm) {
		minY = minY / 1000;
		maxY = maxY / 1000;
	}

	const densityArr = [...schemeChart]?.reverse().map((item) => {
		let density;
		if (item.density === null) {
			density = (minY + maxY) / 2;
		} else {
			density = item.density;
		}
		const arr = [];
		if (isDm) {
			arr.push(density / 1000);
			arr.push((density + 5) / 1000);
			return arr;
		}
		arr.push(density);
		arr.push(density + 5);
		return arr;
	});
	const dottedMaxLine = {
		id: "dottedMaxLine",
		beforeDatasetsDraw(chart, args, options) {
			const {
				ctx,
				chartArea: { top, rigth, bottom, left, width, height },
				scales: { x, y },
			} = chart;
			ctx.save();

			ctx.strokeStyle = "red";
			ctx.setLineDash([5, 5]);
			ctx.strokeRect(left, y.getPixelForValue(maxY), width, 0);
			ctx.restore();
		},
	};

	const dottedMinLine = {
		id: "dottedMaxLine",
		beforeDatasetsDraw(chart, args, options) {
			const {
				ctx,
				chartArea: { top, rigth, bottom, left, width, height },
				scales: { x, y },
			} = chart;
			ctx.save();

			ctx.strokeStyle = "blue";
			ctx.setLineDash([5, 5]);
			ctx.strokeRect(left, y.getPixelForValue(minY), width, 0);
			ctx.restore();
		},
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		animation: false,
		plugins: {
			legend: {
				display: false,
				position: "top",
			},
			title: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},

		scales: {
			y: {
				min: minY - (isDm ? 70 / 1000 : 70),
				max: maxY + (isDm ? 70 / 1000 : 70),
				afterFit: function (scaleInstance) {
					scaleInstance.width = 55;
				},
			},
			x: {
				ticks: {
					// Include a dollar sign in the ticks
					callback: function (value, index, values) {
						return "";
					},
				},
			},
		},
	};

	const overlimitPointer = createPluginWithSetLimits(
		minY - (isDm ? 70 / 1000 : 70),
		maxY + (isDm ? 70 / 1000 : 70),
		[...schemeChart].reverse().map((item) => item.content === 0),
		"#D9DD12"
	);

	const mockLabels = [...Array(30).keys()];

	const labels = mockLabels
		.map((label) => {
			if (label === 0) return label;
			return label * -1;
		})
		.reverse();

	const data = {
		labels,
		datasets: [
			{
				label: "Плотность",
				data: densityArr ?? [],
				backgroundColor: markArr.map((item, index) =>
					item === null
						? "rgba(0, 0, 0, 0)"
						: item > maxY
						? "#D9DD12"
						: item < minY
						? "#FF3F3F"
						: "rgb(12, 156, 35)"
				),
				borderSkipped: false,
			},
		],
	};

	return (
		<Box
			sx={{
				aspectRatio: "9.5/3",
				aspectRatio: "3/1",
				width: "100%",
				position: "relative",
				mt: "1rem",
				paddingBottom: "10px",
			}}
		>
			<Bar
				redraw={true}
				options={options}
				plugins={[dottedMaxLine, dottedMinLine, overlimitPointer]}
				data={data}
			/>
		</Box>
	);
};

export default DensityChart;
