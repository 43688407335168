import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography } from "@mui/material";

export default function FormSearchSelect({
	formList,
	defaultForm,
	nameLayout,
	setDefaultForm,
}) {
	const [form, setForm] = React.useState("");
	const [inputValue, setInputValue] = React.useState("");

	const handleChange = (_, newValue) => {
		setForm(newValue);
		setDefaultForm([newValue]);
	};

	return (
		<Autocomplete
			value={form}
			onChange={handleChange}
			inputValue={inputValue}
			autoFocus
			onInputChange={(_, newInputValue) => {
				setInputValue(newInputValue);
			}}
			disablePortal
			id="combo-box-demo"
			options={
				formList
					? formList.sort((a, b) =>
							a.formName.localeCompare(b.formName)
					  )
					: []
			}
			getOptionLabel={(option) =>
				option.formName
					? option.formName
					: defaultForm
					? defaultForm
					: ""
			}
			renderOption={(props, option) => (
				<Box
					{...props}
					key={option.uuid}
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: "0.5rem",
					}}
				>
					{option.formName}
				</Box>
			)}
			sx={{ minWidth: 180, flex: 1 }}
			renderInput={(params) => (
				<TextField {...params} label={nameLayout} />
			)}
		/>
	);
}
