import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { ReactComponent as ArrowDownLogo } from "../icons/arrow_down.svg";
import { useTranslation } from "react-i18next";

const TitleWrapper = styled.div`
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	display: flex;
	flex-direction: ${(props) => (props.isDrySide ? "row-reverse" : "row")};
	height: 45px;
	justify-content: space-between;
	padding-bottom: 6px;
`;

const DivWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const CurrentTactWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.isDrySide ? "flex-start" : "flex-end")};
`;

const CurrentTact = styled.div`
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-size: ${(props) => (props.isSmall ? "18px" : "14px")} ;
	line-height: 17px;
	text-transform: lowercase;
`;

const ArrowLogoWrapper = styled.div`
	width: ${(props) => (props.isSmall ? "calc(2 * 1.2vw)" : "calc(2 * 0.6vw)")};
	display: flex;
	justify-content: flex-end;
	margin-left: ${(props) => (props.isDrySide ? "4rem" : "0")};
`;

const DefectsTitle = ({ isDrySide }) => {
	const isSmall = useMediaQuery("(max-width: 1200px)");
	const isPortrait = useMediaQuery("(orientation: portrait)");
	const { t } = useTranslation();

	return (
		<TitleWrapper isDrySide={isDrySide}>
			<DivWrapper>
				{t(`panel.${isDrySide ? "nextTacts" : "prevTacts"}.title`)}
			</DivWrapper>
			<CurrentTactWrapper isDrySide={isDrySide}>
				<CurrentTact isSmall={isSmall || isPortrait}>
					{t("panel.prevTacts.current")}
				</CurrentTact>
				<ArrowLogoWrapper
					isSmall={isSmall || isPortrait}
					isDrySide={isDrySide}
				>
					<ArrowDownLogo />
				</ArrowLogoWrapper>
			</CurrentTactWrapper>
		</TitleWrapper>
	);
};

export default DefectsTitle;
