import React, { useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setDefectInspectionSettings } from "../../store/defectsInspectionSlice";

const ModalForDefects = ({
	open,
	onClose,
	uuid,
	crack_threshold,
	pit_threshold,
	stain_threshold,
	destruction_threshold,
	name,
}) => {
	const [value, setValue] = useState({
		crack_threshold,
		pit_threshold,
		stain_threshold,
		destruction_threshold,
	});

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleClick = () => {
		const settings = {
			pit_threshold: value.pit_threshold,
			crack_threshold: value.crack_threshold,
			stain_threshold: value.stain_threshold,
			destruction_threshold: value.destruction_threshold,
		};
		dispatch(setDefectInspectionSettings({ color_id: uuid, settings }));
		onClose();
	};

	return (
		<Box>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{t("color.defecto")} - {t(`color.${name}`)}
				</DialogTitle>
				<DialogContent
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
						}}
					>
						<Typography>{t("color.crack")}</Typography>
						<TextField
							error={Number(value) > 1}
							value={Number(value.crack_threshold)}
							onChange={(e) =>
								setValue({
									...value,
									crack_threshold: e.target.value,
								})
							}
							type="number"
							helperText={t("color.helper")}
							InputProps={{
								inputProps: {
									max: 1,
									min: 0,
									step: ".01",
								},
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
						}}
					>
						<Typography>{t("color.pit")}</Typography>
						<TextField
							error={Number(value) > 1}
							value={Number(value.pit_threshold)}
							onChange={(e) =>
								setValue({
									...value,
									pit_threshold: e.target.value,
								})
							}
							type="number"
							helperText={t("color.helper")}
							InputProps={{
								inputProps: {
									max: 1,
									min: 0,
									step: ".01",
								},
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
						}}
					>
						<Typography>{t("color.stain")}</Typography>
						<TextField
							error={Number(value) > 1}
							value={Number(value.stain_threshold)}
							onChange={(e) =>
								setValue({
									...value,
									stain_threshold: e.target.value,
								})
							}
							type="number"
							helperText={t("color.helper")}
							InputProps={{
								inputProps: {
									max: 1,
									min: 0,
									step: ".01",
								},
							}}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
						}}
					>
						<Typography>{t("color.destruction")}</Typography>
						<TextField
							error={Number(value) > 1}
							value={Number(value.destruction_threshold)}
							onChange={(e) =>
								setValue({
									...value,
									destruction_threshold: e.target.value,
								})
							}
							type="number"
							helperText={t("color.helper")}
							InputProps={{
								inputProps: {
									max: 1,
									min: 0,
									step: ".01",
								},
							}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{
							color: "#000000",
							border: "1px solid #000000",
							borderRadius: "10px",
							p: "4px 12px 4px 12px",
						}}
						onClick={onClose}
					>
						{t("color.btn_cancel")}
					</Button>
					<Button
						sx={{
							color: "#7CC6B6",
							border: "1px solid #7CC6B6",
							borderRadius: "10px",
							p: "4px 12px 4px 12px",
						}}
						disabled={Number(value) > 1}
						onClick={handleClick}
						autoFocus
					>
						{t("color.change")}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ModalForDefects;
