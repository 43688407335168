import styled from "styled-components";
import { DialogWrapper } from "./DialogWrapper";
import { useDispatch } from "react-redux";
import { deleteSpecialSettings } from "../store/defectsInspectionSlice";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`;

const Label = styled.p``;

export const DefectInspectionRuleDeleteDialog = ({ open, onClose, rule }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const deleteRule = () => {
		dispatch(
			deleteSpecialSettings({
				product_id: rule.product_id,
				color_id: rule.color_id,
			})
		);
		onClose();
	};

	return (
		<DialogWrapper open={open} onClose={onClose}>
			<Content>
				<Label>{t("defectInspection.deleteRule")}</Label>
				<ButtonWrapper>
					<Button sx={{ color: "red" }} onClick={onClose}>
						{t("cancel")}
					</Button>
					<Button onClick={deleteRule}>
						{t("color.modal_delete_btn")}
					</Button>
				</ButtonWrapper>
			</Content>
		</DialogWrapper>
	);
};
