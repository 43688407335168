import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

export default function ColorSelect({
	colorList,
	defaultColor = "",
	nameLayout,
	setDefaultColor,
}) {
	const { t } = useTranslation();

	const handleChange = (event) => {
		const filtered = colorList?.filter(
			(color) => color.uuid === event.target.value
		)[0];
		setDefaultColor(filtered);
	};

	return (
		<Box sx={{ minWidth: 180, flex: 1 }}>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">
					{nameLayout ? nameLayout : t("learning.color")}
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					label={t("learning.color")}
					value={defaultColor}
					onChange={handleChange}
				>
					{[...colorList]
						?.sort((a, b) => a.name.localeCompare(b.name))
						?.map((color) => (
							<MenuItem key={color.uuid} value={color.uuid}>
								{color.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
	);
}
