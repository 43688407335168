import { Box, Icon, Typography } from "@mui/material";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TableWrapper = styled.div`
	width: 100%;
	overflow: scroll;
	background-color: white;
	height: ${(props) => props.height};
`;

const StyledTable = styled.table`
	border-spacing: 0;
	position: relative;
	width: 100%;
`;

const ButtonsWrapper = styled.div`
	display: ${(props) => (props.isDisplayed ? "flex" : "none")};
	position: absolute;
	background-color: inherit;
	height: 33px;
	padding: 3px 0;
	top: -31px;
	left: ${(props) => props.left};
	border-radius: 5px 5px 0 0;
	box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled.div`
	padding: 1px 5px;
	transition-duration: 100ms;
	width: 35px;
	&:hover {
		color: black;
	};
	color: #00000090;
	&:last-of-type {
		border-left: 1px solid rgba(0, 0, 0, 0.1);
	}
`;

const StyledHead = styled.thead`
	background-color: #ededed;
	position: sticky;
	top: 0;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
	z-index: 1;
`;

const StyledHeadCell = styled.th`
	background-color: #ededed;
	position: relative;
	padding: ${(props) => (props.hasIcon ? "1.1rem" : "0.5rem")};
	width: ${(props) => props.width ?? "initial"};
	border-left: 1px solid #d4d4d4;
	cursor: ${(props) => (props.isClickable ? "pointer" : "arrow")};
	&:first-of-type {
		border-left: none;
	}
	&:hover {
		background-color: ${(props) => (props.isClickable ? "#dddddd" : "#ededed")};
	}
	position: ${(props) => (props.isPinned ? "sticky" : "initial")};
	right: ${(props) => (props.isPinned ? "0" : "initial")};
`;

const StyledBody = styled.tbody`
	max-height: 100%;
`;

const IconWrapper = styled.div`
	position: absolute;
	top: 7px;
	right: 7px;
`;

const DataCell = styled.td`
	padding: 10px;
	background-color: ${(props) => (props.isSelected ? "#8eb5fc" : "white")};
	border-left: 1px solid #d4d4d4;
	&:first-of-type {
		border-left: none;
	}
	position: ${(props) => (props.isPinned ? "sticky" : "initial")};
	right: ${(props) => (props.isPinned ? "0" : "initial")};
`;

const DataRow = styled.tr`
	&:hover ${DataCell} {
		background-color: ${(props) => (props.isSelected ? "#8eb5fc" : "#d9e6fe")};
	}
	position: relative;
`;

const TextContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	justify-content: center;
	flex-wrap: wrap;
	
`;

export const Table = ({
	data,
	columns,
	isClickable,
	Divider,
	dataIdField,
	height = "640px",
	emptyString,
	dividerHandler,
	rowButtons = [],
	determineIfSelected = () => false,
}) => {
	const { t } = useTranslation();
	const [clickedRow, setClickedRow] = useState({ id: null, position: 0 });
	const table = useRef(null);

	const rowClickHandler = useCallback(
		(entry) => (event) => {
			event.stopPropagation();
			const clickXPosition = event.nativeEvent.layerX;
			const targetWidth = table.current.clientWidth;
			setClickedRow({
				id: entry[dataIdField],
				position:
					clickXPosition - rowButtons?.length * 17.5 > 0
						? clickXPosition + rowButtons?.length * 17.5 <
						  targetWidth
							? clickXPosition - rowButtons?.length * 17.5
							: targetWidth - rowButtons?.length * 35
						: 0,
			});
		},
		[dataIdField, rowButtons?.length]
	);

	const clickOutside = () => {
		setClickedRow((prev) => ({
			...prev,
			id: null,
		}));
	};

	useEffect(() => {
		document.documentElement.addEventListener("click", clickOutside);
		return () => {
			document.documentElement.removeEventListener("click", clickOutside);
		};
	}, []);

	const tableBody = useMemo(
		() =>
			data.map((entry, index) => {
				const dividerData = dividerHandler
					? dividerHandler(entry, data.length - 1 === index)
					: { isDisplayed: false };
				return (
					<>
						{dividerData.isDisplayed ? (
							<Divider {...dividerData.dividerProps} />
						) : null}
						<DataRow
							onClick={
								rowButtons?.length > 1
									? rowClickHandler(entry)
									: rowButtons?.length
									? (event) => {
											event.stopPropagation();
											rowButtons[0].onClick(entry);
									  }
									: null
							}
							isSelected={determineIfSelected(entry)}
							key={entry[dataIdField]}
						>
							{columns.map((column) => (
								<DataCell
									isSelected={determineIfSelected(entry)}
									isPinned={column.isPinned}
									key={column.customName || column.name}
								>
									{(column.formatter
										? column.formatter(
												entry[column.name],
												entry,
												data
										  )
										: entry[column.name]) ?? "-"}
								</DataCell>
							))}
							<ButtonsWrapper
								left={`${clickedRow.position}px`}
								isDisplayed={
									clickedRow.id === entry[dataIdField]
								}
							>
								{rowButtons
									? rowButtons.map((button) => (
											<StyledButton
												key={button.onClick}
												onClick={(event) => {
													event.stopPropagation();
													button.onClick(entry);
												}}
											>
												<Icon component={button.icon} />
											</StyledButton>
									  ))
									: null}
							</ButtonsWrapper>
						</DataRow>
					</>
				);
			}),
		[data, columns, determineIfSelected, rowClickHandler, clickedRow]
	);
	return (
		<TableWrapper height={height}>
			<StyledTable ref={table}>
				<StyledHead>
					<tr>
						{columns.map((column) => (
							<StyledHeadCell
								isPinned={column.isPinned}
								hasIcon={Boolean(column.icon)}
								width={column.customWidth}
								isClickable={
									isClickable && column.headerClickHandler
								}
								onClick={column.headerClickHandler}
								key={column.customName || column.name}
							>
								{column.icon ? (
									<IconWrapper>
										<Icon
											sx={{
												width: "18px",
												height: "18px",
												opacity: isClickable ? 1 : 0.2,
											}}
											component={column.icon}
										/>
									</IconWrapper>
								) : null}
								<TextContainer>
									{t(column.customName || column.name)}
									{column.additionalHeaderContent}
								</TextContainer>
							</StyledHeadCell>
						))}
					</tr>
				</StyledHead>
				<StyledBody>
					{data && data.length ? (
						tableBody
					) : (
						<tr>
							<td colSpan={999}>
								<Box
									display={"flex"}
									mt={"10%"}
									justifyContent="center"
									alignItems={"center"}
								>
									<Typography fontSize={"20px"}>
										{emptyString}
									</Typography>
								</Box>
							</td>
						</tr>
					)}
				</StyledBody>
			</StyledTable>
		</TableWrapper>
	);
};
