import styled from "styled-components";

const Circle = styled.div`
	aspect-ratio: 1/1;
	width: 100%;
	background-color: ${(props) => props.color};
	border-radius: 50%;
	display: inline-block;
`;
export const CircleIndicator = ({ color }) => (
	<Circle
		style={{
			aspectRatio: "1/1",
			width: "100%",
		}}
		color={color}
	/>
);
