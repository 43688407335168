import { createGlobalStyle } from "styled-components";

const FontStyles = createGlobalStyle`

@font-face {
  font-family: "'Inter', sans serif";
  src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
}
`;

export default FontStyles;
