import { useEffect, useRef } from "react";

export const useDragToZoom = () => {
	const parent = useRef(null);
	const zoomArea = useRef(null);
	const isDown = useRef(false);
	const start = useRef(null);
	const finish = useRef(null);
	const isBlocked = useRef(false);
	const movement = useRef(0);
	const scrollLength = useRef(0);
	const onMouseUp = useRef(null);
	const timeOut = useRef(null);
	const shouldBlockClicks = useRef(false);

	const handleMouseDown = (event) => {
		if (!isBlocked.current) {
			event.preventDefault();
			start.current = event.clientX - 48 + scrollLength.current;
			finish.current =
				parent.current.scrollWidth -
				(event.clientX - 48 + scrollLength.current);
			zoomArea.current.style.left = `${start.current}px`;
			zoomArea.current.style.right = `${finish.current}px`;
			isDown.current = true;
			timeOut.current = setTimeout(
				() => (shouldBlockClicks.current = true),
				250
			);
		}
	};

	const handleMouseUp = (event) => {
		movement.current = 0;
		clearTimeout(timeOut.current);
		if (
			shouldBlockClicks.current &&
			isDown.current &&
			!isBlocked.current &&
			zoomArea.current.clientWidth > 10
		) {
			if (event.clientX - 48 + scrollLength.current > start.current) {
				finish.current =
					event.clientX - 48 <
					parent.current.parentElement.clientWidth
						? event.clientX - 48 + scrollLength.current
						: parent.current.parentElement.clientWidth +
						  scrollLength.current;
			} else {
				finish.current = start.current;
				start.current =
					event.clientX - 48 > 0
						? event.clientX - 48 + scrollLength.current
						: scrollLength.current;
			}
			onMouseUp.current && onMouseUp.current(start, finish);
		}
		isDown.current = false;
		zoomArea.current.style.transitionDuration = "150ms";
		zoomArea.current.style.opacity = "0";
		setTimeout(() => {
			shouldBlockClicks.current = false;
			zoomArea.current.style.transitionDuration = "initial";
			zoomArea.current.style.opacity = "1";
			zoomArea.current.style.left = "initial";
			zoomArea.current.style.right = "initial";
		}, 150);
	};

	const handleMouseMove = (event) => {
		if (isDown.current && !isBlocked.current) {
			movement.current += event.movementX;
			if (movement.current > 0) {
				zoomArea.current.style.left = `${start.current}px`;
				zoomArea.current.style.right = `${
					finish.current - movement.current >
					parent.current.scrollWidth -
						parent.current.parentElement.clientWidth -
						scrollLength.current
						? finish.current - movement.current
						: parent.current.scrollWidth -
						  parent.current.parentElement.clientWidth -
						  scrollLength.current
				}px`;
			} else {
				zoomArea.current.style.left = `${
					start.current + movement.current > scrollLength.current
						? start.current + movement.current
						: scrollLength.current
				}px`;
				zoomArea.current.style.right = `${finish.current}px`;
			}
		} else {
			isDown.current = false;
		}
	};

	useEffect(() => {
		parent.current.addEventListener("mousedown", handleMouseDown);
		window.addEventListener("mouseup", handleMouseUp);
		window.addEventListener("mousemove", handleMouseMove);
		const currentParent = parent.current;
		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
			currentParent.removeEventListener("mousedown", handleMouseDown);
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	return {
		parent,
		zoomArea,
		start,
		isBlocked,
		scrollLength,
		finish,
		onMouseUp,
		shouldBlockClicks,
	};
};
