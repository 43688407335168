import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getZipImageRef, pushRef, removeRef } from "../../store/colorSlice";
import ImageDialog from "../UI/ImageDialog";

const ExampleZipImage = ({ imageRef, del, example }) => {
	const { zipImageRef, imageRefsArr } = useSelector((state) => state.color);
	const [blur, setBlur] = useState(true);
	const [clicked, setClicked] = useState(false);
	const [opened, setOpened] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getZipImageRef(imageRef));
	}, [imageRef]);

	const filterById = zipImageRef.filter(
		(item) => item.id === imageRef.split("__")[0]
	);

	const delClickHandler = () => {
		if (del) {
			setClicked(!clicked);
			dispatch(pushRef(imageRef));
			if (imageRefsArr?.includes(imageRef)) {
				dispatch(removeRef(imageRef));
			}
		} else if (example) {
			setOpened(true);
		}
	};

	return (
		<Box>
			<img
				src={{ ...filterById }[0]?.blob}
				style={{
					filter: blur ? "blur(20px)" : "none",
					transition: blur ? "none" : "filter 0.3s ease-out",
					border: del ? (clicked ? "4px solid #FF3F3F" : "none") : "",
				}}
				loading="lazy"
				onLoad={() => setBlur(false)}
				onClick={delClickHandler}
			/>
			<ImageDialog
				opened={opened}
				refId={{ ...filterById }[0]?.id}
				imgRef={imageRef}
				onClose={() => setOpened(false)}
			/>
		</Box>
	);
};

export default ExampleZipImage;
