import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/userSlice";
import ChangePass from "./ChangePass";
import ChangeUser from "./ChangeUser";

const ProfilePage = () => {
	const { users } = useSelector((state) => state.user);

	const dispatch = useDispatch();

	useEffect(() => {
		if (users === null) {
			dispatch(getUser());
		}
	}, [users]);

	return (
		<Box
			display={"flex"}
			height="94vh"
			alignItems="center"
			justifyContent={"center"}
			flexDirection="row"
			gap="3rem"
		>
			{users && <ChangeUser users={users} userId={users.id} />}
			<ChangePass />
		</Box>
	);
};

export default ProfilePage;
