import styled from "styled-components";
import { LabeledInput } from "./LabeledInput";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "./profile/Snackbar";
import { changeSettings } from "../store/settingsSlice";
import { HintPopup } from "./HintPopup";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const SubHeader = styled.p`
	font-weight: bold;
	display: flex;
	gap: 8px;
	align-items: center;
`;

const InputPair = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const Hint = styled.div`
	position: relative;
	color: rgba(0, 0, 0, 0.8);
	font-weight: normal;
	cursor: pointer;
	border-radius: 100%;
	padding: 0 5px;
	border: 2px solid rgba(0, 0, 0, 0.8);
	aspect-ratio: 1;
`;

export const DataListSettings = () => {
	const { alarm } = useSelector((state) => state.settings);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [snackState, setSnackState] = useState({
		show: false,
		success: false,
		msg: "msg",
	});
	const [validationState, setValidationState] = useState({
		heights: false,
		defects: false,
		repeats: false,
	});
	const [popupState, setPopupState] = useState({
		heights: false,
		defects: false,
		repeats: false,
	});

	const [heightAllowed, setHeightAllowed] = useState(
		alarm?.pallet?.height_map?.ok ?? 0
	);
	const [heightRestricted, setHeightRestricted] = useState(
		alarm?.pallet?.height_map?.allowed ?? 0
	);
	const [defectAllowed, setDefectAllowed] = useState(
		alarm?.pallet?.defect_map?.ok ?? 0
	);
	const [defectRestricted, setDefectRestricted] = useState(
		alarm?.pallet?.defect_map?.allowed ?? 0
	);
	const [repeatAllowed, setRepeatAllowed] = useState(
		alarm?.pallet?.repeat_map?.ok ?? 0
	);
	const [repeatRestricted, setRepeatRestricted] = useState(
		alarm?.pallet?.repeat_map?.allowed ?? 0
	);

	const handleInput = (setValue) => (event) => {
		const numberRegex = /^[0-9]*$/;
		if (numberRegex.test(event.target.value)) {
			if (Number(event.target.value) >= 100) {
				setValue(100);
			} else {
				setValue(Number(event.target.value));
			}
		}
	};

	const handleApply = () => {
		const result = {
			pallet: {
				defect_map: {
					ok: defectAllowed,
					allowed: defectRestricted,
				},
				height_map: {
					ok: heightAllowed,
					allowed: heightRestricted,
				},
				repeat_map: {
					ok: repeatAllowed,
					allowed: repeatRestricted,
				},
			},
			serie: {
				defect_map: {
					ok: 0,
					allowed: 5,
				},
			},
		};
		if (validate()) {
			dispatch(changeSettings({ settingsState: result })).then(() =>
				setSnackState({
					show: true,
					success: true,
					msg: t("settings.setting.success"),
				})
			);
		} else {
			setSnackState({
				show: true,
				success: false,
				msg: t("settings.setting.error"),
			});
		}
	};

	const validate = () => {
		const result = {
			heights: !(Number(heightAllowed) < Number(heightRestricted)),
			defects: !(Number(defectAllowed) < Number(defectRestricted)),
			repeats: !(Number(repeatAllowed) < Number(repeatRestricted)),
		};
		setValidationState(result);
		return !Object.keys(result).some((entry) => result[entry]);
	};

	useEffect(() => {
		if (
			validationState.heights &&
			Number(heightAllowed) < Number(heightRestricted)
		) {
			setValidationState((prev) => ({
				...prev,
				heights: false,
			}));
		}
	}, [heightAllowed, heightRestricted, validationState.heights]);

	useEffect(() => {
		if (
			validationState.defects &&
			Number(defectAllowed) < Number(defectRestricted)
		) {
			setValidationState((prev) => ({
				...prev,
				defects: false,
			}));
		}
	}, [defectAllowed, defectRestricted, validationState.defects]);

	useEffect(() => {
		if (
			validationState.repeats &&
			Number(repeatAllowed) < Number(repeatRestricted)
		) {
			setValidationState((prev) => ({
				...prev,
				repeats: false,
			}));
		}
	}, [repeatAllowed, repeatRestricted, validationState.repeats]);

	return (
		<div>
			<Wrapper>
				<Typography
					fontFamily="Roboto"
					fontSize={"24px"}
					fontWeight="bold"
				>
					{t("settings.setting.palletStateIndicators")}
				</Typography>
				<SubHeader>
					{t("settings.setting.heightExplanation")}
					<Hint
						onMouseEnter={() =>
							setPopupState((prev) => ({
								...prev,
								heights: true,
							}))
						}
						onMouseLeave={() =>
							setPopupState((prev) => ({
								...prev,
								heights: false,
							}))
						}
					>
						?
						<HintPopup
							topic={"heights"}
							isVisible={popupState.heights}
						/>
					</Hint>
				</SubHeader>
				<InputPair>
					<LabeledInput
						value={heightAllowed}
						adornment={<p>%</p>}
						error={validationState.heights}
						onChange={handleInput(setHeightAllowed)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.yellow")}: ${t(
							"settings.setting.from"
						)}`}
					/>
					<LabeledInput
						value={heightRestricted}
						adornment={<p>%</p>}
						error={validationState.heights}
						onChange={handleInput(setHeightRestricted)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.red")}: ${t(
							"settings.setting.from"
						)}`}
					/>
				</InputPair>
				<SubHeader>
					{t("settings.setting.defectExplanation")}
					<Hint
						onMouseEnter={() =>
							setPopupState((prev) => ({
								...prev,
								defects: true,
							}))
						}
						onMouseLeave={() =>
							setPopupState((prev) => ({
								...prev,
								defects: false,
							}))
						}
					>
						?
						<HintPopup
							topic={"defects"}
							isVisible={popupState.defects}
						/>
					</Hint>
				</SubHeader>
				<InputPair>
					<LabeledInput
						value={defectAllowed}
						adornment={<p>%</p>}
						error={validationState.defects}
						onChange={handleInput(setDefectAllowed)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.yellow")}: ${t(
							"settings.setting.from"
						)}`}
					/>
					<LabeledInput
						value={defectRestricted}
						adornment={<p>%</p>}
						error={validationState.defects}
						onChange={handleInput(setDefectRestricted)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.red")}: ${t(
							"settings.setting.from"
						)}`}
					/>
				</InputPair>
				<SubHeader>
					{t("settings.setting.repeatExplanation")}
					<Hint
						onMouseEnter={() =>
							setPopupState((prev) => ({
								...prev,
								repeats: true,
							}))
						}
						onMouseLeave={() =>
							setPopupState((prev) => ({
								...prev,
								repeats: false,
							}))
						}
					>
						?
						<HintPopup
							topic={"repeat"}
							isVisible={popupState.repeats}
						/>
					</Hint>
				</SubHeader>
				<InputPair>
					<LabeledInput
						value={repeatAllowed}
						error={validationState.repeats}
						onChange={handleInput(setRepeatAllowed)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.yellow")}: ${t(
							"settings.setting.from"
						)}`}
					/>
					<LabeledInput
						value={repeatRestricted}
						error={validationState.repeats}
						onChange={handleInput(setRepeatRestricted)}
						inputStyles={{ width: "100px" }}
						label={`${t("qpbc.settings.red")}: ${t(
							"settings.setting.from"
						)}`}
					/>
				</InputPair>
				<Button onClick={handleApply} sx={{ maxWidth: "fit-content" }}>
					{t("profile.apply")}
				</Button>
			</Wrapper>
			<Snackbar
				open={snackState.show}
				setOpen={setSnackState}
				error={snackState.success}
				msg={snackState.msg}
			/>
		</div>
	);
};
