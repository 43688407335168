import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	margin-top: 60px;
	margin-right: 5%;
	background-color: gray;
	width: 600px;
`;

const StatFormChart = () => {
	return <Wrapper>StatFormChart</Wrapper>;
};

export default StatFormChart;
