import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColorDefectParams } from "./ColorDefectParams";
import ColorDefectTable from "./ColorDefectTable";
import { useNavigate } from "react-router-dom";
import {
	getDefaultColorSettings,
	getSpecialSettings,
} from "../../store/defectsInspectionSlice";
import styled from "styled-components";
import { SpecialDefectInspectionSettings } from "./SpecialDefectInspectionSettings";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-bottom: 3rem;
	padding-top: 3rem;
`;

const ColorParams = () => {
	const { defaultColorSettings } = useSelector(
		(state) => state.defectInspection
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getDefaultColorSettings());
		dispatch(getSpecialSettings());
	}, []);

	return (
		<Content>
			<Button
				sx={{ width: "fit-content", marginLeft: "auto" }}
				onClick={() => navigate("test")}
			>
				{t("defectInspection.checkInspection")}
			</Button>
			<Box display={"flex"} flexDirection="row" gap="2rem">
				<Box display={"flex"} flexDirection="column" gap="2rem">
					<div>
						<ColorDefectTable list={defaultColorSettings} />
					</div>
				</Box>
				<ColorDefectParams />
			</Box>
			<SpecialDefectInspectionSettings />
		</Content>
	);
};

export default ColorParams;
