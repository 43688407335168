import { Checkbox, IconButton } from "@mui/material";
import MergeIcon from "../icons/MergeIcon";
import SplitIcon from "../icons/SplitIcon";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const BatchEditingButtons = ({
	editBatch,
	isMergeInProgress,
	setIsMergeInProgress,
	selectedToMerge,
	setSelectedToMerge,
	data,
	...batch
}) => {
	const handleCheckBoxChange = (event) => {
		if (event.target.checked) {
			setSelectedToMerge((prev) => {
				const temp = structuredClone(prev);
				temp.push(batch.batch_id);
				return temp;
			});
		} else {
			setSelectedToMerge((prev) => {
				const temp = structuredClone(prev);
				return temp.filter((uuid) => uuid !== batch.batch_id);
			});
		}
	};

	const determineIfDisabled = () => {
		const uuidsList = Array.from(
			new Set(data.map((batch) => batch.batch_id))
		);
		const indexList = selectedToMerge
			.map((uuid) => uuidsList.findIndex((batch_id) => batch_id === uuid))
			.sort((a, b) => a - b);
		const isInBeginning =
			uuidsList.at(indexList.at(0)) === batch.batch_id ||
			uuidsList.at(indexList.at(0) - 1 < 0 ? 0 : indexList.at(0) - 1) ===
				batch.batch_id;
		const isInEnd =
			uuidsList.at(indexList.at(-1)) === batch.batch_id ||
			uuidsList.at(indexList.at(-1) + 1) === batch.batch_id;
		return !(
			(isInBeginning || isInEnd || selectedToMerge.length === 0) &&
			(data.find((batch) => batch.batch_id === selectedToMerge?.at(0))
				?.shift?.uuid === batch?.shift?.uuid ||
				selectedToMerge.length === 0)
		);
	};

	return (
		<Wrapper onClick={(event) => event.stopPropagation()}>
			{isMergeInProgress ? (
				<Checkbox
					disabled={determineIfDisabled()}
					checked={Boolean(
						selectedToMerge.find((uuid) => uuid === batch.batch_id)
					)}
					onChange={handleCheckBoxChange}
				/>
			) : (
				<>
					<IconButton
						onClick={(event) => {
							event.stopPropagation();
							editBatch(batch, "props");
						}}
					>
						<EditIcon />
					</IconButton>
					<IconButton
						onClick={(event) => {
							event.stopPropagation();
							editBatch(batch, "split");
						}}
					>
						<SplitIcon />
					</IconButton>
					<IconButton
						onClick={(event) => {
							event.stopPropagation();
							setIsMergeInProgress(true);
							setSelectedToMerge((prev) => {
								const temp = structuredClone(prev);
								temp.push(batch.batch_id);
								return temp;
							});
						}}
					>
						<MergeIcon />
					</IconButton>
				</>
			)}
		</Wrapper>
	);
};
