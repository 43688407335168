import DataItem from "./DataItem";
import { CircleIndicator } from "./CircleIndicator";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Chart } from "react-chartjs-2";
import { scales } from "chart.js";

const InfoPopup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	background: white;
	box-shadow: 4px 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	padding: 8px;
	position: absolute;
	z-index: 10;
	bottom: 100%;
	left: 50%;
`;

const CircleWrapper = styled.div`
	width: 25px;
	height: 25px;
`;

const DataSection = styled.div`
	display: flex;
	gap: 8px;
`;

const CircleSection = styled.div`
	display: flex;
	justify-content: space-evenly;
`;

const CandleSection = styled.div`
	display: flex;
	justify-content: space-evenly;
	padding-right: 15px;
`;

const ChartWrapper = styled.div`
	width: 40px;
	height: 100px;
`;

export const HintPopup = ({ topic, isVisible }) => {
	const { t } = useTranslation();

	const dataOk = {
		labels: [""],
		datasets: [
			{
				type: "boxplot",
				borderWidth: 2.5,
				data: [[0, 5, 10]],
				backgroundColor: "rgb(12, 156, 35)",
				borderColor: "rgb(12, 156, 35)",
			},
		],
	};
	const dataAllowed = {
		labels: [""],
		datasets: [
			{
				type: "boxplot",
				borderWidth: 2.5,
				data: [[0, 5, 10]],
				backgroundColor: "#D9DD12",
				borderColor: "#D9DD12",
			},
		],
	};
	const dataRestricted = {
		labels: [""],
		datasets: [
			{
				type: "boxplot",
				borderWidth: 2.5,
				data: [[0, 5, 10]],
				backgroundColor: "#FF3F3F",
				borderColor: "#FF3F3F",
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
				position: "top",
			},
			title: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
		},
		animation: false,
		scales: {
			y: {
				min: -1,
				max: 11,
				ticks: {
					callback: () => {
						return "";
					},
				},
			},
			x: {
				ticks: {
					callback: () => {
						return "";
					},
				},
			},
		},
	};

	return isVisible ? (
		<InfoPopup>
			<DataSection>
				<DataItem
					title={t(`panel.titles.${topic}`)}
					data={0}
					loaded={true}
					status={"ok"}
				/>
				<DataItem
					title={t(`panel.titles.${topic}`)}
					data={0}
					loaded={true}
					status={"allowed"}
				/>
				<DataItem
					title={t(`panel.titles.${topic}`)}
					data={0}
					loaded={true}
					status={"restricted"}
				/>
			</DataSection>
			{topic === "defects" && (
				<CircleSection>
					<CircleWrapper>
						<CircleIndicator color={"rgb(12, 156, 35)"} />
					</CircleWrapper>
					<CircleWrapper>
						<CircleIndicator color={"#D9DD12"} />
					</CircleWrapper>
					<CircleWrapper>
						<CircleIndicator color={"#FF3F3F"} />
					</CircleWrapper>
				</CircleSection>
			)}
			{topic === "heights" && (
				<CandleSection>
					<ChartWrapper>
						<Chart options={options} data={dataOk} type="bar" />
					</ChartWrapper>
					<ChartWrapper>
						<Chart
							options={options}
							data={dataAllowed}
							type="bar"
						/>
					</ChartWrapper>
					<ChartWrapper>
						<Chart
							options={options}
							data={dataRestricted}
							type="bar"
						/>
					</ChartWrapper>
				</CandleSection>
			)}
		</InfoPopup>
	) : null;
};
