import styled from "styled-components";
import { GalleryItem } from "./GalleryItem";

const Content = styled.div`
	width: 100%;
`;

const ImageList = styled.div`
	display: flex;
	gap: 1rem;
`;

export const ImageGallery = ({
	images,
	imageClickHandler,
	selectedImageUuid,
}) => {
	return (
		<Content>
			<ImageList>
				{images.map((image) => (
					<GalleryItem
						isSelected={image.uuid === selectedImageUuid}
						key={image.label}
						image={image}
						imageClickHandler={imageClickHandler}
					/>
				))}
			</ImageList>
		</Content>
	);
};
