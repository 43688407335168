import styled from "@emotion/styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import DoneIcon from "@mui/icons-material/Done";

const SelectWrapper = styled.div`
	padding: 1rem 1.5rem;
	cursor: pointer;
	font-weight: 600;
	position: relative;
	background: white;
	border-radius: ${(props) => (props.isOpen ? "8px 8px 0 0" : "8px")};
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	max-height: fit-content;
	min-width: max-content;
`;

const StyledLabel = styled.p`
	max-height: fit-content;
	text-align: center;
`;

const DropDown = styled.div`
	position: absolute;
	background-color: white;
	left: 0;
	top: 100%;
	border-radius:0 0 8px 8px;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
	height: 300px;
	overflow-y: scroll;
`;

const StyledList = styled.ul`
	list-style-type: none;
`;

const StyledEntry = styled.li`
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 400;
	cursor: pointer;
	background-color: ${(props) =>
		props.isSelected ? "white" : "rgba(0, 0, 0, 0.03)"} ;
`;

const IconWrapper = styled.div`
	width: 24px;
	height: 24px;
`;

export const ColumnDisplaySelector = ({
	columns,
	selectedColumns,
	setSelectedColumns,
}) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const elementRef = useOnClickOutside(() => setIsOpen(false));

	const handleClick = (name) => {
		if (selectedColumns.includes(name)) {
			setSelectedColumns((prev) => prev.filter((elem) => elem !== name));
		} else {
			setSelectedColumns((prev) => [...prev, name]);
		}
	};

	return (
		<SelectWrapper
			ref={elementRef}
			isOpen={isOpen}
			onClick={() => setIsOpen((prev) => !prev)}
		>
			<StyledLabel>{t(`report.columnsToShow`)}</StyledLabel>
			{isOpen ? (
				<DropDown onClick={(e) => e.stopPropagation()}>
					<StyledList>
						{columns
							.filter((column) => column.name)
							.map((column) => (
								<StyledEntry
									isSelected={selectedColumns.includes(
										column.customName ?? column.name
									)}
									key={column.customName ?? column.name}
									onClick={() =>
										handleClick(
											column.customName ?? column.name
										)
									}
								>
									<p>{t(column.customName ?? column.name)}</p>
									<IconWrapper>
										{selectedColumns.includes(
											column.customName ?? column.name
										) && (
											<DoneIcon sx={{ color: "black" }} />
										)}
									</IconWrapper>
								</StyledEntry>
							))}
					</StyledList>
				</DropDown>
			) : null}
		</SelectWrapper>
	);
};
