import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../icons/arrow_back.svg";
import { getColorImagesRefList, getColorList } from "../../store/colorSlice";
import ModalWindow from "../UI/ModalWindow";
import AddExample from "./AddExample";
import DeleteExample from "./DeleteExample";
import ExampleColorList from "./ExampleColorList";

const ColorItem = () => {
	const [add, setAdd] = useState("example");
	const [deleteColor, setDeleteColor] = useState(false);
	const [editColor, setEditColor] = useState(false);

	const { colorList } = useSelector((state) => state.color);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(getColorList());
	}, [id, add]);

	useEffect(() => {
		if (id) {
			dispatch(getColorImagesRefList(id));
		}
	}, [id, add]);

	const filterColor = useMemo(
		() => ({ ...colorList?.filter((item) => item.uuid === id) }[0]),
		[colorList, id]
	);

	const deleteColorHandler = () => {
		setDeleteColor(false);
		dispatch(getColorList());
	};

	return (
		<Box pt="38px" pl="60px" pr="50px">
			<Box
				display={"flex"}
				flexDirection="row"
				justifyContent={"space-between"}
				alignItems="center"
			>
				<Box display={"flex"} flexDirection="row">
					<ArrowBack
						onClick={() => navigate("/settings/color")}
						style={{ cursor: "pointer" }}
					/>
					<Typography
						sx={{
							fontSize: "26px",
							fontWeight: "700",
							display: "flex",
							alignItems: "center",
							paddingLeft: "25px",
						}}
					>
						{filterColor?.name}
					</Typography>
				</Box>
				<Box>
					<Button
						sx={{
							border: "2px solid black",
							fontSize: "18px",
							color: "black",
							borderRadius: "10px",
							padding: "5px 33px",
						}}
						onClick={() => setEditColor(true)}
					>
						{t("color.btn_edit")}
					</Button>
					<Button
						sx={{
							border: "2px solid red",
							fontSize: "18px",
							color: "black",
							borderRadius: "10px",
							padding: "5px 33px",
							marginLeft: "45px",
						}}
						onClick={() => setDeleteColor(true)}
					>
						{t("color.btn_delete")}
					</Button>
				</Box>
			</Box>
			<Box>
				<Typography sx={{ paddingLeft: "73px" }}>
					{t(`color.default_${filterColor?.color_type}`)}/
					{t(`color.${filterColor?.brightness}`)}/
					{t(`color.${filterColor?.surface_type}`)}
				</Typography>
			</Box>
			<Divider
				sx={{ pt: "46px", color: "black", marginLeft: "0px" }}
				variant="middle"
			/>
			{add === "add" ? (
				<AddExample setAdd={setAdd} color_id={id} />
			) : add === "delete" ? (
				<DeleteExample setAdd={setAdd} color_id={id} />
			) : (
				<ExampleColorList color_id={id} setAdd={setAdd} />
			)}
			{deleteColor && (
				<ModalWindow
					opened={deleteColor}
					color_id={id}
					onClose={deleteColorHandler}
					remove
				>
					{t("color.delete_desc")} - {filterColor?.name}?
				</ModalWindow>
			)}
			{editColor && (
				<ModalWindow
					opened={editColor}
					color_id={id}
					onClose={() => setEditColor(false)}
					edit
				/>
			)}
		</Box>
	);
};

export default ColorItem;
