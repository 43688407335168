import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addImageForColor, reset } from "../../store/colorSlice";
import AddBatch from "./AddBatch";

const AddExample = ({ setAdd, color_id }) => {
	const { imageUUidArr } = useSelector((state) => state.color);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const saveClickHandler = () => {
		imageUUidArr.map((item) => {
			dispatch(addImageForColor({ color_id: color_id, pallet_id: item }));
		});
		dispatch(reset());
		setAdd("example");
	};

	return (
		<Box pt="24px">
			<Box>
				<Box
					display={"flex"}
					flexDirection="row"
					justifyContent={"space-between"}
					alignItems="center"
				>
					<Typography fontSize={"18px"}>
						{t("color.add_desc")}
					</Typography>
					<Box>
						<Button
							sx={{
								border: "3px solid #7CC6B6",
								fontSize: "18px",
								color: "#7CC6B6",
								borderRadius: "10px",
								padding: "5px 33px",
								marginRight: "25px",
								"&:disabled": {
									border: "3px solid #71948c4c",
									color: "white",
									backgroundColor: "#71948c4c",
								},
							}}
							onClick={saveClickHandler}
							disabled={imageUUidArr.length === 0}
						>
							{t("color.btn_save")}
						</Button>
						<Button
							sx={{
								border: "2px solid black",
								fontSize: "18px",
								color: "black",
								borderRadius: "10px",
								padding: "5px 33px",
								marginRight: "25px",
								textTransform: "none",
							}}
							onClick={() => setAdd("example")}
						>
							{t("color.btn_cancel")}
						</Button>
					</Box>
				</Box>
			</Box>
			<Box>
				<AddBatch />
			</Box>
		</Box>
	);
};

export default AddExample;
