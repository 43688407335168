import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Table } from "../Table";
import styled from "styled-components";
import { useDataTableFormatter } from "../../hooks/useDataTableFormatter";
import { DefectInspectionControls } from "../DefectInspectionControls";
import { useState } from "react";
import { DefectInspectionRuleDeleteDialog } from "../DefectInspectionRuleDeleteDialog";
import { useTranslation } from "react-i18next";
import { DefectInspectionRuleRuleForm } from "../DefectInspectionRuleForm";

const Content = styled.div`
	display: flex;
	background-color: white;
	flex-direction: column;
	border-radius: 0.5rem;
	overflow: hidden;
`;

const Wrapper = styled.div`
	padding: 1rem;
`;

export const SpecialDefectInspectionSettings = () => {
	const [isFormOpen, setIsFormOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [selectedRule, setSelectedRule] = useState(null);
	const { specialSettings } = useSelector((state) => state.defectInspection);
	const { mmFormatter, customContentFormatter } = useDataTableFormatter();
	const { t } = useTranslation();

	const createRule = () => {
		setIsFormOpen(true);
	};

	const editRule = (rule) => {
		setIsFormOpen(true);
		setSelectedRule(rule);
	};

	const deleteRule = (rule) => {
		setIsDeleting(true);
		setSelectedRule(rule);
	};

	const clearSelectedRule = () => {
		setSelectedRule(null);
	};

	const columns = [
		{
			name: "color_name",
		},
		{
			name: "product_name",
		},
		{
			name: "pit_threshold",
		},
		{
			name: "crack_threshold",
		},
		{
			name: "stain_threshold",
		},
		{
			name: "destruction_threshold",
		},
		{
			name: "min_defect_size",
			formatter: mmFormatter,
		},
		{
			name: "min_pit_depth_rough_mm",
			formatter: mmFormatter,
		},
		{
			name: "allowable_defect_number",
		},
		{
			name: "",
			isPinned: true,
			formatter: customContentFormatter(DefectInspectionControls, {
				deleteRule,
				editRule,
			}),
		},
	];

	return (
		<div>
			<Content>
				<Wrapper>
					<Button onClick={createRule}>
						{t("defectInspection.ruleCreate")}
					</Button>
				</Wrapper>
				<Table
					columns={columns}
					data={specialSettings}
					dataIdField="uuid"
				/>
			</Content>

			{isDeleting && (
				<DefectInspectionRuleDeleteDialog
					open={isDeleting}
					onClose={() => {
						setIsDeleting(false);
						clearSelectedRule();
					}}
					rule={selectedRule}
				/>
			)}
			{isFormOpen && (
				<DefectInspectionRuleRuleForm
					open={isFormOpen}
					onClose={() => {
						setIsFormOpen(false);
						clearSelectedRule();
					}}
					rule={selectedRule}
				/>
			)}
		</div>
	);
};
