import styled from "styled-components";
import CircleLoader from "../UI/CircleLoader";
import { useTranslation } from "react-i18next";
import { numberFormatter } from "../../helper/numberFormatter";

const Content = styled.div`
	padding: 2px 4px 0;
	display: flex;
	gap: 8px;
	flex-direction: column;
	letter-spacing: 0.04em;
	color: #2F3449;
	width: 100%;
`;

const ValueWrapper = styled.div`
	display: flex;
	gap: 6px;
	flex-direction: column;
`;

const Label = styled.p`
	font-size: 12px;
	letter-spacing: 0.04em;
	line-height: 16px;
`;

const Value = styled.p`
	font-size: 20px;
	letter-spacing: 0.04em;
	line-height: 20px;
	font-weight: 500;
`;

const LoaderContainer = styled.div`
	transform: scale(calc(20/32));
	margin-top: -6px;
	width: 32px;
`;

const LoaderWrapper = styled.div`
	height: 20px;
`;

export const WeightInfo = ({ weight, pallet_weight }) => {
	const { t } = useTranslation();

	return (
		<Content>
			<ValueWrapper>
				<Label>{t("panel.titles.productWeight")}</Label>
				{weight === undefined ? (
					<LoaderWrapper>
						<LoaderContainer>
							<CircleLoader />
						</LoaderContainer>
					</LoaderWrapper>
				) : (
					<Value>
						{weight === null ? "-" : numberFormatter(weight)}
					</Value>
				)}
			</ValueWrapper>
			<ValueWrapper>
				<Label>{t("panel.titles.palletWeight")}</Label>
				{pallet_weight === undefined ? (
					<LoaderWrapper>
						<LoaderContainer>
							<CircleLoader />
						</LoaderContainer>
					</LoaderWrapper>
				) : (
					<Value>
						{pallet_weight === null
							? "-"
							: numberFormatter(pallet_weight)}
					</Value>
				)}
			</ValueWrapper>
		</Content>
	);
};
