import styled from "@emotion/styled";
import { ColumnDisplaySelector } from "./ColumnDisplaySelector";
import { LocalizedDatePicker } from "./DatePicker";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "./UI/CircleLoader";
import { getAllPalletsFromBatch, getXLSXReport } from "../store/newReportSlice";
import { TimeLine } from "./TimeLine";
import { BatchPopup } from "./BatchPopup";
import { FilterList } from "./FilterList";
import { TimeLineLocationBar } from "./TimeLineLocationBar";
import { useState } from "react";
import { valueExtractor } from "../helper/valueExtractor";

const Content = styled.div`
	background-color: white;
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 1rem;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	padding: 0.5rem 1rem;
`;

const FiltersWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const DateFiltersWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

const ControlWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	z-index: 3;
`;

const TimeLineWrapper = styled.div`
	width: 100%;
	z-index: 2;
`;

const PopupWrapper = styled.div`
	position: absolute;
	left: 0;
	z-index: 10;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.6);
`;

const Popup = styled.div`
	background-color: white;
	border-radius: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
	display: flex;
	gap: 1rem;
	padding: 0.5rem 1rem;
	flex-direction: column;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
`;

export const ReportControls = ({
	from,
	to,
	setFrom,
	setTo,
	filters,
	setFilters,
	getBatches,
	columns,
	selectedColumns,
	setSelectedColumns,
	isMergeInProgress,
	stopMerge,
	editBatch,
}) => {
	const { batchesStatus, reportStatus, batches, selectedBatch } = useSelector(
		(state) => state.newReport
	);
	const [locationTimestamp, setLocationTimestamp] = useState({
		begin: null,
		end: null,
	});
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleBatchTimelineClick = (batch) => {
		dispatch(getAllPalletsFromBatch({ batch }));
	};

	const handleZoomIn = (begin, end) => {
		setLocationTimestamp({
			begin,
			end,
		});
	};

	const handleZoomOut = () => {
		setLocationTimestamp({
			begin: null,
			end: null,
		});
	};

	return (
		<Content>
			<FiltersWrapper>
				<DateFiltersWrapper>
					<DateFiltersWrapper>
						<p>{t("from")}:</p>
						<LocalizedDatePicker
							variant="standard"
							value={from}
							setValue={setFrom}
						/>
					</DateFiltersWrapper>
					<DateFiltersWrapper>
						<p>{t("to")}:</p>
						<LocalizedDatePicker
							variant="standard"
							value={to}
							setValue={setTo}
						/>
					</DateFiltersWrapper>
					<Button
						disabled={batchesStatus === "pending"}
						sx={{ display: "flex", gap: "0.5rem" }}
						onClick={getBatches}
					>
						{t("report.search")}
						{batchesStatus === "pending" && <CircleLoader />}
					</Button>
					<Button
						disabled={reportStatus === "pending"}
						sx={{ display: "flex", gap: "0.5rem" }}
						onClick={() =>
							dispatch(
								getXLSXReport({
									from: from.format().slice(0, 10),
									to: to.format().slice(0, 10),
									filters: valueExtractor(filters),
									columnsToHide: columns
										.filter(
											(column) =>
												!selectedColumns.includes(
													column.customName ??
														column.name
												)
										)
										.map(
											(column) =>
												column.customName ?? column.name
										),
								})
							)
						}
					>
						{t("downloadReport")}
						{reportStatus === "pending" && <CircleLoader />}
					</Button>
				</DateFiltersWrapper>
				<ControlWrapper>
					<ColumnDisplaySelector
						columns={columns}
						selectedColumns={selectedColumns}
						setSelectedColumns={setSelectedColumns}
					/>
				</ControlWrapper>
			</FiltersWrapper>
			<FilterList filters={filters} setFilters={setFilters} />
			{batches?.length ? (
				<>
					<TimeLineWrapper>
						<TimeLine
							popupLocation="bottom"
							selectedUuid={selectedBatch?.uuid}
							Popup={BatchPopup}
							handleClick={handleBatchTimelineClick}
							isLoaded={batchesStatus === "fulfilled"}
							data={batches}
							onZoomIn={handleZoomIn}
							onZoomOut={handleZoomOut}
						/>
					</TimeLineWrapper>
					<TimeLineLocationBar
						{...locationTimestamp}
						data={batches}
					/>
				</>
			) : null}
			{isMergeInProgress && (
				<PopupWrapper>
					<Popup>
						<p>{t("newReport.mergeInProgress")}</p>
						<ButtonsWrapper>
							<Button onClick={stopMerge} sx={{ color: "red" }}>
								{t("newReport.cancel")}
							</Button>
							<Button onClick={() => editBatch(null, "merge")}>
								{t("newReport.continue")}
							</Button>
						</ButtonsWrapper>
					</Popup>
				</PopupWrapper>
			)}
		</Content>
	);
};
