import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getZippedImageByPallet } from "./layout/LayoutFetch";

const DefaultExampleZipImage = ({ imageRef, onSelect = null, onCancel }) => {
	const [blur, setBlur] = useState(true);
	const [clicked, setClicked] = useState(false);
	const [content, setContent] = useState(null);

	useEffect(() => {
		const fetchZippedImage = async () => {
			const blob = await getZippedImageByPallet(imageRef);
			setContent(blob);
		};
		fetchZippedImage();
	}, []);

	const exampleImageClickHandler = () => {
		if (clicked) {
			if (onCancel !== null) {
				onCancel();
			}
		} else {
			if (onSelect !== null) {
				onSelect();
			}
		}

		setClicked(!clicked);
	};

	return (
		<Box>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					alt={imageRef.pallet_id}
					src={content !== null ? content : null}
					style={{
						width: "280px",
						height: "220px",
						filter: blur ? "blur(20px)" : "none",
						transition: blur ? "none" : "filter 0.3s ease-out",
						border: clicked ? "4px solid #FF3F3F" : "none",
					}}
					loading="lazy"
					onLoad={() => setBlur(false)}
					onClick={exampleImageClickHandler}
				/>
				<div style={{ fontSize: "16px" }}>
					{imageRef.ref !== null
						? `${imageRef.pallet_id} - ${imageRef.formName}`
						: ""}
				</div>
			</div>
		</Box>
	);
};

export default DefaultExampleZipImage;
