import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getAvailableChat, getTokenChat } from "../store/supportSlice";
import { ReactComponent as SupportLogo } from "../icons/supportLogo.svg";

const Wrap = styled.div``;

const SuppBtn = styled.button`
	width: 90px;
	height: 90px;
	border-radius: 45px;
	border: 0px solid gray;
	background-color: #0430d3;
	position: fixed;
	bottom: 0;
	right: 0;
	color: white;
	margin: 24px;
	z-index: 5;
	cursor: pointer;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Badge = styled.span`
	position: fixed;
	background-color: red;
	color: white;
	width: 16px;
	height: 16px;
	text-align: center;
	border-radius: 16px;
	top: 96.2%;
	right: 1%;
`;

const Support = () => {
	const { token, available } = useSelector((state) => state.support);

	const [open, setOpen] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!token) {
			dispatch(getTokenChat());
		}
	}, [token]);

	useEffect(() => {
		if (!available) {
			dispatch(getAvailableChat());
		}
	}, [available]);

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getAvailableChat());
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	if (!token) return <></>;
	if (!available) return <></>;

	return (
		<Wrap>
			<a
				href={`http://51.250.72.5:8090/direct/quatromatic.podderzhka?resumeToken=${token.authToken}`}
				target="_blank"
			>
				<SuppBtn onClick={() => setOpen(!open)}>
					<SupportLogo /> {available.available && <Badge />}
				</SuppBtn>
			</a>
		</Wrap>
	);
};

export default Support;
