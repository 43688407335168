import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getUser = createAsyncThunk(
	"user/getUser",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/users/me`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getNoTokenUsers = createAsyncThunk(
	"user/getNoTokenUsers",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/users`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getCurrentUserSession = createAsyncThunk(
	"user/getCurrentUserSession",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/user_session/current`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const closeUserSession = createAsyncThunk(
	"user/closeUserSession",
	async function ({ username }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/operator_session/close`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ username: username }),
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const openUserSession = createAsyncThunk(
	"user/openUserSession",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/operator_session/open`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const createNewUser = createAsyncThunk(
	"user/updateUserData",
	async function (
		{ login, name, role, password },
		{ rejectWithValue, extra, dispatch }
	) {
		const { api } = extra;
		try {
			const result = await api(`/api/users`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ name, role, login, password }),
			});
			dispatch(getNoTokenUsers());
			return result;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updateUserData = createAsyncThunk(
	"user/updateUserData",
	async function (
		{ user_id, name, role },
		{ rejectWithValue, extra, dispatch }
	) {
		const { api } = extra;
		try {
			const result = await api(`/api/users/${user_id}`, {
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ name: name, role: role }),
			});
			dispatch(getNoTokenUsers());
			dispatch(getUser());
			return result;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const changeUserPassword = createAsyncThunk(
	"user/changeUserPassword",
	async function (
		{ current_password, new_password, confirm_new_password },
		{ rejectWithValue, extra }
	) {
		const { api } = extra;
		try {
			return await api(`/api/users/change_password`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					current_password,
					new_password,
					confirm_new_password,
				}),
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"user/resetPassword",
	async function ({ user_id }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/users/${user_id}/reset_password`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const userSlice = createSlice({
	name: "user",
	initialState: {
		status: null,
		users: null,
		currentUserSession: null,
		error: null,
		noTokenUsers: [],
		updateUser: null,
		passData: null,
		passError: null,
		tempPassword: "",
		tempPasswordStatus: "fulfilled",
	},
	reducers: {
		emptyTempPassword: (state) => {
			state.tempPassword = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUser.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.status = "resolved";
				state.users = action.payload;
			})
			.addCase(getUser.rejected, setError)

			.addCase(getNoTokenUsers.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getNoTokenUsers.fulfilled, (state, action) => {
				state.status = "resolved";
				state.noTokenUsers = action.payload;
				if (state.users) {
					state.users = action.payload.find(
						(user) => user.id === state.users.id
					);
				}
			})
			.addCase(getNoTokenUsers.rejected, setError)

			.addCase(getCurrentUserSession.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getCurrentUserSession.fulfilled, (state, action) => {
				state.status = "resolved";
				state.currentUserSession = action.payload;
			})
			.addCase(getCurrentUserSession.rejected, setError)

			.addCase(updateUserData.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateUserData.fulfilled, (state, action) => {
				state.status = "resolved";
				state.updateUser = action.payload;
			})
			.addCase(updateUserData.rejected, setError)
			.addCase(changeUserPassword.pending, (state) => {
				state.status = "loading";
				state.passError = null;
			})
			.addCase(changeUserPassword.fulfilled, (state, action) => {
				state.status = "resolved";
				state.passData = action.payload;
				state.passError = null;
			})
			.addCase(changeUserPassword.rejected, (state, action) => {
				state.status = "rejected";
				state.passError = action.payload;
			})
			.addCase(resetPassword.pending, (state) => {
				state.tempPasswordStatus = "pending";
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.tempPasswordStatus = "fulfilled";
				state.tempPassword = action.payload.new_password;
			})
			.addCase(resetPassword.rejected, (state) => {
				state.tempPasswordStatus = "rejected";
			});
	},
});

export const { emptyTempPassword } = userSlice.actions;

export default userSlice.reducer;
