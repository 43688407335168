import React, { useEffect, useState } from "react";
import CircleLoader from "./CircleLoader";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getZippedPalletImage } from "../../store/palletSlice";

const Image = styled.div`
	flex: 1;
`;

const ImageTitle = styled.div`
	font-size: 16px;
`;

const ImageItem = styled.div`
	flex: 0.1666;
	aspect-ratio: 14/11;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ImageLoader = ({
	uuid,
	dt,
	palletUUid,
	openHandler,
	selectedIndex,
	indexKey,
}) => {
	const { zippedImagesStatus, zippedImage, zippedImageId } = useSelector(
		(state) => state.pallet
	);
	const [blur, setBlur] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getZippedPalletImage(uuid));
	}, [uuid]);

	useEffect(() => {
		if (selectedIndex === indexKey) {
			openHandler();
		}
	}, [selectedIndex, indexKey]);

	if (zippedImagesStatus === "loading")
		return (
			<Image>
				<ImageItem>
					<CircleLoader />
				</ImageItem>
			</Image>
		);

	const filterById = zippedImage.filter((item) => item.id === uuid);

	return (
		<ImageItem key={uuid}>
			<ImageTitle>{uuid.split("_")[1].split(".").join(":")}</ImageTitle>
			<Image onClick={openHandler}>
				<img
					key={uuid}
					src={{ ...filterById }[0]?.blob}
					style={{
						width: "100%",
						heigth: "auto",
						border:
							selectedIndex === indexKey
								? "4px solid rgba(30, 109, 255, 0.8)"
								: "none",
						filter: blur ? "blur(20px)" : "none",
						transition: blur ? "none" : "filter 0.3s ease-out",
					}}
					loading="lazy"
					onLoad={() => setBlur(false)}
				/>
			</Image>
		</ImageItem>
	);
};

export default React.memo(ImageLoader);
