import styled from "@emotion/styled";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";
import { useTranslation } from "react-i18next";
import { useDragToZoom } from "../hooks/useDragToZoom";

const TimeLineElement = styled.div`
	background-color: ${(props) => props.displayColor};
	margin-top: ${(props) => (props.isSelected ? "-5px" : "0")};
	height: ${(props) => (props.isSelected ? "calc(100% + 10px)" : "100%")};
	opacity: ${(props) =>
		props.isSelected ? "1" : props.isHidden ? "0.33" : "0.6"};
	width: ${(props) => `${props.width}%`};
	margin-left: ${(props) => `${props.margin}%`};
	cursor: pointer;
	&:hover {
		opacity: ${(props) => (props.isSelected ? "1" : "0.85")};
	};
	&:active {
		opacity: 1;
	}
`;

const PopupWrapper = styled.div`
	position: absolute;
	background: white;
	padding: 4px 8px;
	bottom: ${(props) => (props.location === "top" ? "70%" : "initial")};
	top: ${(props) => (props.location === "bottom" ? "60%" : "initial")};;
	width: 450px;
	display: ${(props) => (props.isVisible ? "flex" : "none")};
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
`;

const TimeLineBody = styled.div`
	display: flex;
	height: 25px;
	padding-top: 5px;
	width: 100%;
`;

const ZoomWrapper = styled.div`
	min-width: 100%;
	flex-direction: column;
	display: flex;
	position: relative;
`;

const TimeLineWrapper = styled.div`
	display: flex;
	overflow-x: hidden;
	overflow-y: visible;
	padding-bottom: 28px;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
`;

const TimeSegment = styled.div`
	position: relative;
	height: 18px;
	width: 3px;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	align-items: ${(props) => props.position};
`;

const TimeStamp = styled.div`
	position: absolute;
	white-space: nowrap;
	top: calc(100% + 4px);
`;

const TimeScales = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const ZoomSelector = styled.div`
	position: absolute;
	height: 50px;
	top: 0;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.1);
`;

const ButtonsWrapper = styled.div`
	display: flex;
	gap: 2rem;
	height: 36.5px;
	align-items: center;
`;

export const TimeLine = ({
	data,
	handleClick,
	isLoaded,
	selectedUuid,
	onZoomIn,
	onZoomOut,
	Popup,
	popupLocation,
}) => {
	const [isZoomed, setIsZoomed] = useState(false);
	const [localizedData, setLocalizedData] = useState(structuredClone(data));
	const content = useRef(null);
	const zoomBody = useRef(null);
	const [popupInfo, setPopupInfo] = useState(null);
	const popup = useRef(null);
	const { i18n } = useTranslation();
	const [isVisible, setIsVisible] = useState(false);
	const [params, setParams] = useState({
		start: null,
		end: null,
		duration: null,
	});
	const { parent, zoomArea, start, finish, shouldBlockClicks, onMouseUp } =
		useDragToZoom();

	onMouseUp.current = () => {
		const startTime =
			(start.current / parent.current.clientWidth) * params.duration +
			params.start;
		const endTime =
			(finish.current / parent.current.clientWidth) * params.duration +
			params.start;
		const zoomedData = getZoomedData(startTime, endTime);
		if (zoomedData.length) {
			setLocalizedData(zoomedData);
			setIsZoomed(true);
			onZoomIn && onZoomIn(startTime, endTime);
		}
	};

	const resetZoom = () => {
		setIsZoomed(false);
		setLocalizedData(data);
		onZoomOut && onZoomOut();
	};

	const getZoomedData = (startTime, endTime) => {
		const selectedData = structuredClone(localizedData).filter((entry) => {
			return entry.begin <= endTime && entry.end >= startTime;
		});
		if (selectedData.length) {
			selectedData.at(0).marginMS = 0;
			if (startTime > selectedData.at(0).begin)
				selectedData.at(0).begin = startTime;
			if (endTime < selectedData.at(-1).end)
				selectedData.at(-1).end = endTime;
		}
		return selectedData;
	};

	useEffect(() => {
		resetZoom();
		setIsVisible(false);
	}, [data]);

	useEffect(() => {
		if (localizedData.length) {
			setParams({
				start: localizedData[0].begin,
				end: localizedData[localizedData.length - 1].end,
				duration:
					localizedData[localizedData.length - 1].end -
					localizedData[0].begin,
			});
		}
	}, [localizedData]);

	const handleElementHover = useCallback((event, entry) => {
		const position =
			event.target.getBoundingClientRect().left -
			38 -
			225 +
			event.target.clientWidth / 2;
		popup.current.style.left = `${
			position < 0
				? 0
				: position > content.current.clientWidth - 450
				? content.current.clientWidth - 450
				: position
		}px`;
		setIsVisible(true);
		setPopupInfo(entry);
	}, []);

	const timeLineElements = useMemo(() => {
		if (params.duration === null) return null;
		return localizedData.map((entry) => {
			return (
				<TimeLineElement
					onMouseEnter={(event) => handleElementHover(event, entry)}
					onMouseOut={() => setIsVisible(false)}
					isSelected={entry.uuid === selectedUuid}
					isHidden={entry.isHidden}
					onClick={() =>
						!shouldBlockClicks.current && handleClick
							? handleClick(entry)
							: null
					}
					key={entry.uuid}
					displayColor={entry.displayColor}
					width={((entry.end - entry.begin) / params.duration) * 100}
					margin={(entry.marginMS / params.duration) * 100}
				/>
			);
		});
	}, [params, selectedUuid]);

	const timeLineScales = useMemo(() => {
		if (params.duration === null) return null;
		const isMoreThanDay = params.duration > 24 * 60 * 60 * 1000;
		const deltaTime = Number((params.duration / (9).toFixed(0)).toFixed(0));
		const scales = new Array(Number((10).toFixed(0)))
			.fill(0)
			.map((_, index) =>
				setTimeZoneOffset(
					new Date(params.start + deltaTime * index),
					localizedData && localizedData[0]?.dt?.slice(-6, -3)
				)
			);

		return scales.map((segment, index) => (
			<TimeSegment
				position={
					index === 0
						? "start"
						: index === scales.length - 1
						? "end"
						: "center"
				}
				key={segment}
			>
				<TimeStamp>
					{isMoreThanDay
						? `${segment.toLocaleDateString(
								`${
									i18n.resolvedLanguage
								}-${i18n.resolvedLanguage.toUpperCase()}`,
								{
									month: "numeric",
									day: "numeric",
								}
						  )} - ${segment.toTimeString().slice(0, 5)}`
						: segment.toTimeString().slice(0, 8)}
				</TimeStamp>
			</TimeSegment>
		));
	}, [params]);

	return (
		<Content ref={content}>
			<ButtonsWrapper>
				<OpenInFullIcon
					sx={{
						"cursor": isZoomed ? "pointer" : "default",
						"transitionDuration": "150ms",
						"&:hover": {
							color: isZoomed
								? "rgba(0, 0, 0, 0.6)"
								: "rgba(0, 0, 0, 0.3)",
						},
						"&:active": {
							color: isZoomed
								? "rgba(0, 0, 0, 0.7)"
								: "rgba(0, 0, 0, 0.3)",
						},
						"color": isZoomed
							? "rgba(0, 0, 0, 0.8)"
							: "rgba(0, 0, 0, 0.3)",
					}}
					onClick={isZoomed ? resetZoom : null}
				/>
			</ButtonsWrapper>
			<TimeLineWrapper ref={zoomBody}>
				<ZoomWrapper ref={parent}>
					{isLoaded ? (
						<>
							<TimeLineBody>{timeLineElements}</TimeLineBody>
							<TimeScales>{timeLineScales}</TimeScales>
						</>
					) : null}
					<ZoomSelector ref={zoomArea} />
				</ZoomWrapper>
			</TimeLineWrapper>
			<PopupWrapper
				location={popupLocation}
				ref={popup}
				isVisible={isVisible}
			>
				<Popup {...popupInfo} />
			</PopupWrapper>
		</Content>
	);
};
