import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getPalletList = createAsyncThunk(
	"deprecation/getPallets",
	async function ({ from, to }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(
				`/api/rfid/ws/stat?dt_begin=${encodeURIComponent(
					from
				)}&dt_end=${encodeURIComponent(to)}&desc=true&limit=10000000`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const rfidSlice = createSlice({
	name: "deprecation",
	initialState: {
		palletList: [],
		palletListStatus: "fulfilled",
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPalletList.pending, (state) => {
				state.palletListStatus = "pending";
			})
			.addCase(getPalletList.fulfilled, (state, action) => {
				state.palletListStatus = "fulfilled";
				state.palletList = action.payload;
			})
			.addCase(getPalletList.rejected, (state) => {
				state.palletListStatus = "rejected";
			});
	},
});

export default rfidSlice.reducer;
