import { PalletView } from "./PalletView";
import DataList from "./DataList";
import styled from "styled-components";
import {
	Button,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "./UI/CircleLoader";
import { useTranslation } from "react-i18next";
import {
	getPalletInfo,
	pointCloudDownloadReport,
} from "../store/newReportSlice";
import { RadioButtonGroup } from "./RadioButtonGroup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckboxGroup } from "./CheckboxGroup";
import { submitPalletDecision } from "../store/defectsInspectionSlice";

const Content = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 3rem;
`;

const PalletWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 57vw;
`;

const AnalysisPanel = styled.div`
	flex: 1;
`;

const AnalysisForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Wrapper = styled.div`
	max-width: max-content;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1.5rem;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`;

const Heading = styled.p`
	font-size: 20px;
`;

const PaddingWrapper = styled.div`
	padding-left: 1.5rem;
`;

export const ReportOperativeScreen = ({
	pallet,
	decision,
	imageCrop,
	image,
	scheme,
	selectedDefects,
	sectorStatus,
	palletStatus,
	imageCropCoords,
	updateAfterTagApply,
	positiveDecisionHandler,
	negativeDecisionHandler,
	handleMaskClick,
	isNextAvailable,
	isPreviousAvailable,
	handleNext,
	handlePrevious,
}) => {
	const { cloudStatus } = useSelector((state) => state.newReport);
	const { currentPalletDecision } = useSelector(
		(state) => state.defectInspection
	);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isTagDecisionLoading, setIsTagDecisionLoading] = useState(false);
	const [palletCategory, setPalletCategory] = useState(
		currentPalletDecision?.length
			? currentPalletDecision?.length > 1
				? 1
				: currentPalletDecision[0]
			: null
	);
	const defaultCheckboxes = useMemo(
		() => [
			{
				label: `${t("color.crack")} (Q)`,
				checked: false,
				value: 4,
			},
			{
				label: `${t("color.pit")} (W)`,
				checked: false,
				value: 5,
			},
			{
				label: `${t("color.stain")} (E)`,
				checked: false,
				value: 6,
			},
			{
				label: `${t("color.destruction")} (R)`,
				checked: false,
				value: 7,
			},
			{
				label: `${t("color.missingStone")} (T)`,
				checked: false,
				value: 10,
			},
			{
				label: `${t("color.misplacedStone")} (Y)`,
				checked: false,
				value: 9,
			},
		],
		[t]
	);
	const [checkboxes, setCheckboxes] = useState(
		structuredClone(defaultCheckboxes).map((checkbox) => ({
			...checkbox,
			checked: Boolean(currentPalletDecision?.includes(checkbox.value)),
		}))
	);

	useEffect(() => {
		setPalletCategory(
			currentPalletDecision?.length
				? currentPalletDecision?.length > 1
					? 1
					: currentPalletDecision[0]
				: null
		);
		setCheckboxes(
			structuredClone(defaultCheckboxes).map((checkbox) => ({
				...checkbox,
				checked: Boolean(
					currentPalletDecision?.includes(checkbox.value)
				),
			}))
		);
	}, [currentPalletDecision, defaultCheckboxes]);

	const handleChange = (event) => {
		if (palletCategory === 1) dropCheckboxes();
		setPalletCategory((prev) =>
			prev === event.target.value ? null : event.target.value
		);
	};

	const handleRadioChange = (value) => {
		dropCheckboxes();
		setPalletCategory(value);
	};

	const dropCheckboxes = useCallback(() => {
		setCheckboxes(structuredClone(defaultCheckboxes));
	}, [defaultCheckboxes]);

	const handleCheckboxChange = (index) => {
		setPalletCategory(1);
		setCheckboxes((prev) => {
			const temp = structuredClone(prev);
			temp[index].checked = !temp[index].checked;
			return temp;
		});
	};

	const palletCategories = [
		{
			value: 3,
			label: `${t(`defectInspection.drop`)} (2)`,
		},
		{
			value: 2,
			label: `${t(`defectInspection.notDrop`)} (3)`,
		},
		{
			value: 11,
			label: `${t(`defectInspection.empty`)} (4)`,
		},
		{
			value: 8,
			label: `${t(`defectInspection.anomaly`)} (5)`,
		},
		{
			value: 0,
			label: `${t(`defectInspection.clean`)} (6)`,
		},
		{
			value: 12,
			label: `${t(`defectInspection.segmentationTroubles`)} (7)`,
		},
	];

	const handleSubmit = useCallback(() => {
		setIsTagDecisionLoading(true);
		dispatch(
			submitPalletDecision({
				id: pallet.uuid,
				annotations: [
					parseInt(palletCategory),
					...checkboxes
						.filter((checkbox) => checkbox.checked)
						.map((checkbox) => checkbox.value),
				],
			})
		).then(() => {
			updateAfterTagApply().then(() => setIsTagDecisionLoading(false));
		});
	}, [palletCategory, checkboxes, pallet, dispatch]);

	const handleHotkey = useCallback(
		(event) => {
			const handleRadio = (value) => (prev) =>
				prev === value ? null : value;
			switch (event.keyCode) {
				case 49: {
					if (palletCategory === 1) dropCheckboxes();
					setPalletCategory(handleRadio(1));
					break;
				}
				case 50: {
					dropCheckboxes();
					setPalletCategory(handleRadio(3));
					break;
				}
				case 51: {
					dropCheckboxes();
					setPalletCategory(handleRadio(2));
					break;
				}
				case 52: {
					dropCheckboxes();
					setPalletCategory(handleRadio(11));
					break;
				}
				case 53: {
					dropCheckboxes();
					setPalletCategory(handleRadio(8));
					break;
				}
				case 54: {
					dropCheckboxes();
					setPalletCategory(handleRadio(0));
					break;
				}
				case 55: {
					dropCheckboxes();
					setPalletCategory(handleRadio(12));
					break;
				}
				case 81: {
					handleCheckboxChange(0);
					break;
				}
				case 87: {
					handleCheckboxChange(1);
					break;
				}
				case 69: {
					handleCheckboxChange(2);
					break;
				}
				case 82: {
					handleCheckboxChange(3);
					break;
				}
				case 84: {
					handleCheckboxChange(4);
					break;
				}
				case 89: {
					handleCheckboxChange(5);
					break;
				}
				case 37: {
					if (isPreviousAvailable) handlePrevious();
					break;
				}
				case 39: {
					if (isNextAvailable) handleNext();
					break;
				}
				case 13: {
					if (
						!(
							palletCategory === null ||
							(palletCategory === 1 &&
								!checkboxes.filter(
									(checkbox) => checkbox.checked
								).length)
						)
					)
						handleSubmit();
					break;
				}
				default:
					break;
			}
		},
		[handleSubmit, palletCategory, checkboxes, dropCheckboxes]
	);

	useEffect(() => {
		document.documentElement.addEventListener("keydown", handleHotkey);
		return () =>
			document.documentElement.removeEventListener(
				"keydown",
				handleHotkey
			);
	}, [handleHotkey]);

	return (
		<Content>
			<DataList
				isLoaded={palletStatus === "fulfilled"}
				scheme={scheme}
				isVertical
			/>
			<PalletWrapper>
				<ButtonsWrapper>
					<IconButton
						sx={{
							aspectRatio: "1",
							width: "fit-content",
							height: "fit-content",
						}}
						disabled={!isPreviousAvailable}
						onClick={handlePrevious}
					>
						<ChevronLeftIcon
							sx={{
								fontSize: "3rem",
								color: isPreviousAvailable
									? "#1976d2"
									: "#dddddd",
							}}
						/>
					</IconButton>
					<PalletView
						scheme={scheme}
						imageCrop={imageCropCoords}
						imagesCrop={imageCrop}
						positiveDecisionHandler={positiveDecisionHandler(
							pallet?.uuid
						)}
						negativeDecisionHandler={negativeDecisionHandler(
							pallet?.uuid
						)}
						selectedDefects={selectedDefects}
						decisionBySector={decision}
						clickedChartPalletId={pallet?.uuid}
						isSectorLoaded={sectorStatus === "fulfilled"}
						handleMaskClick={handleMaskClick}
						images={[image]}
					/>
					<IconButton
						sx={{
							aspectRatio: "1",
							width: "fit-content",
							height: "fit-content",
						}}
						disabled={!isNextAvailable}
						onClick={handleNext}
					>
						<ChevronRightIcon
							sx={{ fontSize: "3rem", color: "#1976d2" }}
						/>
					</IconButton>
				</ButtonsWrapper>
			</PalletWrapper>
			<AnalysisPanel>
				<Wrapper>
					<AnalysisForm>
						<Heading>
							{t("defectInspection.analysisHeading")}
						</Heading>
						<RadioGroup
							sx={{
								display: "flex",
								gap: "0.5rem",
							}}
							value={palletCategory}
						>
							<FormControlLabel
								label={`${t("defectInspection.defects")} (1)`}
								value={1}
								control={<Radio onClick={handleChange} />}
							/>
						</RadioGroup>
						<PaddingWrapper>
							<CheckboxGroup
								handleChange={handleCheckboxChange}
								checkboxes={checkboxes}
							/>
						</PaddingWrapper>
						<RadioButtonGroup
							value={palletCategory}
							setValue={handleRadioChange}
							entries={palletCategories}
						/>
						<Button
							disabled={
								isTagDecisionLoading ||
								palletCategory === null ||
								(palletCategory === 1 &&
									!checkboxes.filter(
										(checkbox) => checkbox.checked
									).length)
							}
							onClick={handleSubmit}
						>
							{isTagDecisionLoading ? (
								<CircleLoader />
							) : (
								`${t("newReport.apply")} (Enter)`
							)}
						</Button>
					</AnalysisForm>
					<Button
						sx={{ height: "44px" }}
						disabled={cloudStatus === "pending"}
						onClick={() =>
							dispatch(
								pointCloudDownloadReport({
									palletId: pallet.uuid,
								})
							)
						}
					>
						{cloudStatus === "pending" ? (
							<CircleLoader />
						) : (
							t("pointCloud")
						)}
					</Button>
				</Wrapper>
			</AnalysisPanel>
		</Content>
	);
};
