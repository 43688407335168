import {
	Button,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
	emptyTempPassword,
	resetPassword,
	updateUserData,
} from "../store/userSlice";
import CircleLoader from "./UI/CircleLoader";

const Dialog = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 320px;
`;

const ResetButton = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

export const EditUserDialog = ({ user, closeDialog, setSnackbarState }) => {
	const [name, setName] = useState(user.name);
	const [role, setRole] = useState(user.role);
	const { tempPassword, tempPasswordStatus } = useSelector(
		(state) => state.user
	);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(
		() => () => {
			dispatch(emptyTempPassword());
		},
		[dispatch]
	);

	useEffect(() => {
		if (tempPasswordStatus === "rejected")
			setSnackbarState({
				show: true,
				success: false,
				msg: t("settings.users.resetFail"),
			});
	}, [tempPasswordStatus, setSnackbarState, t]);

	const validate = () => {
		if (name === "") {
			return [false, t("settings.users.nameEmpty")];
		}
		if (role === "") {
			return [false, t("settings.users.roleEmpty")];
		}
		return [true];
	};

	const handleResetPassword = () => {
		dispatch(resetPassword({ user_id: user.id }));
	};

	const handleEditUser = () => {
		const validationResults = validate();
		if (!validationResults[0]) {
			setSnackbarState({
				show: true,
				success: validationResults[0],
				msg: validationResults[1],
			});
			return;
		}
		dispatch(
			updateUserData({
				user_id: user.id,
				name,
				role: ["admin", "moderator"].includes(user.role)
					? user.role
					: role,
			})
		).then(() => {
			setSnackbarState({
				show: true,
				success: true,
				msg: t("settings.users.editSuccess"),
			});
		});
		closeDialog();
	};

	return (
		<Dialog>
			<div>
				<p>{t("settings.users.userName")}</p>
				<TextField
					value={name}
					onChange={(e) => setName(e.target.value)}
					fullWidth
				/>
			</div>
			<div>
				<p>{t("settings.users.role")}</p>
				<Select
					disabled={["admin", "moderator"].includes(user.role)}
					value={role}
					onChange={(e) => setRole(e.target.value)}
					fullWidth
				>
					<MenuItem value={"operator"}>{t("operator")}</MenuItem>
					{/* <MenuItem value={"moderator"}>{t("moderator")}</MenuItem> */}
					<MenuItem value={"admin"}>{t("admin")}</MenuItem>
				</Select>
			</div>

			<div>
				<p>{t("settings.users.passwordReset")}</p>
				<TextField
					fullWidth
					value={tempPassword}
					inputProps={{
						sx: { cursor: "text" },
						disabled: true,
					}}
					InputProps={{
						startAdornment: !tempPassword ? (
							<InputAdornment position="start">
								<ResetButton>
									<Button
										disabled={
											tempPasswordStatus === "pending"
										}
										onClick={handleResetPassword}
									>
										{t("settings.users.reset")}
									</Button>
									{tempPasswordStatus === "pending" && (
										<CircleLoader />
									)}
								</ResetButton>
							</InputAdornment>
						) : null,
					}}
				></TextField>
			</div>

			<div>
				<Button onClick={handleEditUser}>
					{t("settings.users.accept")}
				</Button>
			</div>
		</Dialog>
	);
};
