import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { deDE, ruRU, enUS } from "@mui/x-date-pickers";
import { useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/de";
import "dayjs/locale/en";

export const LocalizedDateTimePicker = ({
	value,
	setValue,
	sx,
	variant,
	maxDateTime,
	minDateTime,
	disabled,
}) => {
	const { i18n } = useTranslation();
	const locale = useRef(
		(() => {
			switch (i18n.resolvedLanguage) {
				case "ru":
					return {
						ampm: false,
						baseLanguage: ruRU,
						views: [
							"year",
							"month",
							"day",
							"hours",
							"minutes",
							"seconds",
						],
						format: "DD/MM/YYYY HH:mm:ss",
					};
				case "en":
					return {
						ampm: true,
						baseLanguage: enUS,
						views: [
							"year",
							"month",
							"day",
							"hours",
							"minutes",
							"seconds",
						],
						format: "MM/DD/YYYY HH:mm:ss aa",
					};
				case "de":
					return {
						ampm: false,
						baseLanguage: deDE,
						views: [
							"year",
							"month",
							"day",
							"hours",
							"minutes",
							"seconds",
						],
						format: "DD/MM/YYYY HH:mm:ss",
					};
				default:
					return {
						ampm: false,
						baseLanguage: ruRU,
						views: [
							"year",
							"month",
							"day",
							"hours",
							"minutes",
							"seconds",
						],
						format: "DD/MM/YYYY HH:mm:ss",
					};
			}
		})()
	);

	return (
		<LocalizationProvider
			adapterLocale={i18n.resolvedLanguage}
			dateAdapter={AdapterDayjs}
			localeText={
				locale.current.baseLanguage.components.MuiLocalizationProvider
					.defaultProps.localeText
			}
		>
			<DateTimePicker
				views={locale.current.views}
				maxDateTime={maxDateTime}
				minDateTime={minDateTime}
				viewRenderers={{
					hours: null,
					minutes: null,
					seconds: null,
				}}
				ampm={locale.current.ampm}
				slotProps={{ textField: { variant } }}
				disabled={disabled}
				value={value}
				sx={sx}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				format={locale.current.format}
				disableFuture
			/>
		</LocalizationProvider>
	);
};
