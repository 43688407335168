import { Dialog, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getImageRef, getImageRefMask } from "../../store/colorSlice";

const SvgWrapper = styled.svg.attrs({
	xmlns: "http://www.w3.org/2000/svg",
	viewBox: "0 0 1400 1100",
})`
	width: 1046px;
	height: 826px;
`;

const ImageDialog = ({ opened, onClose, children, imgRef, refId }) => {
	const { imageRef, imageRefMask } = useSelector((state) => state.color);

	const dispatch = useDispatch();

	useEffect(() => {
		if (opened) {
			dispatch(getImageRef(imgRef));
			dispatch(getImageRefMask(imgRef));
		}
	}, [opened, imgRef]);

	const handleClose = () => {
		onClose();
	};
	const filterByIdImg = imageRef?.filter(
		(item) => item.id === imgRef.split("__")[0]
	);

	const maskFilter = imageRefMask?.filter(
		(item) => item.id === imgRef.split("__")[0]
	);

	return (
		<Box>
			<Dialog
				open={opened}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={"xl"}
			>
				<DialogContent>
					<SvgWrapper>
						<image
							href={{ ...filterByIdImg }[0]?.blob}
							loading="lazy"
						/>
						{{ ...maskFilter }[0]?.mask.map((item) => (
							<g key={item.id}>
								<filter
									id="rounded-corners"
									x="-5%"
									width="110%"
									y="0%"
									height="100%"
								>
									<feFlood floodColor="#353535" />
									<feGaussianBlur stdDeviation="2" />
									<feComponentTransfer>
										<feFuncA
											type="table"
											tableValues="0 0 0 1"
										/>
									</feComponentTransfer>

									<feComponentTransfer>
										<feFuncA
											type="table"
											tableValues="0 1 1 1 1 1 1 1"
										/>
									</feComponentTransfer>
									<feComposite
										operator="over"
										in="SourceGraphic"
									/>
								</filter>
								<polygon
									id={`${item.id}`}
									fill="#00000000"
									stroke={"green"}
									strokeWidth={"1.5px"}
									points={item.points}
								/>
							</g>
						))}
					</SvgWrapper>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default ImageDialog;
