import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { openUserSession } from "./userSlice";

export const authLogin = createAsyncThunk(
	"auth/login",
	async function ({ username, password }, { rejectWithValue, dispatch }) {
		try {
			const details = {
				username: username,
				password: password,
			};
			const formBody = Object.keys(details)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(details[key])
				)
				.join("&");
			const response = await fetch("/api/token", {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: formBody,
			});
			if (!response.ok) {
				if (response.status === 401) throw new Error("Unauthorized");
				throw new Error("Server error");
			}
			const data = await response.json();
			localStorage.setItem("apiToken", data.access_token);
			dispatch(openUserSession());
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const authSlice = createSlice({
	name: "auth",
	initialState: {
		status: null,
		users: null,
		error: null,
		apiToken: localStorage.getItem("apiToken"),
	},
	reducers: {
		authLogout(state, action) {
			state.apiToken = null;
			localStorage.removeItem("apiToken");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(authLogin.pending, (state) => {
				state.status = "loading";
				state.apiToken = null;
				localStorage.removeItem("apiToken");
			})
			.addCase(authLogin.fulfilled, (state, action) => {
				state.status = "resolved";
				state.error = null;
				state.users = action.payload;
				state.apiToken = action.payload.access_token;
				localStorage.setItem("counter", 0);
				localStorage.setItem("apiToken", action.payload.access_token);
			})
			.addCase(authLogin.rejected, setError);
	},
});

export const { authLogout } = authSlice.actions;

export default authSlice.reducer;
