import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";

export const RadioButtonGroup = ({ entries, value, setValue }) => {
	const handleChange = (event) => {
		setValue((prev) =>
			prev === event.target.value ? null : event.target.value
		);
	};

	return (
		<FormControl>
			<RadioGroup
				sx={{
					display: "flex",
					gap: "0.5rem",
				}}
				value={value}
			>
				{entries.map((entry) => (
					<FormControlLabel
						key={entry.value}
						label={entry.label}
						value={entry.value}
						control={<Radio onClick={handleChange} />}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
