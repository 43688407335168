import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";

export default function RfidTable({ rows }) {
	const { t } = useTranslation();

	const sortedRows = [...rows]?.sort(
		(a, b) => new Date(b.dt).getTime() - new Date(a.dt).getTime()
	);

	if (!sortedRows) return <></>;

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 500 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell sx={{ width: "100px" }}>
							{t("rfid.ID")}
						</TableCell>
						<TableCell align="left">{t("rfid.time")}</TableCell>
						<TableCell align="left">
							{t("rfid.deprecation")}
						</TableCell>
						<TableCell align="left">{t("rfid.pallet")}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedRows?.map((row) => (
						<TableRow
							key={row?.tag_id}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0,
								},
								"width": "100px",
							}}
						>
							<TableCell
								component="th"
								scope="row"
								sx={{ fontSize: "12px", minWidth: "150px" }}
							>
								{row?.tag_id}
							</TableCell>
							<TableCell
								align="left"
								sx={{ width: "150px", whiteSpace: "nowrap" }}
							>
								{row?.dt
									?.split("+")[0]
									?.split("T")[1]
									?.slice(0, 8)}
								({row?.dt?.split("+")[0]?.split("T")[0]})
							</TableCell>
							<TableCell
								component="th"
								scope="row"
								sx={{ fontSize: "12px" }}
							>
								{row?.rounds}
							</TableCell>
							<TableCell align="left" sx={{ width: "150px" }}>
								{row?.last_paired_with
									? row?.last_paired_with
									: ""}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
