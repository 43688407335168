import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isClickBySerie } from "../../store/palletSlice";
import { getNonSeriesPallets } from "../../store/reportSlice";
import ImageLoader from "../UI/ImageLoader";
import ColorImageLoader from "./ColorImageLoader";

const BatchImages = () => {
	const { palletsForImageZipped, zippedImagesStatus, clickBySerie } =
		useSelector((state) => state.pallet);
	const { nonSeriesPallets } = useSelector((state) => state.report);
	const [page, setPage] = useState(0);
	const [load, setLoad] = useState(false);
	const [loadImgs, setLoadImgs] = useState([]);
	const [loadNonImgs, setLoadNonImgs] = useState([]);

	const dispatch = useDispatch();

	useEffect(() => {
		window.removeEventListener("scroll", scrollHandler);
		window.addEventListener("scroll", scrollHandler, { passive: true });
		return () => window.removeEventListener("scroll", scrollHandler);
	}, []);

	useEffect(() => {
		if (load) {
			setPage(page + 1);
		}
	}, [load]);

	useEffect(() => {
		if (palletsForImageZipped) {
			const filtered = [
				...loadImgs,
				...[...palletsForImageZipped]
					?.reverse()
					.slice(page * 24, (page + 1) * 24),
			].filter(
				(value, index, self) =>
					index ===
					self.findIndex(
						(t) => t.uuid === value.uuid && t.dt === value.dt
					)
			);
			setLoadImgs(filtered);
		}
	}, [page, palletsForImageZipped]);

	useEffect(() => {
		if (clickBySerie) {
			setLoadImgs([]);
			dispatch(isClickBySerie(false));
		}
	}, [clickBySerie]);

	useEffect(() => {
		if (!palletsForImageZipped) {
			dispatch(getNonSeriesPallets());
		}
	}, [palletsForImageZipped]);

	useEffect(() => {
		if (!palletsForImageZipped && nonSeriesPallets) {
			const filtered = [
				...loadNonImgs,
				...nonSeriesPallets?.slice(page * 24, (page + 1) * 24),
			].filter(
				(value, index, self) =>
					index ===
					self.findIndex(
						(t) => t.uuid === value.uuid && t.dt === value.dt
					)
			);
			setLoadNonImgs(filtered);
		}
	}, [page, nonSeriesPallets]);

	const scrollHandler = (e) => {
		if (
			e.target.documentElement.scrollHeight -
				(e.target.documentElement.scrollTop + window.innerHeight) <
			100
		) {
			setLoad(true);
		} else {
			setLoad(false);
		}
	};

	if (!palletsForImageZipped && zippedImagesStatus === "rejected")
		return (
			<Box
				display={"flex"}
				flexDirection="row"
				gap={"16px"}
				flexWrap="wrap"
			>
				{loadNonImgs.map((pallet, index) => (
					<ColorImageLoader
						colorName={pallet?.color?.name}
						dt={pallet?.dt.split("T")[1].split("+")[0]}
						uuid={pallet?.uuid}
						key={pallet?.uuid}
						batch={true}
					/>
				))}
			</Box>
		);

	return (
		<Box display={"flex"} flexDirection="row" gap={"16px"} flexWrap="wrap">
			{loadImgs.map((pallet, index) => (
				<ColorImageLoader
					colorName={pallet?.color?.name}
					dt={pallet?.dt.split("T")[1].split("+")[0]}
					uuid={pallet?.uuid}
					key={pallet?.uuid}
					batch={true}
				/>
			))}
		</Box>
	);
};

export default BatchImages;
