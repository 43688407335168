import React, { useEffect, useState } from "react";
import "../../styles/Calendar.css";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getSeriesByDay } from "../../store/palletSlice";

import Calendar from "react-calendar";
import { Button } from "@mui/material";

const DatePickerToggle = styled.span`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	background-color: rgb(246, 246, 246);
	border-radius: 8px;
	margin-top: 16px;
	@media (min-width: 1399px) and (max-width: 1535px) {
		padding-right: 2%;
	}
`;

const ReportCalendar = ({ onClick, setDay, setPalletUUid }) => {
	const [dispatchedDay, setDispatchedDay] = useState(new Date());

	const dispatch = useDispatch();

	useEffect(() => {
		const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		const localTime = new Date(dispatchedDay.getTime() - tzoffset);
		const localISOTime = new Date(dispatchedDay.getTime() - tzoffset)
			.toISOString()
			.substring(0, 10);
		dispatch(
			getSeriesByDay({
				begin: localISOTime,
				end: localISOTime,
			})
		);
		if (setPalletUUid) {
			setPalletUUid(null);
		}
		if (setDay) {
			setDay(localTime);
		}
	}, [dispatchedDay]);

	return (
		<DatePickerToggle>
			<Calendar
				maxDate={new Date()}
				value={dispatchedDay}
				onChange={setDispatchedDay}
			/>
			{setDay && <Button onClick={onClick}>X</Button>}
		</DatePickerToggle>
	);
};

export default ReportCalendar;
