import { useLocation, Navigate } from "react-router-dom";

export function RequireAuth({ children }) {
	let location = useLocation();

	if (localStorage.getItem("apiToken") === "") {
		return <Navigate to="/login" state={{ from: location }} replace />;
	} else {
		return children;
	}
}
