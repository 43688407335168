import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

const Text = styled.p`
	display: flex;
	gap: 0.5rem;
`;

const Value = styled.p`
	font-weight: 600;
`;

export const TactPopup = ({
	uuid,
	defect_prod_cnt,
	height_ovf_prod_cnt,
	bdt,
	edt,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<div>
				<Text>
					{t(`popup.time`)}:<Value>{uuid}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`popup.defectCount`)}:<Value>{defect_prod_cnt}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`popup.heightCount`)}:
					<Value>{height_ovf_prod_cnt}</Value>
				</Text>
			</div>
		</div>
	);
};
