import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const Content = styled.div`
	height: 100vh;
	z-index: 999;
	width: calc(100vw - 60px);
	position: fixed;
	top: 0;
	left: 60px;
`;

const StyledIframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`;

const CloseButtonWrapper = styled.div`
	position: absolute;
	right: 1rem;
	top: 1rem;
`;

export const SupportWindow = ({ token, closeSupport }) => {
	return (
		<Content>
			<CloseButtonWrapper>
				<IconButton
					sx={{
						color: window.matchMedia("(prefers-color-scheme: dark)")
							.matches
							? "#f7f9ff"
							: "#2F3449",
					}}
					size="large"
					onClick={closeSupport}
				>
					<CloseIcon fontSize="large" />
				</IconButton>
			</CloseButtonWrapper>
			<StyledIframe
				title="support"
				src={`http://51.250.72.5:8090/direct/quatromatic.podderzhka?resumeToken=${token?.authToken}&layout=embedded`}
			/>
		</Content>
	);
};
