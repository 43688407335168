import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import CircleLoader from "./UI/CircleLoader";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Content = styled.div`
	flex: calc(1/6);
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 0.5rem;
`;

const PlaceHolder = styled.p`
	font-size: 24px;
`;

const ImageWrapper = styled.div`
	width: 100%;
	aspect-ratio: 14/11;
	border: ${(props) =>
		props.isSelected
			? "3px solid rgba(30, 109, 255, 0.8)"
			: "1px solid #ccc"};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled.p`
	
`;

const Image = styled.img`
	width: 100%;
	aspect-ratio: 14/11;
`;

export const GalleryItem = ({ image, imageClickHandler, isSelected }) => {
	const [isLoaded, setIsLoaded] = useState(Boolean(image.url));
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		!isLoaded && image.load(dispatch).then(() => setIsLoaded(true));
	}, [image.label, isLoaded, dispatch]);

	return (
		<Content onClick={() => imageClickHandler(image)}>
			<ImageWrapper isSelected={isSelected}>
				{isLoaded ? (
					image.url ? (
						<Image src={image.url} />
					) : (
						<PlaceHolder>{t("noImage")}</PlaceHolder>
					)
				) : (
					<CircleLoader />
				)}
			</ImageWrapper>
			<Label>{image.label}</Label>
		</Content>
	);
};
