import { Button } from "@mui/material";
import ComboBox from "./ComboBox";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BigCircleLoader from "./UI/BigCircleLoader";
import { submitMerge } from "../store/newReportSlice";
import { useTranslation } from "react-i18next";
import { getColorList, getFormList } from "../store/colorSlice";
import { getNoTokenUsers } from "../store/userSlice";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	width: 400px;
	overflow: visible;
`;

const Header = styled.p`
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-end;
	flex: 1;
`;

const Label = styled.p`
	width: 30%;
`;

export const BatchMergeForm = ({ closeForm, batches, filters }) => {
	const { colorList, formList } = useSelector((state) => state.color);
	const { noTokenUsers } = useSelector((state) => state.user);
	const { requestedDates } = useSelector((state) => state.newReport);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();

	const [form, setForm] = useState({
		uuid: batches.at(0).product_id,
		name: batches.at(0).product_name,
	});
	const [color, setColor] = useState({
		uuid: batches.at(0).color_id,
		name: batches.at(0).color_name,
	});
	const [user, setUser] = useState({
		uuid: batches.at(0).user?.uuid ?? null,
		name: batches.at(0).user?.name ?? "",
	});

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		Promise.allSettled([
			dispatch(getFormList()),
			dispatch(getColorList()),
			dispatch(getNoTokenUsers()),
		]).then(() => setIsLoading(false));
	}, []);

	return (
		<Content>
			<Header>{t("newReport.mergeBatches")}</Header>
			{!isLoading ? (
				<>
					<Wrapper>
						<Label>{t("newReport.form")}:</Label>
						<ComboBox
							options={formList}
							setValue={setForm}
							value={form}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.color")}:</Label>
						<ComboBox
							options={colorList}
							setValue={setColor}
							value={color}
						/>
					</Wrapper>
					<Wrapper>
						<Label>{t("newReport.operator")}:</Label>
						<ComboBox
							options={noTokenUsers
								.filter((user) => user.role === "operator")
								.map((user) => ({
									name: user.name,
									uuid: user.id,
								}))}
							setValue={setUser}
							value={user}
						/>
					</Wrapper>
					<ButtonsWrapper>
						<Wrapper>
							<Button sx={{ color: "red" }} onClick={closeForm}>
								{t("newReport.cancel")}
							</Button>
							<Button
								onClick={() => {
									closeForm();
									dispatch(
										submitMerge({
											batch_ids: batches.map(
												(batch) => batch.batch_id
											),
											product_id: form?.uuid,
											color_id: color?.uuid,
											user_id: user?.uuid,
											filters,
											requestedDates: requestedDates,
										})
									);
								}}
							>
								{t("newReport.apply")}
							</Button>
						</Wrapper>
					</ButtonsWrapper>
				</>
			) : (
				<BigCircleLoader />
			)}
		</Content>
	);
};
