import { InputAdornment, TextField, Typography } from "@mui/material";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: ${(props) =>
		props.areSeparated ? "space-between" : "flex-start"};
`;

export const LabeledInput = ({
	label,
	value,
	onChange,
	type,
	error,
	areSeparated,
	InputProps,
	adornment,
	inputStyles,
}) => {
	return (
		<Wrapper areSeparated={areSeparated}>
			<Typography>{label}</Typography>
			<TextField
				error={error}
				sx={inputStyles}
				type={type}
				value={value}
				onChange={onChange}
				InputProps={{
					...InputProps,
					endAdornment: <InputAdornment>{adornment}</InputAdornment>,
				}}
			/>
		</Wrapper>
	);
};
