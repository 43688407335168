import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SwitchLangToggle = () => {
	const { t, i18n } = useTranslation();

	const handleChange = (event) => {
		i18n.changeLanguage(event.target.value);
		localStorage.setItem("language", event.target.value);
	};

	return (
		<Box sx={{ width: 120 }}>
			<Box display={"flex"} alignItems={"center"} gap={"15px"}>
				<Typography>{t("settings.title.lang")}:</Typography>
				<Select value={i18n.language} onChange={handleChange}>
					<MenuItem value={"ru"}>RU</MenuItem>
					<MenuItem value={"en"}>EN</MenuItem>
					<MenuItem value={"de"}>DE</MenuItem>
				</Select>
			</Box>
		</Box>
	);
};

export default SwitchLangToggle;
