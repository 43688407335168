import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	getPalletsForImageZipped,
	isClickBySerie,
} from "../../store/palletSlice";
import SerieBatch from "./SerieBatch";

const SeriesList = ({ seriesByDay }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		if (seriesByDay) {
			setSelectedIndex(0);
			dispatch(
				getPalletsForImageZipped({
					bdt: seriesByDay[0]?.bdt,
					edt: seriesByDay[0]?.edt,
					has_crack: null,
					has_pit: null,
					has_stain: null,
					has_destruction: null,
					has_no_defect: null,
					has_height_overlimit: null,
					user_decision: null,
					content: null,
					include_garbage: null,
					include_empty: null,
				})
			);
		}
	}, [seriesByDay]);

	const clickHandler = (bdt, edt, index) => {
		setSelectedIndex(index);
		dispatch(isClickBySerie(true));
		dispatch(
			getPalletsForImageZipped({
				bdt: bdt,
				edt: edt,
				has_crack: null,
				has_pit: null,
				has_stain: null,
				has_destruction: null,
				has_no_defect: null,
				has_height_overlimit: null,
				user_decision: null,
				content: null,
				include_garbage: null,
				include_empty: null,
			})
		);
	};

	return (
		<Box sx={{ mt: "50px", overflow: "scroll", height: "600px" }}>
			{seriesByDay?.map((serie, index) => (
				<Box
					key={serie.bdt}
					onClick={() => clickHandler(serie.bdt, serie.edt, index)}
					sx={{
						backgroundColor:
							index === selectedIndex ? "#7CC6B6" : "none",
					}}
				>
					<SerieBatch
						bdt={serie.bdt}
						color_name={serie.color_name}
						edt={serie.edt}
						product_name={serie.product_name}
						key={serie.bdt}
					/>
				</Box>
			))}
		</Box>
	);
};

export default SeriesList;
