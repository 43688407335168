import React, { useState } from "react";
import styled from "styled-components";

const TooltipWrapper = styled.div`
	display: inline-block;
	width: 100%;
	position: relative;
`;

const TooltipContent = styled.div`
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
	color: white;
	background: black;
	font-size: 14px;
	font-family: sans-serif;
	line-height: 1;
	z-index: 100;
	white-space: nowrap;
	top: calc(30px * -1);
	&::before {
		content: " ";
		left: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-width: var(6px);
		margin-left: calc(6px * -1);
		top: 100%;
		border-top-color: black;
	}
`;

const Tooltip = (props) => {
	const [active, setActive] = useState(false);

	const showTip = () => {
		setActive(true);
	};

	const hideTip = () => {
		setActive(false);
	};

	return (
		<TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
			{/* Wrapping */}
			{props.children}
			{active && (
				<TooltipContent>
					{/* Content */}
					{props.content}
				</TooltipContent>
			)}
		</TooltipWrapper>
	);
};

export default Tooltip;
