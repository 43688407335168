import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button } from "@mui/material";
import {
	getNextPallet,
	setCounter,
	setSessionId,
	setTestDate,
	setTestLimit,
} from "../store/defectsInspectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const ContinueCheckButton = ({
	uuid,
	n_pallets_all,
	n_pallets_checked,
	creation_dt,
	userUuid,
}) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const { users } = useSelector((state) => state.user);

	const handleClick = () => {
		dispatch(setTestLimit(n_pallets_all));
		dispatch(setSessionId(uuid));
		dispatch(setCounter(n_pallets_checked));
		dispatch(setTestDate(creation_dt.slice(0, 10)));
		dispatch(getNextPallet({ sessionId: uuid }));
	};

	return n_pallets_all !== n_pallets_checked ? (
		<Button disabled={userUuid !== users.id} onClick={handleClick}>
			<ArrowRightIcon /> {t("defectInspection.continue")}
		</Button>
	) : null;
};
