import styled from "styled-components";
import { DialogWrapper } from "./DialogWrapper";
import { useEffect, useMemo, useState } from "react";
import { LabeledInput } from "./LabeledInput";
import { Button, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getColorList, getFormList } from "../store/colorSlice";
import {
	addSpecialSettings,
	setDefectInspectionSettings,
} from "../store/defectsInspectionSlice";

const Content = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`;

const SelectWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
`;

const Heading = styled.p`
	font-size: 1.5rem;
`;

export const DefectInspectionRuleRuleForm = ({ open, onClose, rule }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { formList, colorList } = useSelector((state) => state.color);

	const isEditing = useMemo(() => Boolean(rule), [rule]);
	const [selectedColor, setSelectedColor] = useState(rule?.color_id ?? "all");
	const [selectedForm, setSelectedForm] = useState(rule?.product_id ?? "all");
	const [ruleValues, setRuleValues] = useState({
		pit_threshold: rule?.pit_threshold ?? "",
		crack_threshold: rule?.crack_threshold ?? "",
		stain_threshold: rule?.stain_threshold ?? "",
		destruction_threshold: rule?.destruction_threshold ?? "",
		min_defect_size: rule?.min_defect_size ?? "",
		min_pit_depth_rough_mm: rule?.min_pit_depth_rough_mm ?? "",
		allowable_defect_number: rule?.allowable_defect_number ?? "",
	});

	useEffect(() => {
		if (!isEditing) {
			dispatch(getFormList());
			dispatch(getColorList());
		}
	}, [isEditing, dispatch]);

	const thresholdHandler = (property) => (event) => {
		const numberRegex = /^([0-9]*[.])?[0-9]*?$/;
		if (numberRegex.test(event.target.value)) {
			const newValue =
				event.target.value.length > 1
					? event.target.value.replace(/^0+(?=\d)/, "")
					: event.target.value.length
					? event.target.value
					: 0;
			setRuleValues((prev) => ({
				...prev,
				[property]: newValue > 1 ? 1 : newValue < 0 ? 0 : newValue,
			}));
		}
	};

	const defectSizeHandler = (property) => (event) => {
		const numberRegex = /^([0-9]*[.])?[0-9]*?$/;
		if (numberRegex.test(event.target.value)) {
			const newValue =
				event.target.value.length > 1
					? event.target.value.replace(/^0+(?=\d)/, "")
					: event.target.value.length
					? event.target.value
					: 0;
			setRuleValues((prev) => ({
				...prev,
				[property]: newValue,
			}));
		}
	};

	const allowedAmountHandler = (event) => {
		const numberRegex = /^[0-9]*$/;
		if (numberRegex.test(event.target.value)) {
			setRuleValues((prev) => ({
				...prev,
				allowable_defect_number: event.target.value.replace(
					/^0+(?=\d)/,
					""
				),
			}));
		}
	};

	const handleSubmit = () => {
		const settings = {
			...ruleValues,
			min_pit_depth_smooth_mm: ruleValues.min_pit_depth_rough_mm,
			...(isEditing
				? {}
				: {
						color_id:
							selectedColor === "all" ? null : selectedColor,
						product_id:
							selectedForm === "all" ? null : selectedForm,
				  }),
		};
		if (isEditing) {
			dispatch(
				setDefectInspectionSettings({
					product_id:
						rule.product_id === "all" ? null : rule.product_id,
					color_id: rule.color_id === "all" ? null : rule.color_id,
					settings,
				})
			);
			onClose();
		} else {
			dispatch(addSpecialSettings({ settings }));
			onClose();
		}
	};

	return (
		<DialogWrapper open={open} onClose={onClose}>
			<Content>
				<Heading>
					{isEditing
						? t("defectInspection.ruleEdit")
						: t("defectInspection.ruleCreate")}
				</Heading>
				{!isEditing ? (
					<>
						<SelectWrapper>
							<Typography>{t("color_name")}:</Typography>
							<Select
								sx={{ width: "235px" }}
								value={selectedColor}
								onChange={(event) =>
									setSelectedColor(event.target.value)
								}
							>
								<MenuItem value={"all"}>
									{t("allColors")}
								</MenuItem>
								{colorList
									.filter(
										(color) =>
											color.name !== "default_mono" &&
											color.name !== "default_colormix"
									)
									.map((color) => (
										<MenuItem value={color.uuid}>
											{color.name}
										</MenuItem>
									))}
							</Select>
						</SelectWrapper>
						<SelectWrapper>
							<Typography>{t("product_name")}:</Typography>
							<Select
								sx={{ width: "235px" }}
								value={selectedForm}
								onChange={(event) =>
									setSelectedForm(event.target.value)
								}
							>
								<MenuItem value={"all"}>
									{t("allForms")}
								</MenuItem>
								{formList.map((form) => (
									<MenuItem value={form.uuid}>
										{form.name}
									</MenuItem>
								))}
							</Select>
						</SelectWrapper>
					</>
				) : null}
				<p>{t("color.defecto")}</p>
				<LabeledInput
					areSeparated
					value={ruleValues.pit_threshold}
					inputStyles={{ width: "235px" }}
					onChange={thresholdHandler("pit_threshold")}
					label={`${t("pit_threshold")}:`}
					InputProps={{
						inputProps: {
							max: 1,
							min: 0,
							step: ".01",
						},
					}}
				/>
				<LabeledInput
					areSeparated
					value={ruleValues.crack_threshold}
					inputStyles={{ width: "235px" }}
					onChange={thresholdHandler("crack_threshold")}
					label={`${t("crack_threshold")}:`}
					InputProps={{
						inputProps: {
							max: 1,
							min: 0,
							step: ".01",
						},
					}}
				/>
				<LabeledInput
					areSeparated
					value={ruleValues.stain_threshold}
					inputStyles={{ width: "235px" }}
					onChange={thresholdHandler("stain_threshold")}
					label={`${t("stain_threshold")}:`}
					InputProps={{
						inputProps: {
							max: 1,
							min: 0,
							step: ".01",
						},
					}}
				/>
				<LabeledInput
					areSeparated
					value={ruleValues.destruction_threshold}
					inputStyles={{ width: "235px" }}
					onChange={thresholdHandler("destruction_threshold")}
					label={`${t("destruction_threshold")}:`}
					InputProps={{
						inputProps: {
							max: 1,
							min: 0,
							step: ".01",
						},
					}}
				/>
				<p>{t("defectInspection.additional")}</p>
				<LabeledInput
					areSeparated
					value={ruleValues.min_defect_size}
					inputStyles={{ width: "235px" }}
					onChange={defectSizeHandler("min_defect_size")}
					label={`${t("min_defect_size")}:`}
					adornment={<p>{t("measureUnits.mm")}</p>}
				/>
				<LabeledInput
					areSeparated
					value={ruleValues.min_pit_depth_rough_mm}
					inputStyles={{ width: "235px" }}
					onChange={defectSizeHandler("min_pit_depth_rough_mm")}
					label={`${t("min_pit_depth_rough_mm")}:`}
					adornment={<p>{t("measureUnits.mm")}</p>}
				/>
				<LabeledInput
					areSeparated
					value={ruleValues.allowable_defect_number}
					inputStyles={{ width: "235px" }}
					onChange={allowedAmountHandler}
					label={`${t("allowable_defect_number")}:`}
				/>
				<ButtonWrapper>
					<Button sx={{ color: "red" }} onClick={onClose}>
						{t("cancel")}
					</Button>
					<Button
						disabled={
							selectedColor === selectedForm ||
							!Object.keys(ruleValues).every((field) =>
								String(ruleValues[field])
							)
						}
						onClick={handleSubmit}
					>
						{isEditing
							? t("newReport.apply")
							: t("color.modal_add_btn")}
					</Button>
				</ButtonWrapper>
			</Content>
		</DialogWrapper>
	);
};
