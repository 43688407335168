import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

export const getChartByScheme = createAsyncThunk(
	"clicked/getChartByClick",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return (
					await api(`/api/pallets/${id}/scheme`, {
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					})
				).chart;
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getReportChartByScheme = createAsyncThunk(
	"clicked/getReportChartByScheme",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/pallets/${id}/scheme`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const clickedSlice = createSlice({
	name: "clicked",
	initialState: {
		id: null,
		chart: null,
		status: null,
		error: null,
		reportChart: null,
	},
	reducers: {
		clearAllClickedData(state, action) {
			state.chart = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getChartByScheme.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getChartByScheme.fulfilled, (state, action) => {
				state.status = "resolved";
				state.chart = action.payload;
			})
			.addCase(getChartByScheme.rejected, setError)
			.addCase(getReportChartByScheme.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(getReportChartByScheme.fulfilled, (state, action) => {
				state.status = "resolved";
				state.reportChart = action.payload;
			})
			.addCase(getReportChartByScheme.rejected, setError);
	},
});

export const { clearAllClickedData } = clickedSlice.actions;

export default clickedSlice.reducer;
