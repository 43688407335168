import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { DialogWrapper } from "./DialogWrapper";
import { BatchCorrectionForm } from "./BatchCorrectionForm";
import { useDispatch, useSelector } from "react-redux";
import { changeNewBatchInfo } from "../store/opPanelSlice";
import { useTranslation } from "react-i18next";
import ComboBox from "./ComboBox";

const NotificationWrapper = styled.div`
	width: 100%;
	height: 0;
	transition-duration: 150ms;
	transition-timing-function: ease-in;
	position: relative;
	z-index: 0;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

const Content = styled.div`
	padding: 12px;
	color: white;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	gap: 0.5rem;
`;

const SelectWrapper = styled.div`
	width: 180px;
	margin-left: 6px;
	background-color: #ffffff5e;
	border-radius: 3px 3px 0 0;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

const SelectsHolder = styled.p`
	display: flex;
	margin-left: 5px;
	align-items: center;
`;

const Notification = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 140px;
	background-color: #53c553;
	border-radius: 12px;
	bottom: 0;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const NewBatchNotification = ({ isVisible = true }) => {
	const { newBatchFlag, possibleMolds, possibleColors } = useSelector(
		(state) => state.opPanel
	);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedMold, setSelectedMold] = useState(possibleMolds[0]);
	const [selectedColor, setSelectedColor] = useState(possibleColors[0]);
	const content = useRef(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<NotificationWrapper
			style={{
				height:
					newBatchFlag && isVisible
						? content.current?.clientHeight + "px"
						: "0px",
			}}
		>
			<Notification>
				<Content ref={content}>
					<TextWrapper>
						{t("newBatch")}
						<SelectsHolder>
							{`${t("panel.dataList.form")} - `}
							<SelectWrapper>
								<ComboBox
									variant="standard"
									size="small"
									setValue={setSelectedMold}
									options={possibleMolds}
									value={selectedMold}
								/>
							</SelectWrapper>
							{`, ${t("report.table.color")} - `}
							<SelectWrapper>
								<ComboBox
									variant="standard"
									size="small"
									setValue={setSelectedColor}
									options={possibleColors}
									value={selectedColor}
								/>
							</SelectWrapper>
						</SelectsHolder>
						{t(`finishInNTacts`)}
					</TextWrapper>
					<ButtonsWrapper>
						<Button
							onClick={() =>
								dispatch(
									changeNewBatchInfo({
										decision: true,
										product_id: selectedMold?.id,
										color_id: selectedColor?.id,
									})
								)
							}
						>
							{t("applyBatchChanges")}
						</Button>
						<Button
							sx={{ color: "#ff0000" }}
							onClick={() =>
								dispatch(
									changeNewBatchInfo({ decision: false })
								)
							}
						>
							{t("cancelBatchChanges")}
						</Button>
					</ButtonsWrapper>
				</Content>
			</Notification>
			<DialogWrapper open={isOpen} onClose={() => setIsOpen(false)}>
				<BatchCorrectionForm setIsOpen={setIsOpen} />
			</DialogWrapper>
		</NotificationWrapper>
	);
};
