import styled from "styled-components";

const Hint = styled.div`
	position: relative;
	width: 25px;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: gray;
	font-weight: 300;
`;

const HintPopup = styled.div`
	position: absolute;
	top: 100%;
	display: ${(props) => (props.isVisible ? "flex" : "none")};
	background-color: white;
	border-radius: 3px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
	white-space: nowrap;
	padding: 4px;
	color: black;
	font-weight: 300;
`;

export const ColumnHint = ({
	onMouseEnter,
	onMouseLeave,
	isVisible,
	hintText,
}) => {
	return (
		<Hint onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			?<HintPopup isVisible={isVisible}>{hintText}</HintPopup>
		</Hint>
	);
};
