import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import StatChart from "./StatChart";
import StatFormChart from "./StatFormChart";
import StatHeights from "./StatHeights";
import StatTable from "./StatTable";

const PageWrapper = styled.div`
	height: 90vh;
	margin-left: 2%;
`;

const ChartWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 40px;
	margin-right: 2%;
`;

const TableWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StatPage = ({ index }) => {
	const { clicked } = useSelector((state) => state.stats);

	console.log("clicked", clicked);

	return (
		<PageWrapper>
			<ChartWrapper>
				<StatChart index={index} />
				<StatHeights />
			</ChartWrapper>
			<TableWrap>
				<StatTable />
				{clicked && <StatFormChart />}
			</TableWrap>
		</PageWrapper>
	);
};

export default StatPage;
