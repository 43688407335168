import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CircleLoader from "../UI/CircleLoader";
import { numberFormatter } from "../../helper/numberFormatter";

const Content = styled.div`
	padding: 6px 4px 16px;
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	letter-spacing: 0.04em;
`;

const DateWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const TimeLabel = styled.p`
	line-height: 30px;
	color: #2F3449;
	letter-spacing: 0.04em;
	font-size: 12px;
`;

const TimeInfo = styled.p`
	white-space: nowrap;
	color: #2F3449;
	letter-spacing: 0.04em;
	font-size: 32px;
	line-height: 32px;
	font-weight: 500;
`;

const TimeInfoUnit = styled.span`
	font-size: 20px;
	line-height: 22px;
	letter-spacing: 0.04em;
`;

export const CycleTimeInfo = ({ palletId, tick_time }) => {
	const { t } = useTranslation();

	const [time, date] = useMemo(() => {
		if (palletId) {
			const dateTime = palletId.split("_");
			return [
				dateTime.at(1).replace(/\./g, ":"),
				dateTime.at(0).slice(2).split("-").reverse().join("/"),
			];
		}
		return ["-", ""];
	}, [palletId]);

	return (
		<Content>
			<DateWrapper>
				<TimeLabel>{time}</TimeLabel>
				<TimeLabel>{date}</TimeLabel>
			</DateWrapper>
			<TimeInfo>
				{tick_time === undefined ? (
					<CircleLoader />
				) : (
					<>
						{numberFormatter(tick_time)}{" "}
						<TimeInfoUnit>{t("measureUnits.sec")}</TimeInfoUnit>
					</>
				)}
			</TimeInfo>
		</Content>
	);
};
