import React from "react";
import styled from "styled-components";
import SettingsCard from "../components/settings/SettingsCard";

const Wrapper = styled.div`
	width: 100%;
	height: max-content;
`;

const Settings = () => {
	return (
		<Wrapper>
			<SettingsCard />
		</Wrapper>
	);
};

export default Settings;
