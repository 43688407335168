import { TextField } from "@mui/material";

export const FilterInput = ({ filter, filters, handleChange }) => {
	return (
		<TextField
			sx={{
				marginLeft: "0.5rem",
				width: "10rem",
			}}
			variant="standard"
			onChange={(event) =>
				handleChange(
					filter,
					filters[filter].inputHandler(event.target.value)
				)
			}
			InputProps={{
				endAdornment: filters[filter].adornment ?? null,
			}}
			value={filters[filter].value}
		/>
	);
};
