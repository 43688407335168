import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";

const OldPass = ({ old, setPassData, passData }) => {
	const [pass, setPass] = React.useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const { t } = useTranslation();

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handlePassChange = (e) => {
		setPass(e.target.value);
		setPassData({ ...passData, old: e.target.value });
	};

	return (
		<FormControl sx={{ width: "25ch" }} variant="outlined">
			<InputLabel htmlFor="outlined-adornment-password">
				{t("profile.old_pass")}
			</InputLabel>
			<OutlinedInput
				id="outlined-adornment-password"
				type={showPassword ? "text" : "password"}
				value={pass}
				onChange={handlePassChange}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label={t("profile.old_pass")}
			/>
		</FormControl>
	);
};

export default OldPass;
