import React from "react";
import { Route, Routes } from "react-router-dom";
import StatLinks from "../components/statistic/StatLinks";
import StatPage from "../components/statistic/StatPage";
import Support from "./Support";

const Statistic = () => {
	return (
		<div>
			<StatLinks />
			<Routes>
				<Route path="feb" element={<StatPage index={"feb"} />} />
				<Route path="mar" element={<StatPage index={"mar"} />} />
			</Routes>
			<Support />
		</div>
	);
};

export default Statistic;
