import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { ReactComponent as GraphLong } from "../../icons/graphLong.svg";
import { ReactComponent as GraphShort } from "../../icons/graphShort.svg";
import { ReactComponent as GraphLast } from "../../icons/graphLast.svg";
import { ReactComponent as DropDownIcon } from "../../icons/dropDownIcon.svg";
import { useEffect } from "react";
import { useCallback } from "react";

const Wrapper = styled.div`
	position: relative;
	border-radius: 4px;
	fill: inherit; 
	:hover {
		& .styledLink {
			background-color: #ebeff5;
		}
	} 
	:active {
		& .styledLink {
			color: #4357A0;
			fill: #4357A0;
			background-color: #dbe2f1;
		}
	}
`;

const StyledLink = styled(NavLink).attrs({
	activeClassName: "active",
})`
	padding: 0 5px;
	color: #5A76A3;
	fill: #5A76A3;
	text-decoration: none;
	border-radius: 4px;
	display: flex;
	gap: 10px;
	align-items: center;

	${(props) =>
		props.isNotClickable
			? `
	cursor: default;
	:active {
		color: #5A76A3 !important;
		fill: #5A76A3 !important;
		background-color: #ebeff5 !important;
	};
			`
			: `
	${props.isOpen ? "" : ":hover {"}
		background-color: #ebeff5;
	${props.isOpen ? "" : "}"}

	:active {
		color: #4357A0;
		fill: #4357A0;
		background-color: #dbe2f1;
	}`}
	
	&.active {
		color: #4357A0;
		fill: #4357A0;
		background-color: #dbe2f1 !important;
	}
`;

const SubLinkList = styled.div`
	position: ${(props) => (props.isFull ? "static" : "absolute")};
	padding: ${(props) => (props.isFull ? "5px 0 0 23px" : "5px 5px 8px")};
	top: 0;
	left: calc(100% + 2px);
	box-shadow: ${(props) => (props.isFull ? "none" : "0 2px 6px #393D4C40")};
	background: #f7f9ff;
	border-radius: 4px;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	gap: 2px;
	transition-duration: 0ms;
`;

const SubLink = styled(NavLink).attrs({
	activeClassName: "active",
})`
	padding: 7px 10px;
	color: #5A76A3;
	text-decoration: none;
	border-radius: 4px;
	display: flex;
	gap: 10px;
	align-items: center;

	:hover {
		background-color: #ebeff5;
	}

	&.active {
		color: #4357A0;
		background-color: #dbe2f1;
	}

	:active {
		color: #4357A0;
		background-color: #dbe2f1;
	}
`;

const SubLinkWrapper = styled.div`
	padding-left: ${(props) => (props.isFull ? "22px" : "0")};
	position: relative;
`;

const GraphTreeWrapper = styled.div`
	position: absolute;
	height: min-content;
	left: 0;
	bottom: 3px;
`;

const LinkText = styled.p`
	color: inherit;
	white-space: nowrap;
	padding-right: 10px;
`;

const IconWrapper = styled.div`position: relative; fill: inherit;`;

const StyledDropDownIcon = styled(DropDownIcon)`
	position: absolute; 
	fill: inherit;
	right: 0;
	bottom: 3px;
	transform: rotate(${(props) => (props.isOpen ? 180 : 0)}deg);
`;

export const NavMenuLink = ({
	link,
	label,
	icon,
	subLinks,
	isFull,
	closeOnClick,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const linkElement = useOnClickOutside(
		useCallback(() => {
			if (!isFull) setIsOpen(false);
		}, [isFull])
	);

	useEffect(() => {
		if (isFull) setIsOpen(false);
	}, [isFull]);

	const handleClick = (event) => {
		if (subLinks && subLinks?.length) {
			event.preventDefault();
			if (!isFull) setIsOpen((prev) => !prev);
		} else {
			setIsOpen(false);
			closeOnClick();
		}
	};

	return (
		<Wrapper ref={linkElement}>
			<StyledLink
				className="styledLink"
				isNotClickable={subLinks && subLinks?.length && isFull}
				isOpen={isOpen}
				onClick={handleClick}
				to={link}
			>
				<IconWrapper style={{ height: "40px" }}>
					{icon}
					{subLinks && subLinks?.length && !isFull ? (
						<StyledDropDownIcon isOpen={isOpen} />
					) : null}
				</IconWrapper>
				{isFull && <LinkText>{label}</LinkText>}
			</StyledLink>
			{(isOpen || isFull) && subLinks && subLinks?.length ? (
				<SubLinkList isFull={isFull}>
					{subLinks.map((subLink, index) => (
						<SubLinkWrapper isFull={isFull} key={subLink.link}>
							{isFull ? (
								<GraphTreeWrapper>
									{index === 0 ? (
										<GraphShort />
									) : index === subLinks?.length - 1 ? (
										<GraphLast />
									) : (
										<GraphLong />
									)}
								</GraphTreeWrapper>
							) : null}
							<SubLink
								onClick={() => {
									setIsOpen(false);
									closeOnClick(closeOnClick);
								}}
								to={subLink.link}
							>
								<LinkText>{subLink.label}</LinkText>
							</SubLink>
						</SubLinkWrapper>
					))}
				</SubLinkList>
			) : null}
		</Wrapper>
	);
};
