import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSeriesByDay } from "../../store/palletSlice";
import ReportCalendar from "../report/ReportCalendar";
import BatchImages from "./BatchImages";
import SeriesList from "./SeriesList";

const AddBatch = () => {
	const { seriesByDay } = useSelector((state) => state.pallet);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [dispatchedDay, setDispatchedDay] = useState(new Date());

	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		const localISOTime = new Date(dispatchedDay.getTime() - tzoffset)
			.toISOString()
			.substring(0, 10);
		dispatch(
			getSeriesByDay({
				begin: localISOTime,
				end: localISOTime,
			})
		);
	}, []);

	const onCalendarClick = () => {
		setOpenCalendar(false);
	};

	return (
		<Box display={"flex"} flexDirection="row" pt="60px">
			<Box>
				<Typography fontSize={"18px"} fontWeight={700}>
					{t("color.add_serie")}
				</Typography>
				<Box>
					{openCalendar ? (
						<Box>
							<ReportCalendar
								onClick={onCalendarClick}
								setDay={setDispatchedDay}
							/>
						</Box>
					) : (
						<Typography
							sx={{
								color: "#4357A0",
								textDecoration: "underline",
							}}
							onClick={() => setOpenCalendar(true)}
						>
							{dispatchedDay
								.toISOString()
								.substring(0, 10)
								.split("-")
								.reverse()
								.join(".")}
						</Typography>
					)}
				</Box>
				<SeriesList seriesByDay={seriesByDay} />
			</Box>
			<Divider orientation="vertical" />
			<Box display={"flex"} ml="55px" flexDirection="column">
				<Typography fontSize={"22px"} fontWeight={600}>
					{t("color.add_change")}
				</Typography>
				<BatchImages />
			</Box>
		</Box>
	);
};

export default AddBatch;
