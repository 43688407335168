import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";

const Text = styled.p`
	display: flex;
	gap: 0.5rem;
`;

const Value = styled.p`
	font-weight: 600;
`;

export const BatchPopup = ({ product_name, color_name, total, bdt, edt }) => {
	const { t, i18n } = useTranslation();
	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	};

	return (
		<div>
			<div>
				<Text>
					{t(`bdt`)}:
					<Value>
						{setTimeZoneOffset(
							new Date(bdt),
							bdt?.slice(-6, -3)
						).toLocaleDateString(
							`${
								i18n.resolvedLanguage
							}-${i18n.resolvedLanguage.toUpperCase()}`,
							options
						) +
							" " +
							bdt?.slice(11, 16)}
					</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`edt`)}:
					<Value>
						{setTimeZoneOffset(
							new Date(edt),
							edt?.slice(-6, -3)
						).toLocaleDateString(
							`${
								i18n.resolvedLanguage
							}-${i18n.resolvedLanguage.toUpperCase()}`,
							options
						) +
							" " +
							edt?.slice(11, 16)}
					</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`panel.dataList.form`)}
					<Value>{product_name}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`panel.dataList.color`)}
					<Value>{color_name}</Value>
				</Text>
			</div>
			<div>
				<Text>
					{t(`report.table.rfidAll`)}:<Value>{total}</Value>
				</Text>
			</div>
		</div>
	);
};
