import React, { useEffect } from "react";
import styled from "styled-components";
import {
	Chart as ChartJS,
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getMonthStats } from "../../store/statsSlice";

ChartJS.register(
	LinearScale,
	CategoryScale,
	BarElement,
	PointElement,
	LineElement,
	Legend,
	Tooltip,
	LineController,
	BarController
);

const Wrapper = styled.div`
	width: 1400px;
	height: 440px;
`;

const StatChart = ({ index }) => {
	const { stats } = useSelector((state) => state.stats);

	const dispatch = useDispatch();

	useEffect(() => {
		if (index === "feb") {
			dispatch(getMonthStats({ begin: "2023-02-01", end: "2023-02-28" }));
		}
	}, [index]);

	useEffect(() => {
		if (index === "mar") {
			dispatch(getMonthStats({ begin: "2023-03-01", end: "2023-03-31" }));
		}
	}, [index]);

	if (!stats) return <></>;

	if (index === "feb") {
		const rebuildStats = stats.map((array) => {
			const allTicks = [];
			array.map((item) => allTicks.push(item.ticks));
			const tickValue = allTicks.reduce((acc, prev) => acc + prev, 0);

			return { day: array[0].day, ticks: tickValue };
		});

		const febLabels = Array.from({ length: 28 }, (_, i) => {
			if (i > 8) return { day: `2023-02-${i + 1}`, ticks: null };
			return { day: `2023-02-0${i + 1}`, ticks: null };
		});

		const options = {
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
				legend: {
					display: false,
					position: "top",
				},
				title: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			scales: {},
		};

		const ids = new Set(rebuildStats.map((d) => d.day));
		const merged = [
			...rebuildStats,
			...febLabels.filter((d) => !ids.has(d.day)),
		].sort((a, b) => a.day.localeCompare(b.day));

		const data = {
			labels: merged.map((item) => item.day),
			datasets: [
				{
					type: "line",
					label: "Dataset 1",
					borderColor: "#0C9C23",
					borderWidth: 2,
					fill: false,
					data: merged.map((item) => item.ticks),
				},
				{
					type: "bar",
					label: "Dataset 2",
					backgroundColor: "#4357A1",
					data: merged.map((item) => item.ticks),
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};

		return (
			<Wrapper>
				<Chart
					type="bar"
					options={options}
					data={data}
					width={"1400px"}
					height={"440px"}
				/>
			</Wrapper>
		);
	}

	if (index === "mar") {
		const rebuildStats = stats.map((array) => {
			const allTicks = [];
			array.map((item) => allTicks.push(item.ticks));
			const tickValue = allTicks.reduce((acc, prev) => acc + prev, 0);

			return { day: array[0].day, ticks: tickValue };
		});

		const oddLabels = Array.from({ length: 31 }, (_, i) => {
			if (index === "mar") {
				if (i > 8) return { day: `2023-03-${i + 1}`, ticks: null };
				return { day: `2023-03-0${i + 1}`, ticks: null };
			}
		});

		const options = {
			responsive: true,
			maintainAspectRatio: true,
			plugins: {
				legend: {
					display: false,
					position: "top",
				},
				title: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
			},
			scales: {},
		};

		const ids = new Set(rebuildStats.map((d) => d.day));
		const merged = [
			...rebuildStats,
			...oddLabels.filter((d) => !ids.has(d.day)),
		].sort((a, b) => a.day.localeCompare(b.day));

		const data = {
			labels: merged.map((item) => item.day),
			datasets: [
				{
					type: "line",
					label: "Dataset 1",
					borderColor: "#0C9C23",
					borderWidth: 2,
					fill: false,
					data: merged.map((item) => item.ticks),
				},
				{
					type: "bar",
					label: "Dataset 2",
					backgroundColor: "#4357A1",
					data: merged.map((item) => item.ticks),
					borderColor: "white",
					borderWidth: 2,
				},
			],
		};

		return (
			<Wrapper>
				<Chart
					type="bar"
					options={options}
					data={data}
					width={"1400px"}
					height={"440px"}
				/>
			</Wrapper>
		);
	}
};

export default StatChart;
