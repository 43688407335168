import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import ColorCheckbox from "../color/ColorCheckbox";
import ColorDropList from "../color/ColorDropList";
import ColorTypeSwitch from "../color/ColorTypeSwitch";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewColor, deleteColor, editColor } from "../../store/colorSlice";

const AddColorChild = ({ color, setColor }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Box>
				<TextField
					fullWidth
					value={color.name}
					onChange={(e) => {
						if (e.target.value.includes("/")) return;
						setColor({ ...color, name: e.target.value });
					}}
					label={t("color.color_name")}
					variant="standard"
				/>
				<DialogContentText>
					<Box mt="2rem">
						<ColorDropList
							brightness={color.brightness}
							setting={color}
							setSetting={setColor}
						/>
					</Box>
					<Box mt="2rem">
						<Typography fontSize={"18px"}>
							{t("color.type")}
						</Typography>
						<ColorCheckbox
							color_type={color.color_type}
							setting={color}
							setSetting={setColor}
						/>
					</Box>
					<Box
						mt="2rem"
						display={"flex"}
						flexDirection="column"
						gap="10px"
					>
						<Typography fontSize={"18px"}>
							{t("color.type")}
						</Typography>
						<ColorTypeSwitch
							surface_type={color.surface_type}
							setting={color}
							setSetting={setColor}
						/>
					</Box>
				</DialogContentText>
			</Box>
		</Box>
	);
};

const ModalWindow = ({
	opened,
	onClose,
	children,
	add,
	color_id,
	edit,
	remove,
}) => {
	const { colorList } = useSelector((state) => state.color);
	const filterColor = {
		...colorList?.filter((item) => item.uuid === color_id),
	}[0];

	const [color, setColor] = useState(
		filterColor ?? {
			name: "",
			crack_threshold: 0.85,
			pit_threshold: 0.85,
			stain_threshold: 0.85,
			destruction_threshold: 0.5,
			brightness: "normal",
			color_type: "mono",
			surface_type: "smooth",
		}
	);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleClick = () => {
		if (add) {
			dispatch(addNewColor(color));
		} else if (edit) {
			dispatch(editColor({ color_id, color }));
		} else if (color_id && remove) {
			dispatch(deleteColor(color_id));
		}
		onClose();
		if (add || remove) navigate("/settings/color");
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Box>
			<Dialog
				open={opened}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{add
						? t("color.modal_add")
						: edit
						? t("color.modal_edit")
						: t("color.modal_delete")}
				</DialogTitle>
				<DialogContent>
					{add || edit ? (
						<AddColorChild color={color} setColor={setColor} />
					) : (
						children
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("cancel")}</Button>
					<Button onClick={handleClick} autoFocus>
						{add
							? t("color.modal_add_btn")
							: edit
							? t("color.modal_edit_btn")
							: t("color.modal_delete_btn")}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ModalWindow;
