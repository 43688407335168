import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "../UI/CircleLoader";
import { pushUUid, removeUUid } from "../../store/colorSlice";
import { getZippedPalletImage } from "../../store/palletSlice";
import AddImageDialog from "../UI/AddImageDialog";

const Image = styled.div`
	width: 288px;
	height: 228px;
	margin-right: 18px;
`;

const ImageTitle = styled.div`
	font-size: 16px;
`;

const ImageItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ColorImageLoader = ({
	uuid,
	dt,
	palletUUid,
	openHandler,
	colorName,
	batch,
}) => {
	const { zippedImagesStatus, zippedImage } = useSelector(
		(state) => state.pallet
	);
	const { imageUUidArr } = useSelector((state) => state.color);

	const [blur, setBlur] = useState(true);
	const [clicked, setClicked] = useState(false);
	const [open, setOpen] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (uuid) {
			dispatch(getZippedPalletImage(uuid));
		}
	}, [uuid]);

	const imageClickHandler = (e) => {
		setClicked(!clicked);
		dispatch(pushUUid(uuid));
		if (imageUUidArr?.includes(uuid)) {
			dispatch(removeUUid(uuid));
		}
		if (e.detail === 2) {
			setOpen(true);
		}
	};

	const filterById = zippedImage.filter((item) => item.id === uuid);

	return (
		<ImageItem key={uuid}>
			<ImageTitle>{colorName}</ImageTitle>
			<Image
				onClick={openHandler}
				style={{
					border: clicked ? "4px solid #7CC6B6" : "none",
				}}
			>
				<img
					loading="lazy"
					src={{ ...filterById }[0]?.blob}
					style={{
						filter: blur ? "blur(20px)" : "none",
						transition: blur ? "none" : "filter 0.3s ease-out",
					}}
					onLoad={() => setBlur(false)}
					onClick={imageClickHandler}
				/>
			</Image>
			{batch && (
				<AddImageDialog
					opened={open}
					onClose={() => setOpen(false)}
					UUid={{ ...filterById }[0]?.id}
				/>
			)}
		</ImageItem>
	);
};

export default ColorImageLoader;
