import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Button,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { createNewUser } from "../store/userSlice";

const Dialog = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 320px;
`;

export const AddNewUserDialog = ({ closeDialog, setSnackbarState }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [name, setName] = useState("");
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState("operator");

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const validate = () => {
		if (name === "") {
			return [false, t("setting.users.nameEmpty")];
		}
		if (login === "") {
			return [false, t("setting.users.loginEmpty")];
		}
		if (role === "") {
			return [false, t("setting.users.roleEmpty")];
		}
		if (password === "") {
			return [false, t("setting.users.passwordEmpty")];
		}
		return [true];
	};

	const handleCreateUser = () => {
		const validationResults = validate();
		if (!validationResults[0]) {
			setSnackbarState({
				show: true,
				success: validationResults[0],
				msg: validationResults[1],
			});
			return;
		}
		dispatch(
			createNewUser({
				login,
				name,
				role,
				password,
			})
		).then(() => {
			setSnackbarState({
				show: true,
				success: true,
				msg: t("settings.users.addSuccess"),
			});
		});
		closeDialog();
	};

	return (
		<Dialog>
			<div>
				<p>{t("settings.users.userName")}</p>
				<TextField
					value={name}
					onChange={(e) => setName(e.target.value)}
					fullWidth
				/>
			</div>

			<div>
				<p>{t("settings.users.login")}</p>
				<TextField
					value={login}
					onChange={(e) => setLogin(e.target.value)}
					fullWidth
				/>
			</div>

			<div>
				<p>{t("settings.users.role")}</p>
				<Select
					value={role}
					onChange={(e) => setRole(e.target.value)}
					fullWidth
				>
					<MenuItem value={"operator"}>{t("operator")}</MenuItem>
					{/* <MenuItem value={"moderator"}>{t("moderator")}</MenuItem> */}
					<MenuItem value={"admin"}>{t("admin")}</MenuItem>
				</Select>
			</div>

			<div>
				<p>{t("settings.users.password")}</p>
				<TextField
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									edge="end"
								>
									{showPassword ? (
										<VisibilityOff />
									) : (
										<Visibility />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>

			<div>
				<Button onClick={handleCreateUser}>
					{t("settings.users.create")}
				</Button>
			</div>
		</Dialog>
	);
};
