import "react-notion/src/styles.css";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { NotionRenderer } from "react-notion";

const NotionOperScr = ({ pageID }) => {
	const [block, setBlock] = useState("");
	//8bbc2738546f444c958c8c60dc1f9321
	useEffect(() => {
		if (pageID) {
			const fetchData = async () => {
				const response = await fetch(
					`https://notion-api.splitbee.io/v1/page/${pageID}`
				).then((res) => res.json());
				setBlock(response);
			};
			fetchData();
		}
	}, [pageID]);

	return (
		<div
			style={{
				maxWidth: "1200px",
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<NotionRenderer blockMap={block} />
		</div>
	);
};

export default NotionOperScr;
