import styled from "styled-components";
import CircleLoader from "../UI/CircleLoader";

const Divider = styled.div`
	width: 2px;
	min-width: 2px;
	height: 100%;
	border-radius: 2px;
	background: #DBE2F1;
`;

const Content = styled.div`
	display: flex;
	gap: 4px;
	height: 100%;
	width: 100%;
`;

const InfoBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	letter-spacing: 0.04em;
	width: 100%;
	gap: 10px;
	padding: 6px 4px 16px;
	border-radius: 4px;
	${(props) => {
		switch (props.status) {
			case "ok": {
				return `
					color: #2F3449;
				`;
			}
			case "allowed": {
				return `
					color: #2F3449;
					background: #E6DA00
				`;
			}
			case "restricted": {
				return `
					color: #fff;
					background: #FB564D
				`;
			}
			default: {
				return `
					color: #2F3449;
				`;
			}
		}
	}}
`;

const Label = styled.p`
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.04em;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
`;

const Info = styled.p`
	font-size: 32px;
	letter-spacing: 0.04em;
	font-weight: 500;
	line-height: 32px;
	white-space: nowrap;
`;

export const DataItemInfo = ({ infoBlocks }) => {
	return (
		<Content>
			{infoBlocks.flatMap((infoBlock, index) => [
				index === 0 ? null : <Divider />,
				<InfoBlock status={infoBlock.status}>
					<Label>{infoBlock.label}</Label>
					{infoBlock.info === undefined ? (
						<CircleLoader />
					) : (
						<Info>{infoBlock.info ?? "-"}</Info>
					)}
				</InfoBlock>,
			])}
		</Content>
	);
};
