import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

export const DefectInspectionControls = ({
	deleteRule,
	editRule,
	data,
	...rule
}) => {
	return (
		<Wrapper onClick={(event) => event.stopPropagation()}>
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					editRule(rule);
				}}
			>
				<EditIcon />
			</IconButton>
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					deleteRule(rule);
				}}
			>
				<DeleteIcon />
			</IconButton>
		</Wrapper>
	);
};
