import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getMonthStats = createAsyncThunk(
	"stats/getMonthStats",
	async function ({ begin, end }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/stats?d1=${begin}&d2=${end}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const statsSlice = createSlice({
	name: "stats",
	initialState: {
		status: null,
		stats: null,
		clicked: false,
	},
	reducers: {
		setClicked: (state, action) => {
			if (action.payload) {
				state.clicked = true;
			}
		},
	},
	extraReducers: {
		[getMonthStats.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getMonthStats.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				const sortedStats = [...action.payload].sort((a, b) =>
					a.day.localeCompare(b.day, undefined, { numeric: true })
				);

				const composed = sortedStats
					.map((d) => {
						return [
							{ ...d },
							...sortedStats.filter(({ day }) => d.day === day),
						];
					})
					.filter(
						(value, index, self) =>
							index ===
							self.findIndex((t) => t[0].day === value[0].day)
					);
				state.stats = composed;
			}
		},
		[getMonthStats.rejected]: setError,
	},
});

export const { setClicked } = statsSlice.actions;

export default statsSlice.reducer;
