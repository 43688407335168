import React, { useState } from "react";
import styled from "styled-components";
import AlertDialog from "./UI/AlertDialog";

const SvgWrapper = styled.svg.attrs({
	xmlns: "http://www.w3.org/2000/svg",
	viewBox: "0 0 1400 1100",
})`
	width: 100%;
	aspect-ratio: 14/11;
`;

export const PalletViewDefectInspection = ({
	image,
	scheme,
	handleMaskClick,
	isSectorLoaded,
	imageCrop,
	areHeightsHidden,
	positiveDecisionHandler,
	negativeDecisionHandler,
	decisionBySector,
}) => {
	const [isOpened, setIsOpened] = useState(false);
	const [sectorId, setSectorId] = useState(false);

	return (
		<>
			<SvgWrapper>
				<image href={image} />
				{scheme?.scheme?.sectors.map((mask) =>
					mask.grid && mask.grid?.length > 1
						? mask.grid.map((grid) => (
								<g key={grid.points}>
									{!areHeightsHidden ? (
										<>
											<text
												filter="url(#rounded-corners)"
												fill={
													grid.heights_repeat_count
														? "red"
														: "#fff"
												}
												fontSize={18}
												textAnchor={"middle"}
												alignmentBaseline={"middle"}
												stroke="black"
												strokeWidth={0.2}
												x={Number(
													grid.center_text_x_y
														.split(" ")[0]
														?.slice(2)
												)}
												y={Number(
													grid.center_text_x_y
														.split(" ")[1]
														?.slice(2)
												)}
											>
												{grid.height.toFixed(1)}
											</text>
											<polygon
												fill="#00000000"
												stroke={"#FFFFFF"}
												strokeWidth={"0.5px"}
												points={grid.points}
											/>
										</>
									) : null}
								</g>
						  ))
						: null
				)}
				{scheme.content === 0 ? (
					scheme?.scheme?.defect_stones?.length > 0 ? (
						scheme?.scheme.defect_stones.map((stone) => (
							<g key={stone?.id}>
								<polygon
									id={`${stone?.id}`}
									fill="#00000000"
									stroke={"#FFF85C"}
									strokeWidth={"5px"}
									points={stone?.points}
								/>
							</g>
						))
					) : (
						<></>
					)
				) : (
					<></>
				)}
				{scheme.content === 0 ? (
					scheme?.scheme?.repeated_defect_stones?.length > 0 ? (
						scheme?.scheme.repeated_defect_stones.map((stone) => (
							<g key={stone?.id}>
								<polygon
									id={`${stone?.id}`}
									fill="#00000000"
									stroke={"#873FA0"}
									strokeWidth={"5px"}
									points={stone?.points}
								/>
							</g>
						))
					) : (
						<></>
					)
				) : (
					<></>
				)}
				{scheme.content === 0 ? (
					scheme?.scheme?.repeated_height_stones?.length > 0 ? (
						scheme?.scheme.repeated_height_stones.map((stone) => (
							<g key={stone?.id}>
								<polygon
									id={`${stone?.id}`}
									fill="#00000000"
									stroke={"#873FA0"}
									strokeWidth={"5px"}
									points={stone?.points}
								/>
							</g>
						))
					) : (
						<></>
					)
				) : (
					<></>
				)}
				{scheme?.scheme?.sectors?.map((mask) => (
					<g
						key={mask?.id}
						onClick={() => {
							setSectorId(mask?.id);
							handleMaskClick(mask?.crop, mask?.id);
							setIsOpened(true);
						}}
					>
						<filter
							id="rounded-corners"
							x="-5%"
							width="110%"
							y="0%"
							height="100%"
						>
							<feFlood floodColor="#353535" />
							<feGaussianBlur stdDeviation="2" />
							<feComponentTransfer>
								<feFuncA type="table" tableValues="0 0 0 1" />
							</feComponentTransfer>

							<feComponentTransfer>
								<feFuncA
									type="table"
									tableValues="0 1 1 1 1 1 1 1"
								/>
							</feComponentTransfer>
							<feComposite operator="over" in="SourceGraphic" />
						</filter>
						<filter
							id="blueBackground"
							x="-30%"
							width="160%"
							y="-5%"
							height="120%"
						>
							<feFlood floodColor="blue" />
							<feComposite operator="over" in="SourceGraphic" />
						</filter>
						{!(mask.grid && mask.grid?.length > 1) &&
						!areHeightsHidden ? (
							<text
								filter="url(#rounded-corners)"
								fill={
									mask?.heights_repeat_count
										? "#ff0000"
										: "#fff"
								}
								fontSize={24}
								textAnchor={"middle"}
								alignmentBaseline={"middle"}
								stroke="black"
								strokeWidth={0.2}
								x={Number(
									mask?.center_text_x_y
										?.split(" ")[0]
										?.slice(2)
								)}
								y={Number(
									mask?.center_text_x_y
										?.split(" ")[1]
										?.slice(2)
								)}
							>
								{mask?.value}
							</text>
						) : null}
						{mask?.n_bboxes ? (
							<text
								filter="url(#blueBackground)"
								fill={"#fff"}
								fontSize={24}
								textAnchor={"middle"}
								alignmentBaseline={"middle"}
								stroke="black"
								strokeWidth={0.2}
								x={mask?.points
									?.split(",")
									?.reduce((res, coords) => {
										const coord = Number(
											coords.split(" ")[0]
										);
										return res > coord ? coord : res;
									}, Infinity)}
								y={
									mask?.points
										?.split(",")
										?.reduce((res, coords) => {
											const coord = Number(
												coords.split(" ")[1]
											);
											return res > coord ? coord : res;
										}, Infinity) + 19
								}
							>
								{mask?.n_bboxes}
							</text>
						) : null}

						<polygon
							id={`${mask?.id}`}
							fill="#00000000"
							stroke={
								mask?.user_decision === false
									? "green"
									: mask?.user_decision === true
									? "red"
									: mask?.user_decision === null
									? "0"
									: "0"
							}
							strokeWidth={
								mask?.user_decision !== null ? "10px" : "0px"
							}
							strokeDasharray={
								mask?.user_decision !== null ? "10" : ""
							}
							points={mask.points}
						/>
					</g>
				))}
			</SvgWrapper>
			<AlertDialog
				opened={isOpened}
				isSectorLoaded={isSectorLoaded}
				onClose={() => setIsOpened(false)}
				sectorId={sectorId}
				positiveDecisionHandler={positiveDecisionHandler(sectorId)}
				negativeDecisionHandler={negativeDecisionHandler(sectorId)}
				decision={decisionBySector}
				sectorImgCrop={imageCrop}
			/>
		</>
	);
};
