import {
	Checkbox,
	FormControlLabel,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const urlSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
	"#fff"
)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>`;

const changeSvg = `<svg xmlns="http://www.w3.org/2000/svg width="200" height="200" viewBox="0 0 1024 1024""><path fill="${encodeURIComponent(
	"#050D42"
)}" d="M832 384l8 1.6-1.6 8 1.6 3.2-4.8 3.2-44.8 161.6-16-4.8 40-147.2-260.8 144-158.4 284.8-11.2-6.4-6.4 6.4-176-176 11.2-11.2 163.2 163.2 147.2-265.6-294.4-297.6 11.2-11.2v-8h9.6l3.2-3.2 3.2 3.2L664 208l1.6 16-395.2 22.4 278.4 278.4 276.8-153.6 6.4 12.8z" /><path fill="${encodeURIComponent(
	"#fff"
)}" d="M896 384c0 35.2-28.8 64-64 64s-64-28.8-64-64 28.8-64 64-64 64 28.8 64 64z m-656-32c-62.4 0-112-49.6-112-112s49.6-112 112-112 112 49.6 112 112-49.6 112-112 112z m304 336c-80 0-144-64-144-144s64-144 144-144 144 64 144 144-64 144-144 144z m-224 144c0-35.2 28.8-64 64-64s64 28.8 64 64-28.8 64-64 64-64-28.8-64-64z m-144-176c0-17.6 14.4-32 32-32s32 14.4 32 32-14.4 32-32 32-32-14.4-32-32z m448-440c0-22.4 17.6-40 40-40s40 17.6 40 40-17.6 40-40 40-40-17.6-40-40zM736 560c0-27.2 20.8-48 48-48s48 20.8 48 48-20.8 48-48 48-48-20.8-48-48z" /></svg>`;

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	"width": 62,
	"height": 34,
	"padding": 7,
	"& .MuiSwitch-switchBase": {
		"margin": 1,
		"padding": 0,
		"top": "3.5px",
		"transform": "translateX(6px)",
		"&.Mui-checked": {
			"color": "#fff",
			"transform": "translateX(29px)",
			"& .MuiSwitch-thumb:before": {},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor:
					theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		"backgroundColor":
			theme.palette.mode === "dark" ? "#003892" : "#001e3c",
		"width": 25,
		"height": 25,
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
		borderRadius: 20 / 2,
	},
}));

const ColorCheckbox = ({ color_type, setting, setSetting }) => {
	const [checked, setChecked] = React.useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		if (color_type === "mono") {
			setChecked(false);
		} else if (color_type === "colormix") {
			setChecked(true);
		}
	}, [color_type]);

	const handleChange = (event) => {
		setChecked(event.target.checked);
		if (event.target.checked === false) {
			setSetting({ ...setting, color_type: "mono" });
		} else if (event.target.checked) {
			setSetting({ ...setting, color_type: "colormix" });
		}
	};

	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Typography>{t("color.default_mono")}</Typography>
			<MaterialUISwitch checked={checked} onChange={handleChange} />
			<Typography>{t("color.default_colormix")}</Typography>
		</Stack>
	);
};

export default ColorCheckbox;
