import {
	Button,
	FormControl,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { Table } from "./Table";
import { useTranslation } from "react-i18next";
import { DialogWrapper } from "./DialogWrapper";
import { LocalizedDatePicker } from "./DatePicker";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
	clearState,
	getAccuracyChecks,
	getBatchesByDate,
	setTestDate,
	startNewCheck,
} from "../store/defectsInspectionSlice";
import { useNavigate } from "react-router-dom";
import { useDataTableFormatter } from "../hooks/useDataTableFormatter";
import { ThresholdResults } from "./ThresholdResults";
import { ColumnHint } from "./ColumnHint";

const Header = styled.div`
	display: flex;
	gap: 4rem;
	align-items: center;
`;

const HeaderText = styled.p`
	font-weight: bold;
	font-size: 22px;
`;

const Content = styled.div`
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Dialog = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 520px;
`;

const DefectsInspectionPage = () => {
	const { t } = useTranslation();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isPrecisionHintVisible, setIsPrecisionHintVisible] = useState(false);
	const [isRecallHintVisible, setIsRecallHintVisible] = useState(false);
	const [date, setDate] = useState(null);
	const isFirst = useRef(true);
	const [selectedBatchUuid, setSelectedBatchUuid] = useState("");
	const [palletsNumber, setPalletsNumber] = useState(200);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { previousInspections, possibleBatches, pallet } = useSelector(
		(state) => state.defectInspection
	);

	const {
		dateFormatter,
		someOfTotalFormatter,
		customContentFormatter,
		percentFormatter,
	} = useDataTableFormatter();

	const columns = [
		{
			name: "creation_dt",
			formatter: dateFormatter,
		},
		{
			name: "batchDate",
			formatter: dateFormatter,
		},
		{
			name: "batchName",
		},
		{
			name: "n_pallets_checked",
			formatter: someOfTotalFormatter("n_pallets_all"),
		},
		{
			name: "precision",
			formatter: percentFormatter,
			additionalHeaderContent: (
				<ColumnHint
					onMouseEnter={() => setIsPrecisionHintVisible(true)}
					onMouseLeave={() => setIsPrecisionHintVisible(false)}
					isVisible={isPrecisionHintVisible}
					hintText={
						<p>
							<b>{t("precision")}</b>{" "}
							{t("defectInspection.precisionHint")}
						</p>
					}
				/>
			),
		},
		{
			name: "recall",
			formatter: percentFormatter,
			additionalHeaderContent: (
				<ColumnHint
					onMouseEnter={() => setIsRecallHintVisible(true)}
					onMouseLeave={() => setIsRecallHintVisible(false)}
					isVisible={isRecallHintVisible}
					hintText={
						<p>
							<b>{t("recall")}</b>{" "}
							{t("defectInspection.recallHint")}
						</p>
					}
				/>
			),
		},
		{
			name: "userName",
		},
		{
			name: "result",
			formatter: customContentFormatter(ThresholdResults),
		},
	];

	const handleDateChange = (date) => {
		dispatch(getBatchesByDate({ date: date.format().slice(0, 10) }));
		setDate(date);
	};

	const handlePalletsNumber = (event) => {
		if (Number(event.target.value) !== palletsNumber)
			setPalletsNumber(Number(event.target.value));
	};

	const handleTestBegin = () => {
		dispatch(setTestDate(date.format().slice(0, 10)));
		dispatch(
			startNewCheck({
				batchId: selectedBatchUuid,
				palletsLimit: palletsNumber,
			})
		);
	};

	useEffect(() => {
		dispatch(clearState());
		dispatch(getAccuracyChecks());
	}, []);

	useEffect(() => {
		if (possibleBatches[0]) {
			setSelectedBatchUuid(possibleBatches[0].batch_id);
		}
	}, [possibleBatches]);

	useEffect(() => {
		if (isFirst.current) {
			isFirst.current = false;
		} else {
			if (pallet?.uuid) {
				navigate(`/settings/sense/test/${pallet.uuid}`);
			} else {
				navigate("/settings/sense/test/");
			}
		}
	}, [pallet?.uuid, navigate]);

	return (
		<Content>
			<Header>
				<HeaderText>
					{t("defectInspection.inspectionResults")}
				</HeaderText>
				<Button
					sx={{ fontSize: "17px" }}
					onClick={() => setIsDialogOpen(true)}
				>
					{t("defectInspection.newCheck")}
				</Button>
			</Header>
			<Table
				emptyString={""}
				isClickable={true}
				dataIdField={"uuid"}
				data={previousInspections}
				columns={columns}
			/>
			<DialogWrapper
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
			>
				<Dialog>
					<div>
						<p>{t("defectInspection.moldingDate")}</p>
						<LocalizedDatePicker
							sx={{ width: "100%" }}
							value={date}
							setValue={handleDateChange}
						/>
					</div>
					<div>
						<p>{t("defectInspection.batchName")}</p>
						<FormControl
							sx={{ width: "100%" }}
							disabled={!possibleBatches.length}
						>
							<Select
								sx={{ width: "100%" }}
								value={selectedBatchUuid}
								onChange={(event) => {
									setSelectedBatchUuid(event.target.value);
								}}
							>
								{possibleBatches.map((batch) => (
									<MenuItem
										value={batch.batch_id}
										key={batch.batch_id}
									>{`${batch.product_name} - ${
										batch.color_name
									} ${batch.bdt.slice(
										11,
										16
									)} - ${batch.edt.slice(11, 16)}`}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div>
						<p>{t("defectInspection.palletsAmountToCheck")}</p>
						<OutlinedInput
							sx={{ width: "100%" }}
							value={palletsNumber}
							onChange={handlePalletsNumber}
						/>
					</div>
					<div>
						<Button
							onClick={handleTestBegin}
							disabled={
								!(selectedBatchUuid !== "" && palletsNumber)
							}
						>
							{t("defectInspection.begin")}
						</Button>
					</div>
				</Dialog>
			</DialogWrapper>
		</Content>
	);
};

export default DefectsInspectionPage;
