import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";

const Group = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const CheckboxGroup = ({ checkboxes, handleChange }) => {
	return (
		<Group>
			{checkboxes.map((checkbox, index) => (
				<FormControlLabel
					key={checkbox.label}
					label={checkbox.label}
					control={
						<Checkbox
							onClick={() => handleChange(index)}
							checked={checkbox.checked}
						/>
					}
				/>
			))}
		</Group>
	);
};
