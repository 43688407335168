import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function TrainingCheckbox({
	training,
	setTraining,
	setEndMode,
}) {
	const handleChange = (event) => {
		setTraining(event.target.checked);
		if (event.target.checked == false) {
			setEndMode(true);
		}
	};

	return (
		<Checkbox
			checked={training}
			onChange={handleChange}
			inputProps={{ "aria-label": "controlled" }}
		/>
	);
}
