import React, { useState } from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";

import CurrentDescription from "./CurrentDescription";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getColorList, getFormList } from "../store/colorSlice";
import ColorSelect from "./UI/ColorSelect";
import { Box, Button, Typography } from "@mui/material";
import TrainingCheckbox from "./UI/TrainingCheckbox";
import { getSystem } from "../store/settingsSlice";
import FormSearchSelect from "./UI/FormSearchSelect";
import ChangeFormDialog from "./UI/ChangeFormDialog";
import FormSnackbar from "./UI/FormSnackbar";
import TrainingSnackbar from "./UI/TrainingSnackbar";
import TrainingEndDialog from "./UI/TrainingEndDialog";
import { NewBatchNotification } from "./NewBatchNotification";

const BatchWrapper = styled.div`
	width: 100%;
	z-index: 1;
	padding: 12px 12px 12px 12px;
	border-radius: 12px;
	position: relative;
	background-color: white;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const Wrapper = styled.div`
	z-index: 10;
`;

const TitleWrapper = styled.div`
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	padding-bottom: 6px;
	display: flex;
	flex-direction: row;
`;

const DivWrapper = styled.div`
	gap: 1rem;
	flex-wrap: wrap;
	display: flex;
	flex-direction: ${(props) => (props.row ? "row" : "column")};
`;

const CurrentBatch = ({ loaded, batch }) => {
	const { colorList, formList } = useSelector((state) => state.color);
	const { newBatchFlag } = useSelector((state) => state.opPanel);
	let { system } = useSelector((state) => state.settings);

	const [colorBase, setColorBase] = useState("");
	const [formBase, setFormBase] = useState("");
	const [training, setTraining] = useState(false);
	const [openForm, setOpenForm] = useState(false);
	const [snackbar, setSnackbar] = useState(false);
	const [trainSnack, setTrainSnack] = useState(false);
	const [endMode, setEndMode] = useState(false);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(getColorList());
		dispatch(getFormList());
		dispatch(getSystem());
	}, []);

	useEffect(() => {
		setTraining(system?.learning_mode);
	}, [system]);

	useEffect(() => {
		if (system?.learning_context) {
			if (colorList?.length > 0) {
				setColorBase(
					colorList?.filter(
						(color) =>
							color.uuid === system?.learning_context.color_id
					)[0]
				);
			}
			if (formList?.length > 0) {
				setFormBase(
					formList?.filter(
						(form) =>
							form.uuid === system?.learning_context.product_id
					)
				);
			}
		}
	}, [system?.learning_context, colorList, formList]);

	const defectPercent = `${(
		(batch
			? batch[0]?.bad_defect?.toFixed(2) / batch[0]?.overall
			: null
		)?.toFixed(3) * 100
	)?.toFixed(1)}%`;

	const goodPercent = `${(
		100 -
		(batch
			? batch[0]?.bad_defect?.toFixed(2) / batch[0]?.overall
			: null
		)?.toFixed(3) *
			100
	)?.toFixed(1)}%`;

	const filteredColor = colorList?.filter(
		(color) => color.uuid === colorBase?.uuid
	);

	const unitedFormList = formList?.map((form) => {
		const uuid = form.uuid;
		const formName = form.name;
		return {
			uuid: uuid,
			formName: formName,
		};
	});

	const filteredForm = unitedFormList?.filter(
		(form) => form.uuid === { ...formBase }[0]?.uuid
	);

	return (
		<Wrapper>
			<BatchWrapper>
				<Box
					display={"flex"}
					flexDirection={"row"}
					justifyContent="flex-start"
					alignItems="center"
				>
					<TitleWrapper>{t("panel.dataList.current")} </TitleWrapper>
					<Box
						display={"flex"}
						flexDirection={"row"}
						alignItems="center"
						sx={{ mb: "7px" }}
					>
						<TrainingCheckbox
							setEndMode={setEndMode}
							training={training}
							setTraining={setTraining}
						/>
						<Typography>{t("learning.mode")}</Typography>
					</Box>
				</Box>

				<DivWrapper>
					<DivWrapper
						style={{
							justifyContent: training
								? "space-between"
								: "flex-start",
							alignItems: "center",
						}}
						row
					>
						{training ? (
							<FormSearchSelect
								nameLayout={t("learning.form")}
								setDefaultForm={setFormBase}
								formList={unitedFormList}
								defaultForm={{ ...formBase }[0]?.name}
							/>
						) : (
							<CurrentDescription
								title={t("panel.dataList.form")}
								info={batch ? batch[0]?.product_name : null}
								pr
								loaded={loaded && batch}
							/>
						)}

						{training ? (
							<ColorSelect
								setDefaultColor={setColorBase}
								colorList={colorList}
								defaultColor={colorBase?.uuid}
							/>
						) : (
							<CurrentDescription
								title={t("panel.dataList.color")}
								info={batch ? batch[0]?.color_name : null}
								pr
								loaded={batch && loaded}
							/>
						)}
						{training && (
							<Button
								sx={{
									"padding": "0px 20px 0px 20px",
									"color": "white",
									"backgroundColor": "#4357A1",
									"borderRadius": "20px",
									"height": "3rem",
									"border": "1px solid #4357A1",
									"&:hover": {
										color: "#4357A1",
										border: "1px solid #4357A1",
									},
								}}
								onClick={() => setOpenForm(true)}
							>
								{t("learning.yesMode")}
							</Button>
						)}
						<ChangeFormDialog
							snackbar={snackbar}
							setSnackbar={setSnackbar}
							training={training}
							form={
								filteredForm?.length > 0
									? { ...filteredForm }[0]?.uuid
									: { ...formBase }[0]?.uuid
							}
							color={
								filteredColor?.length > 0
									? { ...filteredColor }[0]?.uuid
									: colorBase?.uuid
							}
							formName={
								filteredForm?.length > 0
									? { ...filteredForm }[0]?.formName
									: { ...formBase }[0]?.name
							}
							colorName={
								filteredColor?.length > 0
									? { ...filteredColor }[0]?.name
									: colorBase?.name
							}
							open={openForm}
							onClose={() => setOpenForm(false)}
						/>
						<TrainingEndDialog
							form={
								filteredForm?.length > 0
									? { ...filteredForm }[0]?.uuid
									: { ...formBase }[0]?.uuid
							}
							color={
								filteredColor?.length > 0
									? { ...filteredColor }[0]?.uuid
									: colorBase?.uuid
							}
							open={endMode}
							onClose={() => setEndMode(false)}
							setTrainSnack={setTrainSnack}
							training={training}
							setTraining={setTraining}
						/>
					</DivWrapper>
					{batch && (
						<DivWrapper
							row
							style={{
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									gap: "1rem",
								}}
							>
								<CurrentDescription
									unitType={batch[0]?.unit_type}
									overall={batch[0]?.overall}
									title={t("panel.dataList.tacts")}
									info={batch[0]?.total}
									loaded={loaded}
								/>
								<CurrentDescription
									unitType={batch[0]?.unit_type}
									title={t("panel.dataList.valid")}
									info={
										<>
											{(
												batch[0]?.overall -
												batch[0]?.bad_defect
											)?.toFixed(
												batch[0]?.unit_type === "m2"
													? 1
													: 0
											)}
											<Typography marginX={"0.2rem"}>
												{t(
													batch[0]?.unit_type === "m2"
														? "measureUnits.m2"
														: "measureUnits.pcs"
												)}
											</Typography>
											{` / ${
												goodPercent === `${NaN}%`
													? "-"
													: goodPercent
											}`}
										</>
									}
									width
									loaded={loaded}
								/>
								<CurrentDescription
									title={t("panel.dataList.fool")}
									info={
										<>
											{batch[0]?.bad_defect?.toFixed(
												batch[0]?.unit_type === "m2"
													? 1
													: 0
											)}
											<Typography marginX={"0.2rem"}>
												{t(
													batch[0]?.unit_type === "m2"
														? "measureUnits.m2"
														: "measureUnits.pcs"
												)}
											</Typography>
											{` / ${
												defectPercent === `${NaN}%`
													? "-"
													: defectPercent
											}`}
										</>
									}
									width
									loaded={loaded}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									gap: "1rem",
								}}
							>
								<CurrentDescription
									title={t("panel.dataList.avg")}
									info={
										batch[0] ? (
											<>
												{batch[0]?.avg?.toFixed(1)}
												<Typography
													sx={{
														marginLeft: "0.2rem",
													}}
												>
													{t("measureUnits.mm")}
												</Typography>
											</>
										) : (
											"-"
										)
									}
									width
									loaded={loaded}
								/>
								<CurrentDescription
									title={t("panel.dataList.avgD")}
									info={
										batch[0]?.avg_density ? (
											<>
												{system?.density_measure_unit ===
												"kg_dm3"
													? (
															batch[0]
																?.avg_density /
															1000
													  )?.toFixed(2)
													: batch[0]?.avg_density?.toFixed(
															0
													  )}
												<Typography
													sx={{
														marginLeft: "0.2rem",
													}}
												>
													{t(
														system?.density_measure_unit ===
															"kg_dm3"
															? "measureUnits.kg/dm"
															: "measureUnits.kg/m"
													)}
												</Typography>
											</>
										) : (
											"-"
										)
									}
									width
									loaded={loaded}
								/>
							</Box>
						</DivWrapper>
					)}
				</DivWrapper>
				<FormSnackbar open={snackbar} setOpen={setSnackbar} />
				<TrainingSnackbar open={trainSnack} setOpen={setTrainSnack} />
			</BatchWrapper>
			{newBatchFlag && <NewBatchNotification isVisible={!training} />}
		</Wrapper>
	);
};

export default CurrentBatch;
