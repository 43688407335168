import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { addLogs, changeSystem } from "../../store/settingsSlice";
import { useTranslation } from "react-i18next";
import { fetchSystem } from "../../helper/fetchSystem";

export default function ChangeFormDialog({
	open,
	onClose,
	form,
	formName,
	colorName,
	color,
	training,
	snackbar,
	setSnackbar,
}) {
	let { system } = useSelector((state) => state.settings);
	const { users } = useSelector((state) => state.user);
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const yesClickHandler = async () => {
		const currentSystem = await fetchSystem();
		const settingsState = {
			...currentSystem,
			learning_mode: training,
			learning_context: {
				product_id: form && form,
				color_id: color && color,
			},
		};
		dispatch(changeSystem({ settingsState: settingsState }));
		onClose();
		setSnackbar(true);
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t("learning.mode")}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{`${t("learning.yesForm")} - ${formName} ${t(
						"learning.yesColor"
					)}- ${colorName}?`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button sx={{ color: "green" }} onClick={yesClickHandler}>
					{t("learning.yes")}
				</Button>
				<Button sx={{ color: "red" }} onClick={handleClose} autoFocus>
					{t("learning.no")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
