import styled from "@emotion/styled";
import { useEffect, useMemo, useRef, useState } from "react";

const TimeLineElement = styled.div`
	background-color: ${(props) => props.displayColor};
	height: 5px;
	opacity: 0.5;
	width: ${(props) => `${props.width}%`};
	margin-left: ${(props) => `${props.margin}%`};
	transition-duration: 150ms;
`;

const TimeLineBody = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
	width: 100%;
`;

const TimeLineWrapper = styled.div`
	display: flex;
	height: 100%;
	overflow-x: auto;
	overflow-y: visible;
	width: 100%;
`;

const Content = styled.div`
	position: relative;
	height: 20px;
`;

const SelectionArea = styled.div`
	position: absolute;
	height: 20px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.1);
`;

export const TimeLineLocationBar = ({ data, begin, end }) => {
	const body = useRef(null);
	const selectionArea = useRef(null);
	const [params, setParams] = useState({
		start: null,
		end: null,
		duration: null,
	});

	const getOffsetFromTime = (time) => {
		const offset =
			(body.current.clientWidth / params?.duration) *
			(time - params?.start);
		return offset;
	};

	useEffect(() => {
		console.log(new Date(begin), new Date(end));
		const startOffset = getOffsetFromTime(begin);
		const endOffset = getOffsetFromTime(end);
		selectionArea.current.style.left = `${
			endOffset - startOffset > 5 ? startOffset : startOffset - 2.5
		}px`;
		selectionArea.current.style.width = `${
			endOffset - startOffset > 5 ? endOffset - startOffset : 5
		}px`;
	}, [begin, end, params]);

	useEffect(() => {
		if (data.length) {
			setParams({
				start: data[0].begin,
				end: data[data.length - 1].end,
				duration: data[data.length - 1].end - data[0].begin,
			});
		}
	}, [data]);

	const timeLineElements = useMemo(() => {
		if (params.duration === null) return null;
		return data.map((entry) => {
			return (
				<TimeLineElement
					key={entry.uuid}
					displayColor={entry.displayColor}
					width={((entry.end - entry.begin) / params.duration) * 100}
					margin={(entry.marginMS / params.duration) * 100}
				/>
			);
		});
	}, [params]);

	return (
		<Content ref={body}>
			<TimeLineWrapper>
				{data.length ? (
					<>
						<TimeLineBody>{timeLineElements}</TimeLineBody>
					</>
				) : null}
			</TimeLineWrapper>
			<SelectionArea ref={selectionArea} />
		</Content>
	);
};
