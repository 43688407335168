import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TrainingSnackbar({ open, setOpen }) {
	const { t } = useTranslation();
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity="info"
					sx={{ width: "100%" }}
				>
					{t("color.disabled")}
				</Alert>
			</Snackbar>
		</Stack>
	);
}
