import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import CircleLoader from "../UI/CircleLoader";

const Image = styled.img`
	width: 280px;
	aspect-ratio: 14/11;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PlaceHolder = styled.div`
	width: 280px;
	aspect-ratio: 14/11;
	border: 1px solid #ccc;
	display: flex;
	justify-content: center;
	font-size: 24px;
	align-items: center;
`;

export const LayoutItem = ({ layout, preview, isLoading }) => {
	const { t } = useTranslation();

	return (
		<Container>
			{preview ? (
				<Image src={preview ?? null} />
			) : (
				<PlaceHolder>
					{isLoading ? <CircleLoader /> : t("noExamples")}
				</PlaceHolder>
			)}
			<Link
				to={layout.uuid}
				style={{
					width: "280px",
					textAlign: "center",
					fontSize: "24px",
				}}
			>
				{layout.name}
			</Link>
		</Container>
	);
};
