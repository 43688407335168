import {
	Button,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Switch,
} from "@mui/material";
import { PalletViewDefectInspection } from "./PalletViewDefectInspection";
import { useDispatch, useSelector } from "react-redux";
import BigCircleLoader from "./UI/BigCircleLoader";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	displayDefect,
	dotCloudDownload,
	getPalletInfo,
	getSectorInfo,
	setCounter,
	submitPalletCheck,
	submitPalletDecision,
} from "../store/defectsInspectionSlice";
import CircleLoader from "./UI/CircleLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { RadioButtonGroup } from "./RadioButtonGroup";
import { CheckboxGroup } from "./CheckboxGroup";

const Content = styled.div`
	display: flex;
	padding-top: 1rem;
	width: 100%;
	height: calc(100vh - 112.5px);
	flex-direction: column;
	gap: 1rem;
`;

const LoaderWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

const HeaderText = styled.div`
	font-weight: bold;
	font-size: 22px;
`;

const PalletWrapper = styled.div`
	aspect-ratio: 14/11;
	height: 100%;
`;

const PalletPanel = styled.div`
	aspect-ratio: 14/11;
	max-height: calc(100% - (15px + 2rem));
`;

const ControlsPanel = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 320px;
`;

const ControlsContainer = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
`;

const DownloadLink = styled.button`
	text-decoration: underline;
	font-size: 20px;
	color: black;
	cursor: pointer;
	background-color: transparent;
	border: none;
	&:hover {
		color: #1976d2;
	}
	&:active {
		color: #002ba1;
	}
	&:disabled {
		color: rgba(0, 0, 0, 0.8)
	}
	&:disabled &:hover {
		color: rgba(0, 0, 0, 0.8)
	}
	&:disabled &:active {
		color: rgba(0, 0, 0, 0.8)
	}
`;

const Controls = styled.div`
	display: flex;
	gap: 2rem;
	flex-direction: column;
`;

const LinkWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	height: 32px;
	width: 276px;
`;

const Header = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const AnalysisForm = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Heading = styled.p`
	font-size: 20px;
`;

const PaddingWrapper = styled.div`
	padding-left: 1.5rem;
`;

export const DefectInspectionTest = () => {
	const {
		pallet,
		scheme,
		image,
		limit,
		date,
		palletInfoStatus,
		sectorStatus,
		currentNumber,
		cloudStatus,
		imageCrop,
		currentCheckSession,
		currentPalletDecision,
		decision,
	} = useSelector((state) => state.defectInspection);
	const { uuid } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const defaultCheckboxes = useMemo(
		() => [
			{
				label: `${t("color.missingStone")} (T)`,
				checked: false,
				value: 10,
			},
			{
				label: `${t("color.misplacedStone")} (Y)`,
				checked: false,
				value: 9,
			},
		],
		[t]
	);
	const [checkboxes, setCheckboxes] = useState(
		structuredClone(defaultCheckboxes).map((checkbox) => ({
			...checkbox,
			checked: Boolean(currentPalletDecision?.includes(checkbox.value)),
		}))
	);

	const [areHeightsHidden, setAreHeightsHidden] = useState(false);
	const falsePositive = useRef([]);
	const falseNegative = useRef([]);
	const [palletCategory, setPalletCategory] = useState(null);

	useEffect(() => {
		if (pallet?.uuid) {
			navigate(`/settings/sense/test/${pallet.uuid}`);
		} else {
			navigate("/settings/sense/test/");
		}
	}, [pallet?.uuid, navigate]);

	const handleMaskClick = (crop, sectorId) => {
		dispatch(
			getSectorInfo({
				palletId: pallet.uuid,
				crop: crop,
				sectorId: sectorId,
			})
		);
	};

	const palletCategories = [
		{
			value: 3,
			label: `${t(`defectInspection.drop`)} (2)`,
		},
		{
			value: 11,
			label: `${t(`defectInspection.empty`)} (3)`,
		},
		{
			value: 8,
			label: `${t(`defectInspection.anomaly`)} (4)`,
		},
		{
			value: 12,
			label: `${t(`defectInspection.segmentationTroubles`)} (5)`,
		},
	];

	const handleCheckboxChange = (index) => {
		setPalletCategory(1);
		setCheckboxes((prev) => {
			const temp = structuredClone(prev);
			temp[index].checked = !temp[index].checked;
			return temp;
		});
	};

	const handleChange = (event) => {
		if (palletCategory === 1) dropCheckboxes();
		setPalletCategory((prev) =>
			prev === event.target.value ? null : event.target.value
		);
	};

	const handleRadioChange = (value) => {
		dropCheckboxes();
		setPalletCategory(value);
	};

	const dropCheckboxes = useCallback(() => {
		setCheckboxes(structuredClone(defaultCheckboxes));
	}, [defaultCheckboxes]);

	const handleNext = useCallback(() => {
		let isLast = limit === currentNumber;
		dispatch(
			submitPalletCheck({
				sessionId: currentCheckSession,
				palletId: pallet.uuid,
				falsePositive: falsePositive.current,
				falseNegative: falseNegative.current,
				troubleClass: palletCategory,
				isLast: isLast,
			})
		);
		dispatch(
			submitPalletDecision({
				id: pallet.uuid,
				annotations: [
					...(palletCategory ? [parseInt(palletCategory)] : []),
					...checkboxes
						.filter((checkbox) => checkbox.checked)
						.map((checkbox) => parseInt(checkbox.value)),
				],
			})
		);
		dropCheckboxes();
		if (isLast) {
			navigate("/settings/sense/test/");
		}
	}, [
		currentCheckSession,
		currentNumber,
		dispatch,
		limit,
		navigate,
		dropCheckboxes,
		checkboxes,
		pallet?.uuid,
		palletCategory,
	]);

	const positiveDecisionHandler = (sectorId) => (defected) => {
		falsePositive.current = falsePositive.current.filter(
			(sector) => sector.id !== sectorId
		);
		falseNegative.current = [
			...falseNegative.current,
			{ id: sectorId, defect_classes: defected },
		];
		dispatch(displayDefect({ id: sectorId, decision: true }));
	};

	const negativeDecisionHandler = (sectorId) => () => {
		falseNegative.current = falseNegative.current.filter(
			(sector) => sector.id !== sectorId
		);
		falsePositive.current = [
			...falsePositive.current,
			{ id: sectorId, defect_classes: [] },
		];
		dispatch(displayDefect({ id: sectorId, decision: false }));
	};

	const handleHotkey = useCallback(
		(event) => {
			const handleRadio = (value) => (prev) =>
				prev === value ? null : value;
			switch (event.keyCode) {
				case 49: {
					if (palletCategory === 1) dropCheckboxes();
					setPalletCategory(handleRadio(1));
					break;
				}
				case 50: {
					dropCheckboxes();
					setPalletCategory(handleRadio(3));
					break;
				}
				case 51: {
					dropCheckboxes();
					setPalletCategory(handleRadio(11));
					break;
				}
				case 52: {
					dropCheckboxes();
					setPalletCategory(handleRadio(8));
					break;
				}
				case 53: {
					dropCheckboxes();
					setPalletCategory(handleRadio(12));
					break;
				}
				case 84: {
					handleCheckboxChange(0);
					break;
				}
				case 89: {
					handleCheckboxChange(1);
					break;
				}
				case 72: {
					setAreHeightsHidden((prev) => !prev);
					break;
				}
				case 13: {
					if (
						!(
							palletCategory === 1 &&
							!checkboxes.filter((checkbox) => checkbox.checked)
								.length
						)
					)
						handleNext();
					break;
				}
				default:
					break;
			}
		},
		[handleNext, palletCategory, checkboxes, dropCheckboxes]
	);

	useEffect(() => {
		document.documentElement.addEventListener("keydown", handleHotkey);
		return () =>
			document.documentElement.removeEventListener(
				"keydown",
				handleHotkey
			);
	}, [uuid, handleHotkey]);

	useEffect(() => {
		if (pallet) {
			dispatch(setCounter(currentNumber + 1));
			dispatch(getPalletInfo({ id: pallet.uuid }));
			setPalletCategory(null);
			falsePositive.current = [];
			falseNegative.current = [];
		} else {
			navigate("/settings/sense/test/");
		}
	}, [pallet, uuid, dispatch]);

	return (
		<Content>
			{pallet && scheme && palletInfoStatus === "fulfilled" ? (
				<>
					<Header>
						<ArrowBackIcon
							sx={{
								"cursor": "pointer",
								"transitionDuration": "100ms",
								"fontSize": "28px",
								"&:hover": {
									color: "rgba(0, 0, 0, 0.6)",
								},
								"&:active": {
									color: "rgba(0, 0, 0, 0.8)",
								},
							}}
							onClick={() => navigate("/settings/sense/test")}
						/>
						<HeaderText>
							{t(`defectInspection.checkFor`)}
							{` ${date} (${currentNumber} / ${limit})`}
						</HeaderText>
					</Header>
					<Wrapper>
						<PalletPanel>
							<PalletWrapper>
								<PalletViewDefectInspection
									image={image}
									scheme={scheme}
									areHeightsHidden={areHeightsHidden}
									handleMaskClick={handleMaskClick}
									isSectorLoaded={
										sectorStatus === "fulfilled"
									}
									imageCrop={imageCrop}
									positiveDecisionHandler={
										positiveDecisionHandler
									}
									negativeDecisionHandler={
										negativeDecisionHandler
									}
									decisionBySector={decision}
								/>
							</PalletWrapper>
						</PalletPanel>
						<ControlsPanel>
							<Controls>
								<LinkWrapper>
									<DownloadLink
										disabled={cloudStatus === "pending"}
										onClick={() =>
											dispatch(
												dotCloudDownload({
													palletId: pallet.uuid,
												})
											)
										}
									>
										{t(
											`defectInspection.pointsCloudDownload`
										)}
									</DownloadLink>
									{cloudStatus === "pending" && (
										<CircleLoader />
									)}
								</LinkWrapper>
								<ControlsContainer>
									<AnalysisForm>
										<Heading>
											{t(
												"defectInspection.analysisHeading"
											)}
										</Heading>
										<RadioGroup
											sx={{
												display: "flex",
												gap: "0.5rem",
											}}
											value={palletCategory}
										>
											<FormControlLabel
												label={`${t(
													"defectInspection.defects"
												)} (1)`}
												value={1}
												control={
													<Radio
														onClick={handleChange}
													/>
												}
											/>
										</RadioGroup>
										<PaddingWrapper>
											<CheckboxGroup
												handleChange={
													handleCheckboxChange
												}
												checkboxes={checkboxes}
											/>
										</PaddingWrapper>
										<RadioButtonGroup
											value={palletCategory}
											setValue={handleRadioChange}
											entries={palletCategories}
										/>
									</AnalysisForm>
									<FormControl>
										<FormControlLabel
											label={`${t(
												"defectInspection.hideHeights"
											)} (H)`}
											control={
												<Switch
													onChange={(event) => {
														setAreHeightsHidden(
															event.target.checked
														);
													}}
													checked={areHeightsHidden}
												/>
											}
										/>
									</FormControl>
								</ControlsContainer>
								<Button
									sx={{
										alignSelf: "center",
										fontSize: "16px",
									}}
									disabled={
										palletCategory === 1 &&
										!checkboxes.filter(
											(checkbox) => checkbox.checked
										).length
									}
									onClick={handleNext}
								>
									{`${
										limit === currentNumber
											? t(`defectInspection.done`)
											: t(`defectInspection.next`)
									} (Enter)`}
								</Button>
							</Controls>
						</ControlsPanel>
					</Wrapper>
				</>
			) : (
				<LoaderWrapper>
					<BigCircleLoader />
				</LoaderWrapper>
			)}
		</Content>
	);
};
