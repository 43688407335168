import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useDebounce } from "../../hooks/useDebounce";
import { useTranslation } from "react-i18next";

export default function UserField({ userName, userParams, setUserName }) {
	const [name, setName] = React.useState(userName);
	const { t } = useTranslation();
	const debounced = useDebounce(name, 500);

	React.useEffect(() => {
		setUserName({ ...userParams, name: debounced });
	}, [debounced]);

	const changeHandler = (e) => {
		setName(e.target.value);
	};

	return (
		<Box
			component="form"
			sx={{
				"& > :not(style)": { width: "25ch" },
			}}
			noValidate
			autoComplete="off"
		>
			<TextField
				id="outlined-controlled"
				label={t("profile.name")}
				value={name}
				onChange={changeHandler}
			/>
		</Box>
	);
}
