import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import requestWrapper from "../../helper/requestWrapper";
import { Button } from "@mui/material";
import { useCallback, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authLogout } from "../../store/authSlice";
import DefaultExampleZipImage from "../ImageExample";
import { LocalizedDateTimePicker } from "../DateTimePicker";
import CircleLoader from "../UI/CircleLoader";

const Wrapper = styled.div`
	padding: 2rem 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Heading = styled.p`
	font-size: 22px;
	font-weight: 600;
	font-family: "Roboto";
`;

const SubHeading = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-family: "Roboto";
`;

const DatePickersWrapper = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;
`;

const FilterWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const LoaderWrapper = styled.div`
	width: 2.5rem;
	height: 2.5rem;
`;

const Examples = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	width: 100%;
	overflow-x: ${(props) => (props.isLoaded ? "scroll" : "hidden")} ;
`;

export const PicturesSelectionForm = ({
	appendExample,
	removeExample,
	header,
	subHeader,
	datePickerLabel,
}) => {
	const { t } = useTranslation();
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const dispatch = useDispatch();
	const [isDone, setIsDone] = useState(false);
	const [examples, setExamples] = useState([]);
	const [latestDate, setLatestDate] = useState("");
	const observerTarget = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && examples.length && !isDone) {
					requestMorePictures();
				}
			},
			{ threshold: 1 }
		);

		if (observerTarget.current) {
			observer.observe(observerTarget.current);
		}

		return () => {
			if (observerTarget.current) {
				observer.unobserve(observerTarget.current);
			}
		};
	}, [observerTarget, examples]);

	useEffect(() => {
		requestInitialPictures();
	}, []);

	const requestInitialPictures = async () => {
		const fromQuery = from
			? `&dt_begin=${encodeURIComponent(from.format())}`
			: "";
		const toQuery = to ? `&dt_end=${encodeURIComponent(to.format())}` : "";
		const pallets = (
			await requestWrapper(() => {
				dispatch(authLogout());
			})(
				`/api/pallets?is_inspected=true&limit=21&desc=true${fromQuery}${toQuery}&join_form=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
		).map((el) => {
			return {
				ref: el.image_ref,
				pallet_id: el.uuid,
				dt: el.dt,
				formName: el.product?.name,
			};
		});

		setIsDone(false);

		setLatestDate(pallets[pallets.length - 1].dt);
		setExamples(pallets.slice(0, 20));
	};

	const requestMorePictures = useCallback(async () => {
		const fromQuery = from
			? `&dt_begin=${encodeURIComponent(from.format())}`
			: "";
		const pallets = (
			await requestWrapper(() => {
				dispatch(authLogout());
			})(
				`/api/pallets?is_inspected=true&limit=21&desc=true${fromQuery}&dt_end=${encodeURIComponent(
					latestDate
				)}&join_form=true`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
		).map((el) => {
			return {
				ref: el.image_ref,
				pallet_id: el.uuid,
				dt: el.dt,
				formName: el.product?.name,
			};
		});

		if (pallets.length < 21) {
			setIsDone(true);
		}

		setLatestDate(pallets[pallets.length - 1].dt);
		setExamples((prev) => [...prev, ...pallets.slice(0, 20)]);
	}, [latestDate]);

	return (
		<Wrapper>
			<div>
				<Heading>{header}</Heading>
				{subHeader ? <SubHeading>{subHeader}</SubHeading> : null}
			</div>
			<DatePickersWrapper>
				{datePickerLabel ? (
					<SubHeading>{datePickerLabel}</SubHeading>
				) : null}
				<FilterWrapper>
					<FilterWrapper>
						<p>{t(`from`)}:</p>
						<LocalizedDateTimePicker
							value={from}
							setValue={setFrom}
						/>
					</FilterWrapper>
					<FilterWrapper>
						<p>{t(`to`)}:</p>
						<LocalizedDateTimePicker value={to} setValue={setTo} />
					</FilterWrapper>
					<Button onClick={requestInitialPictures}>
						{t("profile.apply")}
					</Button>
				</FilterWrapper>
			</DatePickersWrapper>

			<Examples isLoaded={examples.length}>
				{examples.length
					? examples.map((example) => (
							<DefaultExampleZipImage
								key={example.pallet_id}
								imageRef={example}
								onSelect={() => {
									appendExample(example.pallet_id);
								}}
								onCancel={() => {
									removeExample(example.pallet_id);
								}}
							/>
					  ))
					: null}
				<LoaderWrapper ref={observerTarget}>
					{isDone ? null : <CircleLoader />}
				</LoaderWrapper>
			</Examples>
		</Wrapper>
	);
};
