import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const SerieBatch = ({ bdt, edt, product_name, color_name }) => {
	return (
		<Box
			sx={{
				width: "350px",
				height: "125px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				cursor: "pointer",
				gap: "8px",
				pl: "12px",
				backgroundColor: "none",
			}}
		>
			<Typography fontSize={"18px"}>
				{bdt?.split("T")[1].slice(0, 8)} -{" "}
				{edt?.split("T")[1].slice(0, 8)}
			</Typography>
			<Typography fontSize={"18px"}>{product_name}</Typography>
			<Typography fontSize={"18px"}>{color_name}</Typography>
		</Box>
	);
};

export default SerieBatch;
