import { Chart } from "react-chartjs-2";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
`;

const SelectWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const ChartWrapper = styled.div`
	height: 250px;
`;

export const TactCharts = ({ pallets }) => {
	const [timeScale, setTimeScale] = useState(60);

	const { t } = useTranslation();

	const chartConfigs = useMemo(() => {
		const numberOfUnits = Math.ceil(
			(new Date(pallets.at(-1).dt) - new Date(pallets.at(0).dt)) /
				(1000 * 60 * timeScale)
		);
		const deltaTime = timeScale * 1000 * 60;
		const timeUnits = new Array(numberOfUnits)
			.fill(0)
			.map(
				(_, index) =>
					new Date(
						+new Date(pallets.at(0).dt) + deltaTime * index >
						+new Date(pallets.at(-1).dt)
							? +new Date(pallets.at(-1).dt)
							: +new Date(pallets.at(0).dt) + deltaTime * index
					)
			);

		const labels = timeUnits.map((timeUnit) =>
			setTimeZoneOffset(
				timeUnit,
				pallets && pallets[0]?.dt?.slice(-6, -3)
			)
				.toTimeString()
				.slice(0, 8)
		);

		const [tactData, defectData, heightDeviationData] = [
			"count",
			"m2defect",
			"m2height",
		].map((field) => {
			const data = [0];
			const areaData = [0];
			let timeIndex = 0;
			pallets.forEach((pallet, index) => {
				while (+new Date(pallet.dt) >= timeUnits[timeIndex + 1]) {
					data.push(0);
					areaData.push(0);
					if (field !== "count")
						data[timeIndex] = Number(
							(
								(data[timeIndex] / areaData[timeIndex]) *
								100
							).toFixed(1)
						);
					timeIndex++;
				}
				if (field === "count") {
					data[timeIndex] += 1;
				} else {
					data[timeIndex] += pallet[field];
					areaData[timeIndex] += pallet.m2ovr;
				}
				if (field !== "count" && index === pallets.length - 1)
					data[timeIndex] = Number(
						((data[timeIndex] / areaData[timeIndex]) * 100).toFixed(
							1
						)
					);
			});
			return data;
		});
		return { tactData, defectData, heightDeviationData, labels };
	}, [pallets, timeScale]);

	const handleScaleChange = (event) => {
		setTimeScale(event.target.value);
	};

	const chartData = {
		labels: chartConfigs.labels,
		datasets: [
			{
				label: t("bad_defect"),
				data: chartConfigs.defectData,
				type: "line",
				borderColor: "#eb3636",
				backgroundColor: "#f5a19b",
				yAxisID: "y1",
			},
			{
				label: t("bad_height"),
				data: chartConfigs.heightDeviationData,
				type: "line",
				borderColor: "#ebca36",
				backgroundColor: "#f5dd9b",
				yAxisID: "y1",
			},
			{
				label: t("ticks"),
				data: chartConfigs.tactData,
				type: "bar",
				borderColor: "#36A2EB",
				backgroundColor: "#9BD0F5",
				yAxisID: "y",
			},
		],
	};

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				y: {
					type: "linear",
					display: true,
					position: "left",
				},
				y1: {
					type: "linear",
					display: true,
					position: "right",
					ticks: {
						callback: (value) => value + "%",
					},
				},
			},
			plugins: {
				tooltip: {
					callbacks: {
						label: (context) => {
							console.log(context);
							if (context.dataset.type === "line")
								return (
									context.dataset.label +
									": " +
									context.formattedValue +
									"%"
								);
						},
					},
				},
			},
		}),
		[]
	);

	return (
		<Content>
			<div>
				<SelectWrapper>
					<p>{t("report.scale")}:</p>
					<Select
						variant="standard"
						value={timeScale}
						onChange={handleScaleChange}
					>
						<MenuItem value={10}>
							{"10 " + t("report.minutes")}
						</MenuItem>
						<MenuItem value={30}>
							{"30 " + t("report.minutes")}
						</MenuItem>
						<MenuItem value={60}>
							{"60 " + t("report.minutes")}
						</MenuItem>
					</Select>
				</SelectWrapper>
			</div>
			<div>
				<ChartWrapper>
					<Chart options={options} data={chartData}></Chart>
				</ChartWrapper>
				<div></div>
			</div>
		</Content>
	);
};
