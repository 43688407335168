import { Dialog, DialogContent } from "@mui/material";

export const DialogWrapper = ({ open, onClose, children }) => {
	return (
		<Dialog
			PaperProps={{ sx: { overflow: "visible", maxWidth: "800px" } }}
			onClose={onClose}
			open={open}
		>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	);
};
