import { Switch } from "@mui/material";

export const FilterSwitch = ({ filter, filters, handleChange }) => {
	return (
		<Switch
			sx={{ marginLeft: "0.5rem" }}
			checked={filters[filter].value}
			onChange={() => handleChange(filter, !filters[filter].value)}
		/>
	);
};
