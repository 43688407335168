import { useCallback, useEffect, useMemo, useState } from "react";
import { ImageGallery } from "./ImageGallery";
import { ReportOperativeScreen } from "./ReportOperativeScreen";
import { useDispatch, useSelector } from "react-redux";
import { setDecisionBySector } from "../store/opPanelSlice";
import {
	getPalletInfo,
	getSectorInfo,
	updateAfterDecision,
} from "../store/newReportSlice";
import styled from "styled-components";
import { FilterList } from "./FilterList";
import { TactTimeLine } from "./TactTimeLine";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;

const TimeLineWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const ReportPalletScreen = ({
	pallets,
	filteredPallets,
	palletImages,
	selectedPalletIndex,
	setSelectedPalletIndex,
	updateAfterTagApply,
	apply,
	reset,
	filters,
	setFilters,
	Popup,
	handleClick,
	isLoaded,
	isFilteredOnly,
	setIsFilteredOnly,
}) => {
	const [startIndex, setStartIndex] = useState(0);
	const [imageCropCoords, setImageCropCoords] = useState(null);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const selectedDefects = [];
	["has_crack", "has_pit", "has_stain", "has_destruction"].forEach(
		(field, index) => {
			filters[field].value && selectedDefects.push(index);
		}
	);

	const handleIsFilteredSwitch = () => {
		setSelectedPalletIndex(0);
		setIsFilteredOnly((prev) => !prev);
	};

	const handledPallets = useMemo(
		() =>
			isFilteredOnly
				? pallets
						.filter((pallet) =>
							filteredPallets.includes(pallet?.uuid)
						)
						.map((pallet, index, processedPallets) => ({
							...pallet,
							marginMS:
								index === 0
									? +new Date(pallet.dt) - pallet.begin
									: +new Date(pallet.dt) -
									  new Date(processedPallets[index - 1].dt),
						}))
				: pallets,
		[pallets, filteredPallets, isFilteredOnly]
	);
	const handledImages = useMemo(
		() =>
			isFilteredOnly
				? palletImages.filter((image) =>
						filteredPallets.includes(image?.label)
				  )
				: palletImages,
		[palletImages, filteredPallets, isFilteredOnly]
	);

	const {
		decision,
		imageCrop,
		image,
		scheme,
		sectorStatus,
		palletInfoStatus,
	} = useSelector((state) => state.newReport);

	useEffect(() => {
		setStartIndex(
			selectedPalletIndex - 3 > 0
				? selectedPalletIndex + 4 > handledImages.length
					? handledImages.length - 7
					: selectedPalletIndex - 3
				: 0
		);
		dispatch(
			getPalletInfo({ id: handledPallets[selectedPalletIndex]?.uuid })
		);
	}, [selectedPalletIndex, pallets, isFilteredOnly]);

	const handleMaskClick = (maskCrop, sector_id) => {
		setImageCropCoords(maskCrop);
		dispatch(
			getSectorInfo({
				palletId: handledPallets[selectedPalletIndex].uuid,
				crop: maskCrop,
				sectorId: sector_id,
			})
		);
	};

	const positiveDecisionHandler = (palletId) => (sectorId) => (defected) => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: true,
					defect_classes: defected,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const negativeDecisionHandler = (palletId) => (sectorId) => () => {
		dispatch(
			setDecisionBySector({
				palletId,
				sectorId,
				decisionDetails: {
					decision: false,
				},
			})
		).then(() => {
			dispatch(updateAfterDecision({ palletId }));
		});
	};

	const galleryImageClickHandler = (image) => {
		setSelectedPalletIndex(
			handledPallets.findIndex((pallet) => image.uuid === pallet.uuid)
		);
	};

	const isNextAvailable = useMemo(
		() => selectedPalletIndex < handledPallets.length,
		[handledPallets, selectedPalletIndex]
	);

	const isPreviousAvailable = useMemo(
		() => selectedPalletIndex > 0,
		[selectedPalletIndex]
	);

	const handleNext = useCallback(() => {
		if (isNextAvailable) {
			setSelectedPalletIndex((prev) => prev + 1);
		}
	}, [isNextAvailable, selectedPalletIndex, dispatch, handledPallets]);

	const handlePrevious = useCallback(() => {
		if (isPreviousAvailable) {
			setSelectedPalletIndex((prev) => prev - 1);
		}
	}, [isPreviousAvailable, selectedPalletIndex, dispatch, handledPallets]);

	return (
		<Content>
			<FormControlLabel
				sx={{
					position: "absolute",
					left: "164px",
				}}
				label={t("report.onlyFiltered")}
				control={
					<Switch
						checked={isFilteredOnly}
						onChange={handleIsFilteredSwitch}
					/>
				}
			/>

			<TimeLineWrapper>
				<FilterList
					apply={apply}
					reset={reset}
					filters={filters}
					setFilters={setFilters}
				/>
				<TactTimeLine
					popupLocation="top"
					selectedUuid={handledPallets[selectedPalletIndex]?.uuid}
					Popup={Popup}
					handleClick={handleClick}
					isLoaded={isLoaded}
					data={handledPallets}
				/>
			</TimeLineWrapper>
			<ImageGallery
				imageClickHandler={galleryImageClickHandler}
				selectedImageUuid={handledImages[selectedPalletIndex]?.uuid}
				images={handledImages.slice(startIndex, startIndex + 7)}
			/>
			{scheme ? (
				<ReportOperativeScreen
					decision={decision}
					selectedDefects={selectedDefects}
					imageCrop={imageCrop}
					image={image}
					palletStatus={palletInfoStatus}
					scheme={scheme}
					sectorStatus={sectorStatus}
					pallet={handledPallets[selectedPalletIndex]}
					imageCropCoords={imageCropCoords}
					positiveDecisionHandler={positiveDecisionHandler}
					negativeDecisionHandler={negativeDecisionHandler}
					handleMaskClick={handleMaskClick}
					handleNext={handleNext}
					handlePrevious={handlePrevious}
					updateAfterTagApply={updateAfterTagApply}
					isNextAvailable={isNextAvailable}
					isPreviousAvailable={isPreviousAvailable}
				/>
			) : null}
		</Content>
	);
};
