import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function DefaultAlertDialog({
	open,
	onClose,
	onAccept,
	entity,
	onDecline = null,
}) {
	const { t } = useTranslation();

	const handleClose = () => {
		console.log("close");
		onClose();
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"></DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{`${t(
							"qpbc.entities.actions.delete-alert"
						)} - ${entity}?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button sx={{ color: "green" }} onClick={onAccept}>
						{t("learning.yes")}
					</Button>
					<Button
						sx={{ color: "red" }}
						onClick={onDecline === null ? onClose : onDecline}
						autoFocus
					>
						{t("learning.no")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
