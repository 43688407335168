import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getTokenChat = createAsyncThunk(
	"support/getTokenChat",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/rchat/token`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getAvailableChat = createAsyncThunk(
	"support/getAvailableChat",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/rchat/available`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const supportSlice = createSlice({
	name: "support",
	initialState: {
		token: null,
		available: null,
		status: null,
		error: null,
	},
	reducers: {
		dropAlert: (state) => {
			state.available = null;
		},
	},
	extraReducers: {
		[getTokenChat.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getTokenChat.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.token = action.payload;
		},
		[getTokenChat.rejected]: setError,

		[getAvailableChat.pending]: (state) => {
			state.status = "loading";
			state.error = null;
		},
		[getAvailableChat.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.available = action.payload.available;
		},
		[getAvailableChat.rejected]: setError,
	},
});

export const { dropAlert } = supportSlice.actions;

export default supportSlice.reducer;
