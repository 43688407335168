import styled from "@emotion/styled";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CircleLoader from "./UI/CircleLoader";
import { FilterSelect } from "./FilterSelect";
import { FilterInput } from "./FilterInput";
import { FilterSwitch } from "./FilterSwitch";
import { FilterMultiselect } from "./FilterMultiselect";

const Content = styled.div`
	display: flex;
	gap: 1.5rem;
	flex-wrap: wrap;
`;

const FilterCount = styled.p`
	border-radius: 100px;
	background-color: #1976d2;
	color: white;
	width: 1.5rem;
	aspect-ratio: 1;
`;

const SwitchFiltersWrapper = styled.div`
`;

const Filters = styled.div`
	transition-duration: 150ms;
	overflow: hidden;
`;

export const FilterList = ({ filters, setFilters, apply, reset }) => {
	const [areFiltersDisplayed, setAreFiltersDisplayed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const innerContent = useRef(null);
	const [height, setHeight] = useState(0);

	const handleChange = (filterName, value) => {
		if (value !== undefined)
			setFilters((prev) => ({
				...prev,
				[filterName]: {
					...prev[filterName],
					value,
				},
			}));
	};

	const buttonHandlerWrapper = (handler) => () => {
		setIsLoading(true);
		handler().then(() => setIsLoading(false));
	};

	return (
		<SwitchFiltersWrapper>
			<Button
				onClick={() => {
					setHeight(
						areFiltersDisplayed
							? 0
							: innerContent.current.clientHeight
					);
					setAreFiltersDisplayed((prev) => !prev);
				}}
			>
				{t("report.filters")}{" "}
				{areFiltersDisplayed ? (
					<ArrowDropUpIcon />
				) : (
					<ArrowDropDownIcon />
				)}{" "}
				<FilterCount>
					{Object.keys(filters).reduce(
						(sum, current) =>
							sum +
							Boolean(
								Array.isArray(filters[current].value)
									? filters[current].value.length
									: filters[current].value
							),
						0
					)}
				</FilterCount>
			</Button>
			<Filters style={{ height: height }}>
				<Content ref={innerContent}>
					{Object.keys(filters).map((filter) => (
						<FormControlLabel
							sx={{ margin: "0" }}
							key={filter}
							control={
								filters[filter].options ? (
									filters[filter].isMultiple ? (
										<FilterMultiselect
											filter={filter}
											handleChange={handleChange}
											filters={filters}
										/>
									) : (
										<FilterSelect
											filter={filter}
											handleChange={handleChange}
											filters={filters}
										/>
									)
								) : filters[filter].inputHandler ? (
									<FilterInput
										filter={filter}
										handleChange={handleChange}
										filters={filters}
									/>
								) : (
									<FilterSwitch
										filter={filter}
										filters={filters}
										handleChange={handleChange}
									/>
								)
							}
							labelPlacement={
								filters[filter].options ||
								filters[filter].inputHandler
									? "start"
									: "end"
							}
							label={t(`report.${filter}`)}
						/>
					))}
					{isLoading ? (
						<CircleLoader />
					) : (
						<>
							{apply ? (
								<Button onClick={buttonHandlerWrapper(apply)}>
									{t(`profile.apply`)}
								</Button>
							) : null}
							{reset ? (
								<Button
									sx={{ color: "#FF0000" }}
									onClick={buttonHandlerWrapper(reset)}
								>
									{t(`reset`)}
								</Button>
							) : null}
						</>
					)}
				</Content>
			</Filters>
		</SwitchFiltersWrapper>
	);
};
