import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getColorList = createAsyncThunk(
	"color/getColorList",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/colors`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getFormList = createAsyncThunk(
	"color/getFormList",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/products`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getColorImagesRefList = createAsyncThunk(
	"color/getColorImagesRefList",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/colors/${id}/images`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addImageForColor = createAsyncThunk(
	"color/addImageForColor",
	async function ({ color_id, pallet_id }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (pallet_id) {
				return await api(
					`/api/colors/${color_id}/images?pallet_id=${pallet_id}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addNewColor = createAsyncThunk(
	"color/addNewColor",
	async function (body, { rejectWithValue, extra, dispatch }) {
		const { api } = extra;
		try {
			if (body) {
				await api(`/api/colors`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				});
				dispatch(getColorList());
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getZipImageRef = createAsyncThunk(
	"color/getZipImageRef",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/colors/examples/${encodeURIComponent(
						ref
					)}?zipped=true`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getImageRef = createAsyncThunk(
	"color/getImageRef",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/colors/examples/${encodeURIComponent(ref)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getColorPreview = createAsyncThunk(
	"color/getColorPreview",
	async function (color_id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (color_id) {
				return await api(`/api/colors/${color_id}/preview`, {
					method: "GET",
					headers: {
						"Content-Type": "image/webp",
					},
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getImageRefMask = createAsyncThunk(
	"color/getImageRefMask",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/colors/examples/${encodeURIComponent(ref)}/mask`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteImageRef = createAsyncThunk(
	"color/deleteImageRef",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/colors/examples/${encodeURIComponent(ref)}`,
					{
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteColor = createAsyncThunk(
	"color/deleteColor",
	async function (color_id, { rejectWithValue, extra, dispatch }) {
		const { api } = extra;
		try {
			if (color_id) {
				await api(`/api/colors/${color_id}`, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				});
				dispatch(getColorList());
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const editColor = createAsyncThunk(
	"color/editColor",
	async function ({ color_id, color }, { rejectWithValue, extra, dispatch }) {
		const { api } = extra;
		try {
			if (color_id) {
				await api(`/api/colors/${color_id}`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(color),
				});
				dispatch(getColorList());
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const setError = (state, action) => {
	state.status = "rejected";
	state.error = action.payload;
};

const colorSlice = createSlice({
	name: "color",
	initialState: {
		status: null,
		colorList: [],
		formList: [],
		error: null,
		imagesRefList: null,
		imageRef: [],
		imageRefMask: [],
		zipImageRef: [],
		imageUUidArr: [],
		imageRefsArr: [],
		colorPreview: [],
	},
	reducers: {
		pushUUid(state, action) {
			state.imageUUidArr.push(action.payload);
		},
		removeUUid(state, action) {
			state.imageUUidArr = state.imageUUidArr.filter(
				(item) => item !== action.payload
			);
		},
		reset(state) {
			state.imageUUidArr = [];
			state.imageRefsArr = [];
		},
		resetRefList(state) {
			state.imagesRefList = null;
		},
		pushRef(state, action) {
			state.imageRefsArr.push(action.payload);
		},
		removeRef(state, action) {
			state.imageRefsArr = state.imageRefsArr.filter(
				(item) => item !== action.payload
			);
		},
	},
	extraReducers: {
		[getColorList.pending]: (state) => {
			state.status = "loading";
		},
		[getColorList.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.colorList = action.payload.sort((a, b) =>
				a.name.localeCompare(b.name)
			);
		},
		[getColorList.rejected]: setError,

		[getFormList.pending]: (state) => {
			state.status = "loading";
		},
		[getFormList.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.formList = action.payload;
		},
		[getFormList.rejected]: setError,

		[getColorImagesRefList.pending]: (state) => {
			state.status = "loading";
		},
		[getColorImagesRefList.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.imagesRefList = action.payload;
		},
		[getColorImagesRefList.rejected]: setError,

		[getZipImageRef.pending]: (state) => {
			state.status = "loading";
		},
		[getZipImageRef.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.zipImageRef.push({
					id: action.meta.arg.split("__")[0],
					blob: action.payload,
				});
			}
			state.zipImageRef = [...new Set(state.zipImageRef)];
		},
		[getZipImageRef.rejected]: setError,

		[getImageRef.pending]: (state) => {
			state.status = "loading";
			state.imageRef = [];
		},
		[getImageRef.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.imageRef.push({
					id: action.meta.arg.split("__")[0],
					blob: action.payload,
				});
			}
		},
		[getImageRef.rejected]: setError,

		[getColorPreview.pending]: (state) => {
			state.status = "loading";
		},
		[getColorPreview.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.colorPreview.push({
					id: action.meta.arg.split("__")[0],
					blob: action.payload,
				});
			}
		},
		[getColorPreview.rejected]: setError,

		[getImageRefMask.pending]: (state) => {
			state.status = "loading";
			state.imageRefMask = [];
		},
		[getImageRefMask.fulfilled]: (state, action) => {
			state.status = "resolved";
			if (action.payload) {
				state.imageRefMask.push({
					id: action.meta.arg.split("__")[0],
					mask: action.payload,
				});
			}
		},
		[getImageRefMask.rejected]: setError,
	},
});

export const { pushUUid, reset, removeUUid, pushRef, removeRef, resetRefList } =
	colorSlice.actions;

export default colorSlice.reducer;
