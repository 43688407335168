import { Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import { useCallback } from "react";

export const FilterMultiselect = ({ filter, filters, handleChange }) => {
	const handleRender = useCallback(
		(selected) =>
			selected
				.map((selectedItem) => {
					return filters[filter].options.find(
						(option) => option.uuid === selectedItem
					).label;
				})
				.join(", "),
		[filters, filter]
	);

	return (
		<Select
			sx={{
				width: "10rem",
				marginLeft: "0.5rem",
			}}
			variant="standard"
			multiple
			value={filters[filter].value}
			renderValue={handleRender}
			onChange={(event) => handleChange(filter, event.target.value)}
		>
			{filters[filter].options.map((option) => (
				<MenuItem key={option.uuid} value={option.uuid}>
					<Checkbox
						checked={
							filters[filter].value.indexOf(option.uuid) > -1
						}
					/>
					<ListItemText primary={option.label} />
				</MenuItem>
			))}
		</Select>
	);
};
