import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
	padding-left: 34px;
	padding-top: 31px;
	display: flex;
	flex-direction: row;
	width: 47%;
	justify-content: space-between;
`;

const StatLinks = () => {
	const [months, setMonths] = useState([
		{ id: "feb", title: "ФЕВ 22" },
		{ id: "mar", title: "МАР 22" },
		{ id: "apr", title: "АПР 22" },
		{ id: "may", title: "МАЙ 22" },
		{ id: "jul", title: "ИЮН 22" },
		{ id: "jun", title: "ИЮЛ 22" },
		{ id: "aug", title: "АВГ 22" },
		{ id: "sep", title: "СЕН 22" },
		{ id: "okt", title: "ОКТ 22" },
		{ id: "nov", title: "НОЯ 22" },
		{ id: "dec", title: "ДЕК 22" },
	]);

	return (
		<>
			<Wrapper>
				<Link to={"/statistic/year"}>
					<div>ЗА ГОД 22</div>
				</Link>
				{months.map((month) => (
					<Link key={month.id} to={`/statistic/${month.id}`}>
						<div>{month.title}</div>
					</Link>
				))}
			</Wrapper>
			<Outlet />
		</>
	);
};

export default StatLinks;
