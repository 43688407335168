import styled from "styled-components";
import { Table } from "../components/Table";
import { useCallback, useEffect, useState } from "react";
import { useDataTableFormatter } from "../hooks/useDataTableFormatter";
import {
	getAllPalletsFromBatch,
	getFilteredPallets,
	requestBatches,
} from "../store/newReportSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReportControls } from "../components/ReportControls";
import { DataVisualization } from "../components/DataVisualization";
import { useTranslation } from "react-i18next";
import ReportRow from "../components/report/ReportRow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DialogWrapper } from "../components/DialogWrapper";
import { setTimeZoneOffset } from "../helper/setTimeZoneOffset";
import { getColorList, getFormList } from "../store/colorSlice";
import { getNoTokenUsers } from "../store/userSlice";
import { useMemo } from "react";
import { valueExtractor } from "../helper/valueExtractor";
import { BatchParamsChangeForm } from "../components/BatchParamsChangeForm";
import { BatchEditingButtons } from "../components/BatchEditingButtons";
import { BatchSplitForm } from "../components/BatchSplitForm";
import { BatchMergeForm } from "../components/BatchMergeForm";
import dayjs from "dayjs";

const Wrapper = styled.div`
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Header = styled.div`
	width: 100%;
`;

const TableWrapper = styled.div`
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
	border-radius: 1rem;
	overflow: hidden;
`;

const NewReport = () => {
	const [currentScale, setCurrentScale] = useState("batches");
	const [batchToEdit, setBatchToEdit] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editMode, setEditMode] = useState("props");
	const [isMergeInProgress, setIsMergeInProgress] = useState(false);
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [selectedToMerge, setSelectedToMerge] = useState([]);
	const { batches, selectedBatch, palletsForBatch, requestedDates } =
		useSelector((state) => state.newReport);
	const { noTokenUsers } = useSelector((state) => state.user);

	const { formList, colorList } = useSelector((state) => state.color);

	useEffect(() => {
		dispatch(getColorList());
		dispatch(getFormList());
		dispatch(getNoTokenUsers());
		if (!batches.length)
			dispatch(
				requestBatches({
					from: from.format().slice(0, 10),
					to: to.format().slice(0, 10),
					filters: valueExtractor(batchFilters),
				})
			);
	}, []);

	useEffect(() => {
		setCurrentScale("tacts");
	}, [palletsForBatch]);

	useEffect(() => {
		setCurrentScale("batches");
	}, [batches]);

	const editBatch = (batch, mode) => {
		setBatchToEdit(batch);
		setEditMode(mode);
		setIsModalOpen(true);
	};

	const {
		areaFormatter,
		timeFormatter,
		overallAreaFormatter,
		piecesFormatter,
		complexPercentFormatter,
		heightFormatter,
		densityFormatter,
		weightFormatter,
		differenceFormatter,
		secondsFormatter,
		customContentFormatter,
	} = useDataTableFormatter();

	const columns = [
		{
			name: "bdt",
			colSpan: 1,
			formatter: timeFormatter,
		},
		{
			name: "edt",
			colSpan: 1,
			formatter: timeFormatter,
		},
		{
			name: "product_name",
			colSpan: 1,
		},
		{
			name: "color_name",
			colSpan: 1,
		},
		{
			name: "operator",
			colSpan: 1,
		},
		{
			name: "ticks",
			colSpan: 1,
		},
		{ name: "total", colSpan: 1, formatter: differenceFormatter("ticks") },
		{
			name: "avg_tick_time_s",
			colSpan: 1,
			formatter: secondsFormatter,
		},
		{
			name: "overall",
			colSpan: 1,
			formatter: overallAreaFormatter,
		},
		{
			name: "bad",
			colSpan: 1,
			formatter: overallAreaFormatter,
		},
		{
			name: "bad_defect",
			colSpan: 1,
			formatter: overallAreaFormatter,
		},
		{
			name: "bad_height",
			colSpan: 1,
			formatter: overallAreaFormatter,
		},
		{
			name: "stones_with_cracks_cnt",
			colSpan: 1,
			formatter: piecesFormatter,
		},
		{ name: "stones_with_cracks_m2", colSpan: 1, formatter: areaFormatter },
		{
			name: "stones_with_pits_cnt",
			colSpan: 1,
			formatter: piecesFormatter,
		},
		{ name: "stones_with_pits_m2", colSpan: 1, formatter: areaFormatter },
		{
			name: "stones_with_stains_cnt",
			colSpan: 1,
			formatter: piecesFormatter,
		},
		{ name: "stones_with_stains_m2", colSpan: 1, formatter: areaFormatter },
		{
			name: "stones_with_destructions_cnt",
			colSpan: 1,
			formatter: piecesFormatter,
		},
		{
			name: "stones_with_destructions_m2",
			colSpan: 1,
			formatter: areaFormatter,
		},
		{
			name: "bad",
			colSpan: 1,
			customName: "bad_share",
			formatter: complexPercentFormatter("overall"),
		},
		{
			name: "bad_defect",
			colSpan: 1,
			customName: "bad_defect_share",
			formatter: complexPercentFormatter("overall"),
		},
		{
			name: "bad_height",
			colSpan: 1,
			customName: "bad_height_share",
			formatter: complexPercentFormatter("overall"),
		},
		{
			name: "product_height",
			formatter: heightFormatter,
		},
		{
			name: "avg",
			colSpan: 1,
			formatter: heightFormatter,
		},
		{
			name: "avg_density",
			colSpan: 1,
			formatter: densityFormatter,
		},
		{
			name: "product_weight",
			colSpan: 1,
			formatter: weightFormatter,
		},
		{
			name: "",
			colSpan: 1,
			isPinned: true,
			formatter: customContentFormatter(BatchEditingButtons, {
				editBatch,
				isMergeInProgress,
				setIsMergeInProgress,
				selectedToMerge,
				setSelectedToMerge,
			}),
		},
	];

	const defaultTactFilters = useMemo(
		() => ({
			has_crack: { value: false },
			has_pit: { value: false },
			has_stain: { value: false },
			has_destruction: { value: false },
			has_no_defect: { value: false },
			has_height_overlimit: { value: false },
			user_decision: { value: false },
			user_decision_alt: { value: false },
			include_garbage: { value: false },
		}),
		[]
	);

	const defaultBatchFilters = useMemo(
		() => ({
			products: {
				value: [],
				options: formList.map((form) => ({
					uuid: form.uuid,
					label: form.name,
				})),
				isMultiple: true,
			},
			colors: {
				value: [],
				options: colorList.map((color) => ({
					uuid: color.uuid,
					label: color.name,
				})),
				isMultiple: true,
			},
			heights: {
				value: [],
				options: Array.from(
					new Set(formList.map((form) => form.height))
				).map((height) => ({
					uuid: height,
					label: `${height} ${t("measureUnits.mm")}`,
				})),
				isMultiple: true,
			},
			users: {
				value: [],
				options: noTokenUsers
					.filter((user) => user.role === "operator")
					.map((user) => ({
						uuid: user.id,
						label: user.name,
					})),
				isMultiple: true,
			},
		}),
		[colorList, formList, noTokenUsers, t]
	);

	const [tactFilters, setTactFilters] = useState(defaultTactFilters);

	const [batchFilters, setBatchFilters] = useState(defaultBatchFilters);

	useEffect(() => {
		setBatchFilters(defaultBatchFilters);
	}, [defaultBatchFilters]);

	const [from, setFrom] = useState(
		dayjs(requestedDates.from ? requestedDates.from : Date.now())
	);
	const [to, setTo] = useState(
		dayjs(requestedDates.to ? requestedDates.to : Date.now())
	);
	const [selectedColumns, setSelectedColumns] = useState(
		columns.map((column) => column.customName ?? column.name)
	);

	const refreshWithFilters = useCallback(() => {
		return dispatch(
			getFilteredPallets({
				batch: selectedBatch,
				filters: valueExtractor(tactFilters),
			})
		);
	}, [tactFilters, selectedBatch, dispatch]);

	const resetFilters = useCallback(() => {
		setTactFilters(structuredClone(defaultTactFilters));
		return dispatch(
			getFilteredPallets({
				batch: selectedBatch,
				filters: valueExtractor(defaultTactFilters),
			})
		);
	}, [selectedBatch, dispatch, defaultTactFilters]);

	const getBatches = () => {
		dispatch(
			requestBatches({
				from: from.format().slice(0, 10),
				to: to.format().slice(0, 10),
				filters: valueExtractor(batchFilters),
			})
		);
	};

	const dividerHandler = () => {
		let initialShift = "";
		return (batch, isLast) => {
			if (!batch?.shift) {
				if (isLast) initialShift = "";
				return {
					isDisplayed: false,
				};
			}
			if (initialShift === batch?.shift?.uuid) {
				if (isLast) initialShift = "";
				return {
					isDisplayed: false,
				};
			} else {
				initialShift = isLast ? "" : batch?.shift?.uuid;
				const options = {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				};
				const beginTime = batch.shift?.begin_dt
					? setTimeZoneOffset(
							new Date(batch.shift?.begin_dt),
							batch.shift?.begin_dt?.slice(-6, -3)
					  ).toLocaleDateString(
							`${
								i18n.resolvedLanguage
							}-${i18n.resolvedLanguage.toUpperCase()}`,
							options
					  ) +
					  " " +
					  batch.shift?.begin_dt?.slice(11, 16)
					: "undefined";
				const endTime = batch.shift?.end_dt
					? setTimeZoneOffset(
							new Date(batch.shift?.end_dt),
							batch.shift?.end_dt.slice(-6, -3)
					  ).toLocaleDateString(
							`${
								i18n.resolvedLanguage
							}-${i18n.resolvedLanguage.toUpperCase()}`,
							options
					  ) +
					  " " +
					  batch.shift?.end_dt?.slice(11, 16)
					: "undefined";
				return {
					isDisplayed: true,
					dividerProps: {
						count: batch.shift?.name?.slice(-1),
						time: beginTime + " - " + endTime,
					},
				};
			}
		};
	};

	const determineIfSelected = (batch) =>
		selectedBatch ? batch.bdt === selectedBatch.bdt : false;

	const rowClickHandler = (batch) => {
		dispatch(
			getAllPalletsFromBatch({
				batch: batch,
				filters: valueExtractor(tactFilters),
			})
		);
	};

	const handleClose = () => {
		setEditMode(null);
		setIsModalOpen(false);
	};

	const stopMerge = () => {
		setIsMergeInProgress(false);
		setSelectedToMerge([]);
	};

	const rowButtons = [
		{
			icon: VisibilityIcon,
			onClick: rowClickHandler,
		},
	];

	return (
		<Wrapper>
			<Header>
				<ReportControls
					isMergeInProgress={isMergeInProgress}
					stopMerge={stopMerge}
					editBatch={editBatch}
					from={from}
					to={to}
					setFrom={setFrom}
					setTo={setTo}
					filters={batchFilters}
					setFilters={setBatchFilters}
					getBatches={getBatches}
					columns={columns}
					selectedColumns={selectedColumns}
					setSelectedColumns={setSelectedColumns}
				/>
			</Header>
			<TableWrapper>
				<Table
					isClickable
					Divider={ReportRow}
					dividerHandler={dividerHandler()}
					dataIdField="bdt"
					rowButtons={rowButtons}
					determineIfSelected={determineIfSelected}
					rowClickHandler={rowClickHandler}
					data={batches}
					columns={columns.filter((column) =>
						selectedColumns.includes(
							column.customName ?? column.name
						)
					)}
				/>
			</TableWrapper>
			<DataVisualization
				setCurrentScale={setCurrentScale}
				refreshWithFilters={refreshWithFilters}
				resetFilters={resetFilters}
				filters={tactFilters}
				setFilters={setTactFilters}
				currentScale={currentScale}
			/>
			<DialogWrapper open={isModalOpen} onClose={handleClose}>
				{editMode === "props" && (
					<BatchParamsChangeForm
						batch={batchToEdit}
						closeForm={handleClose}
						filters={valueExtractor(batchFilters)}
					/>
				)}
				{editMode === "split" && (
					<BatchSplitForm
						batch={batchToEdit}
						closeForm={handleClose}
						filters={valueExtractor(batchFilters)}
					/>
				)}
				{editMode === "merge" && (
					<BatchMergeForm
						batches={batches.filter((batch) =>
							selectedToMerge.includes(batch.batch_id)
						)}
						closeForm={() => {
							handleClose();
							stopMerge();
						}}
						filters={valueExtractor(batchFilters)}
					/>
				)}
			</DialogWrapper>
		</Wrapper>
	);
};

export default NewReport;
