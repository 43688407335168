import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getLayouts = createAsyncThunk(
	"form/getLayouts",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/layouts`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const addLayouts = createAsyncThunk(
	"form/addLayouts",
	async function (needObject, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/layouts`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(needObject),
			});
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const getFormsByLayout = createAsyncThunk(
	"form/getFormsByLayout",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/layouts/${id}/forms`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const getLayoutImagesRefList = createAsyncThunk(
	"form/getLayoutImagesRefList",
	async function (id, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (id) {
				return await api(`/api/layouts/${id}/images`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
			}
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const addImageForLayout = createAsyncThunk(
	"form/addImageForLayout",
	async function ({ layout_id, pallet_id }, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (pallet_id) {
				return await api(
					`/api/layouts/${layout_id}/images?pallet_id=${pallet_id}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
			}
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const getZipImageRef = createAsyncThunk(
	"form/getZipImageRef",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/layouts/examples/${encodeURIComponent(
						ref
					)}?zipped=true`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				);
			}
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const getImageRef = createAsyncThunk(
	"form/getImageRef",
	async function (ref, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			if (ref) {
				return await api(
					`/api/layouts/examples/${encodeURIComponent(ref)}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "image/webp",
						},
					}
				);
			}
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

const formSlice = createSlice({
	name: "form",
	initialState: {
		layouts: null,
		status: null,
		error: null,
		forms: [],
	},
	reducers: {
		addForm(state, action) {
			state.forms = action.payload;
		},
	},
	extraReducers: {
		[getLayouts.pending]: (state) => {
			state.status = "loading";
		},
		[getLayouts.fulfilled]: (state, action) => {
			state.status = "resolved";
			state.layouts = action.payload.sort((a, b) =>
				a.name.localeCompare(b.name)
			);
		},
		[getLayouts.rejected]: (state, action) => {
			state.status = "rejected";
			state.error = action.payload;
		},
	},
});

export const { addForm } = formSlice.actions;

export default formSlice.reducer;
