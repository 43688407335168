import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProducts = createAsyncThunk(
	"product/getProducts",
	async function (_, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/products`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

export const addProduct = createAsyncThunk(
	"product/addProducts",
	async function (product, { rejectWithValue, extra }) {
		const { api } = extra;
		try {
			return await api(`/api/products`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(product),
			});
		} catch (error) {
			rejectWithValue(error.message);
		}
	}
);

const productSlice = createSlice({
	name: "product",
	initialState: {
		products: [],
	},
	extraReducers: (builder) => {
		builder.addCase(getProducts.fulfilled, (state, action) => {
			state.products = action.payload;
		});
	},
});

export default productSlice.reducer;
