import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getColorImagesRefList } from "../../store/colorSlice";
import ExampleZipImage from "./ExampleZipImage";

const ExampleColorList = ({ color_id, setAdd }) => {
	const { imagesRefList } = useSelector((state) => state.color);

	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(getColorImagesRefList(color_id));
	}, [color_id]);

	return (
		<Box pt="40px">
			<Box
				display={"flex"}
				flexDirection="row"
				alignItems={"center"}
				justifyContent="space-between"
			>
				<Box display={"flex"} flexDirection="column">
					<Typography
						sx={{
							fontSize: "18px",
							color: "black",
							paddingRight: "75px",
							textTransform: "uppercase",
						}}
					>
						{t("color.title_example")}
					</Typography>
					<Typography>{t("color.desc_example")}</Typography>
				</Box>

				<Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
					<Button
						sx={{
							border: "2px solid black",
							fontSize: "18px",
							color: "black",
							borderRadius: "10px",
							padding: "5px 33px",
							textTransform: "none",
						}}
						onClick={() => setAdd("add")}
					>
						{t("color.btn_add")}
					</Button>
					<Button
						sx={{
							border: "2px solid black",
							fontSize: "18px",
							color: "black",
							borderRadius: "10px",
							padding: "5px 33px",
							textTransform: "none",
						}}
						onClick={() => setAdd("delete")}
					>
						{t("color.btn_example_delete")}
					</Button>
				</Box>
			</Box>
			<Box
				display={"flex"}
				flexDirection="row"
				gap={"16px"}
				flexWrap="wrap"
				mt="60px"
			>
				{imagesRefList?.map((item) => (
					<ExampleZipImage
						example={true}
						key={item.ref}
						imageRef={item.ref}
					/>
				))}
			</Box>
		</Box>
	);
};

export default ExampleColorList;
